import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Import Components
import MiniWidgets from './MiniWidgets';
import MembersAnalytics from './MembersAnalytics';
import RidesAnalytics from './RidesAnalytics';
import EarningReports from './EarningReports';
import Sources from './Sources';
import RecentlyActivity from './RecentlyActivity';
import RevenueByLocations from './RevenueByLocations';
import ChatBox from './ChatBox';
import LatestTransactions from './LatestTransactions';
import PassengersAnalytics from './PassengersAnalytics';
import AlertCities from './AlertCities';

const Dashboard = () => {
    const [breadcrumbItems] = useState([
        { title: 'Home', link: '/' },
        { title: 'Dashboard', link: '#' },
    ]);

    const [reports] = useState([
        {
            data: [
                { title: 'Active Members Today', value: '1452' },
            ]
        },
        {
            data: [
                { title: 'Members Registered today', value: '38452' },
                { title: 'Avg Registration per day', value: '1422' },
            ]
        },
        {
            data: [
                { title: 'Cancelled Empty Ride', value: '15' },
                { title: 'Cancelled Ride', value: '15' },
                { title: 'Canceled', value: '15' }
            ]
        },
        {
            data: [
                { title: 'Published Offers', value: '200' },
                { title: 'Cancelled Offers', value: '100' },
            ]
        },
    ]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
                <Container fluid>
                    <Row>
                        <Row>
                            <MiniWidgets reports={reports} />
                        </Row>
                    </Row>
                    <Row>
                        <Col xl={8}>
                            <Row>
                                <Col xl={12}>
                                    <MembersAnalytics />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <RidesAnalytics />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <PassengersAnalytics />
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={4}>
                            <AlertCities />
                            <AlertCities />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
