import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, InputGroup, Label, Row } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Loader from "../../components/Loader/Loader";
import { get, put } from "../../helpers/api_helper";
import { GET_RIDES_FOLLOW_UP, PUT_RIDES_FOLLOW_UP } from "./../../constants/api.constants";
import { FaCaretDown } from "react-icons/fa";

const RidesHandles = () => {
  const [reservations, setReservations] = useState([]);
  const [originalReservations, setOriginalReservations] = useState([]);
  const [dateRange, setRideRange] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({ label: "Ongoing", value: 1 });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [current_ride, setCurrentReservation] = useState([]);
  const [activeBtn, setActiveBtn] = useState("reset");
  const [nbrNewRides, setNbrNewRides] = useState(0)

  useEffect(() => {
    // fetch_rides();
    filter()
  }, []);

  useEffect(() => {
    filter();
  }, [perPage, page]);

  const handleChangeReservationHandle = (e, ride_id) => {
    setCurrentReservation({ ride_id, val: e.target.value });
    setShowSweetAlert(true);
  };

  const handleChangeReservationStatus = async () => {
    const config = {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
    try {
      await put(PUT_RIDES_FOLLOW_UP, current_ride, config);
      toastr.success("Reservation Updated");
      setShowSweetAlert(false);
      filter();
    } catch (error) {
      toastr.error("System Error");
      console.log(error);
    }
  };

  const filter = () => {
    let _query = "";
    if (dateRange.length === 2) {
      let from_range = dateRange[0];
      let to_range = dateRange[1];
      // from_range.setDate(from_range.getDate());
      // to_range.setDate(to_range.getDate());
      from_range = from_range.toISOString().split("T")[0];
      to_range = to_range.toISOString().split("T")[0];
      _query += `?from_range=${from_range}&to_range=${to_range}`;

      console.log('from : ', from_range, ' to : ', to_range)
    }
    if (selectedStatus.value !== 0) {
      _query += `&status=${selectedStatus.value}`;

    }
    _query += (_query === "" ? "?" : "&") + `page=${page}&per_page=${perPage}`;
    fetch_rides(_query);
  };

  const fetch_rides = async (_query = null) => {
    setIsLoading(true);
    try {
      const response = await get(GET_RIDES_FOLLOW_UP + (_query ?? ""));
      console.log('reponse : ', response)
      let totalSize = response._pagination.total_items;
      setNbrNewRides(response._pagination.total_new_rides)
      let temp = response.rides.map((ride) => ({
        key: ride.ride_id,
        driverName: (
          <ul>
            <li>
              <Link to={"/member-detail/" + ride.passenger_id}>
                {ride.first_name} {ride.last_name}
              </Link>
            </li>
            <li>0{ride.driver_number}</li>
          </ul>
        ),
        ride: (
          <Link to={"/ride-detail/" + ride.ride_id}>
            {ride.departure_city} {" > "} {ride.arrival_city}
          </Link>
        ),
        districts: (
          (ride.departure_district && ride.departure_district !== "unknown") ?
            ride.departure_district :
            "Other"
        ) + " > " + (
            (ride.arrival_district && ride.arrival_district !== "unknown") ?
              ride.arrival_district :
              "Other"
          ),
        price: ride.price.toFixed(2),
        departureDate: `${ride.departure_date}`,
        seats: ride.total_pending_reservations,
        actions: ride.ride_handle?.status !== 2 ? (
          <select
            className={
              ride.ride_handle?.status === 1
                ? "custom-select-not-responding"
                : "custom-select"
            }
            onChange={(e) => handleChangeReservationHandle(e, ride.ride_id)}
          >
            <option disabled selected={!ride.ride_handle}>New</option>
            <option value="1" selected={ride.ride_handle?.status === 1}>
              Not responding
            </option>
            <option value="2" selected={ride.ride_handle?.status === 2}>
              Called
            </option>
          </select>
        ) : (
          <span class="badge bg-primary">Called ✓</span>
        ),
      }));
      setReservations(temp);
      setOriginalReservations(temp);
      setTotalRows(totalSize);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const CustomHeader = ({ title, onClick, activeBtn, column }) => (
    <span onClick={onClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
      {title}
      {activeBtn === column ? (
        <span style={{ marginLeft: '8px', width: '7px', height: '7px', backgroundColor: '#FFD700', borderRadius: '50%' }}></span>
      ) : (
        <FaCaretDown className="down-angle-icon" style={{marginLeft: '5px'}} />
      )}
    </span>
  );

  const columns = [
    {
      name: "Fullname",
      selector: (row) => row.driverName,
    },
    {
      name: "Ride",
      cell: (row) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
            <div>{row.ride}</div>
            <div style={{ "fontSize": "11px", "color": "#808080" }}>{row.districts}</div>
          </div>
        );
      },
      grow: 1.8
    },
    {
      name: "Reservations",
      selector: (row) => row.seats,
      grow: 0.6
    },
    {
      name: (
        <CustomHeader
          title="Price"
          onClick={() => {
            handleFilterChange('price')
          }}
          activeBtn={activeBtn}
          column='price'
        />
      ),
      selector: (row) => row.price,
      filterComponent: (
        <input
          type="checkbox"
          onChange={() => handleFilterChange("price")}
        />
      ),
      allowOverflow: true,
      grow: 0.6
    },
    {
      name: (
        <CustomHeader
          title="Departure Date"
          onClick={() => {
            handleFilterChange('date')
          }}
          activeBtn={activeBtn}
          column='date'
        />
      ),
      selector: (row) => row.departureDate,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
    },
  ];

  const handlePageChange = (page) => {
    setPage(page - 1);
    setActiveBtn("reset");
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setActiveBtn("reset");
  };

  const handleFilterChange = (filterType) => {
    if (filterType === "price") {
      setActiveBtn("price")
      const sortedReservations = [...reservations].sort(
        (a, b) => parseFloat(b.price) - parseFloat(a.price)
      );
      setReservations(sortedReservations);
    } else if (filterType === "date") {
      setActiveBtn("date")
      const sortedReservations = [...reservations].sort((a, b) => {
        const dateA = new Date(a.departureDate);
        const dateB = new Date(b.departureDate);
        return dateB - dateA;
      });
      setReservations(sortedReservations);
    }

  };

  const handleResetFilters = () => {
    setActiveBtn("reset")
    setReservations(originalReservations);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card className="custom-card">
                <CardBody>
                  {showSweetAlert ? (
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => handleChangeReservationStatus()}
                      onCancel={() => setShowSweetAlert(false)}
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col xs="3">
                      <div className="col mb-3" xl="3">
                        <Label>Departure Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            value={dateRange}
                            onChange={(e) => {
                              console.log(e);
                              setRideRange(e);
                            }}
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    {/* <Col xs="3">
                      <div className="col mb-3" xl="3">
                        <Label className="form-label">Ride Status</Label>
                        <Select
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e)}
                          options={[
                            { label: "All", value: 0 },
                            { label: "Ongoing", value: "1" },
                            { label: "Cancelled", value: "2" },
                            { label: "Completed", value: "3" },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}
                    <Col xs="2">
                      <div
                        className="col mt-4"
                        xl="3"
                        style={{ "margin-top": "28px !important" }}
                      >
                        <Button
                          color="success"
                          type="button"
                          onClick={filter}
                          className="waves-effect waves-light me-1"
                        >
                          <i className="ri-search-line align-middle me-2"></i>
                          <span className="align-middle">Search</span>
                        </Button>
                      </div>
                    </Col>
                    <Col xs="2">
                      <Card className="custom-card">
                        <CardBody>
                          <>
                            <p>To Treat</p>
                            <h4>{nbrNewRides}</h4>
                          </>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="custom-card">
                <CardBody>
{/* <<<<<<< HEAD
                  <div className="d-flex gap-2 mb-3">
                    <Button
                      color={activeBtn === "reset" ? "primary" : "warning"}
                      type="button"
                      onClick={handleResetFilters}
                      className="mr-3"
                    >
                      Reset Filters
                    </Button>
                    <Button
                      color={activeBtn === "price" ? "primary" : "warning"}
                      type="button"
                      onClick={() => handleFilterChange('price')}
                    >
                      Filter by Price
                    </Button>
                    <Button
                      color={activeBtn === "date" ? "primary" : "warning"}
                      type="button"
                      onClick={() => handleFilterChange('date')}
                    >
                      Filter by Date
                    </Button>
                  </div>
                  </div> */}

                <div className="d-flex gap-2 mb-3">
                  <Button
                    color={activeBtn === "reset" ? "primary" : "warning"}
                    type="button"
                    onClick={handleResetFilters}
                    className="mr-3"
                  >
                    Reset Filters
                  </Button>
                  {/* <Button
                    color={activeBtn === "price" ? "primary" : "warning"}
                    type="button"
                    onClick={() => handleFilterChange('price')}
                  >
                    Filter by Price
                  </Button>
                  <Button
                    color={activeBtn === "date" ? "primary" : "warning"}
                    type="button"
                    onClick={() => handleFilterChange('date')}
                  >
                    Filter by Date
                  </Button> */}
                </div>
{/* >>>>>>> fa5954dd4f7c0fcf1f66e84e9371a2b28e489339 */}
                  <DataTable
                    pagination
                    paginationServer
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    columns={columns}
                    progressPending={isLoading}
                    progressComponent={<Loader />}
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    data={reservations}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RidesHandles;

