import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import {
  GET_RIDES,
  POST_FULL_POWER,
  RESERVATIONS
} from "../../../constants/api.constants";
import { reservation_statuses } from "../../../data/selects.data";
import { get, post } from "../../../helpers/api_helper";
import ChatDialog from "./ChatDialog";
import ReviewDialog from "./ReviewDialog";
function isValidJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
export default function PassengersList(props) {
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModel, setShowModel] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [is_modal_loading, setModalLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogReviewOpen, setDialogReviewOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState({});

  const [updatedSeats, setUpdatedSeats] = useState({});
  const [rideSeats, setRideSeats] = useState();
  const [availableSeats, setAvailbleSeats] = useState();
  const [buttonColor, setButtonColor] = useState({});
  const [activeButton, setActiveButton] = useState({});

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };
  const toggleReviewDialog = () => {
    setDialogReviewOpen(!dialogReviewOpen);
  };

  useEffect(() => {
    fetch_reservations();
  }, []);

  const parse_status = (status) => {
    switch (status) {
      case 0:
        return {
          status: "pending",
          class: "bg-pending",
        };
      case 1:
        return {
          status: "pre-approved",
          class: "bg-approved",
        };
      case 2:
        return {
          status: "PAID",
          class: "bg-paid",
        };
      case 3:
        return {
          status: "completed",
          class: "bg-completed",
        };
      case 4:
        return {
          status: "cancelled",
          class: "bg-negative",
        };
      default:
        break;
    }
  };
  const parse_status_raison = (status) => {
    switch (status) {
      case 0:
        return {
          status: "By system",
          class: "bg-pending",
        };
      case 1:
        return {
          status: "By passenger: ",
          class: "bg-approved",
        };
      case 2:
        return {
          status: "Declined by Driver",
          class: "bg-paid",
        };
      case 3:
        return {
          status: "Driver Cancels \n the ride",
          class: "bg-completed",
        };
      case 4:
        return {
          status: "Absence confirmed \n by system",
          class: "bg-negative",
        };
      case 5:
        return {
          status: "Driver absent \n confirmed by system",
          class: "bg-negative",
        };
      default:
        return {
          status: "-",
          class: "bg-negative",
        };
    }
  };

  const parse_qr_status = ({
    action_from_driver,
    action_from_passenger,
    txt_qr_code,
  }) => {
    let action = txt_qr_code;
    if (action_from_driver === 1 && action_from_passenger === 1) {
      action = "Scanned";
    } else if (action_from_driver === 2 && action_from_passenger === 2) {
      action = "Opposé";
    } else {
      if (action_from_driver === 2) {
        action = "Driver declared \n passenger absent";
      } else if (action_from_driver === 3) {
        action = "Driver declared \n himself absent";
      }

      if (action_from_passenger === 2) {
        action += " Passenger declared \n driver absent";
      } else if (action_from_passenger === 3) {
        action += " Passenger declared \n himself absent";
      }
    }

    return action;
  };

  const handle_generate_qrc = async (reservation) => {
    console.log(reservation);
    try {
      await post(`${RESERVATIONS}generate-qrc`, {
        reservation_id: reservation.reservation_id,
      });
      fetch_reservations();
    } catch (error) {
      console.log(error);
    }
  };
  const fetch_reservations = async () => {
    try {
      // const response = await get(
      //   GET_RESERVATIONS + props.ride_id
      // );
      const response = await get(GET_RIDES + "reservations/" + props.ride_id);
      let temp = [];
      for (let index = 0; index < response.ride_reservations.length; index++) {
        const reservation = response.ride_reservations[index];
        setRideSeats(reservation.seats);
        console.log('reservation : ', reservation)

        if (reservation.paid_seats !== null) {
          setAvailbleSeats(reservation.seats - reservation.paid_seats)
        } else {
          setAvailbleSeats(reservation.seats)
        }

        temp.push({
          ...reservation,
          fullname: (
            <Link to={"/member-detail/" + reservation.passenger_id}>
              {" "}
              {reservation.passenger_fname + " " + reservation.passenger_lname}
            </Link>
          ),
          ride: reservation.departure_city + " > " + reservation.arrival_city,
          districts: (
            (reservation.departure_district && reservation.departure_district !== "unknown") ?
              reservation.departure_district :
              "Other"
          ) + " > " + (
              (reservation.arrival_district && reservation.arrival_district !== "unknown") ?
                reservation.arrival_district :
                "Other"
            ),
          price: (
            // (reservation.reservation_price + (reservation.reservation_price * .1)).toFixed(2))

            <span>
              {(
                reservation.reservation_price +
                (reservation.reservation_price * 0.1) + ((reservation.reservation_price * 0.1) * 0.2)
              ).toFixed(2)}
            </span>
          ),
          price_to_pay: (
            // (reservation.reservation_price + (reservation.reservation_price * .1)).toFixed(2))

            <span>
              {(
                reservation.reservation_price +
                (reservation.reservation_price * 0.1) + ((reservation.reservation_price * 0.1) * 0.2)
              ).toFixed(2)}
            </span>
          ),
          // updated seats
          updatedSeats: updatedSeats[reservation.id] || reservation.reserved_seats,

          seats: reservation.reserved_seats,
          status: (
            <div>
              {reservation.paid_date ? (
                <span className={`badge ${parse_status(2).class}`}>
                  {parse_status(2).status} {reservation.paid_date}
                </span>
              ) : (
                ""
              )}

              {reservation.reservation_status === 3 && reservation.cron_action ? <>
                <br />
                <span
                  className={`badge bg-info`}
                >
                  {isValidJSON(reservation.cron_action) ? JSON.parse(reservation.cron_action).action_message : ''}
                </span>
              </> : reservation.reservation_status !== 2 ? (
                <>
                  <br />
                  <span
                    className={`badge ${parse_status(reservation.reservation_status).class
                      }`}
                  >
                    {parse_status(reservation.reservation_status).status}
                  </span>
                </>
              ) : (
                ""
              )}





              <span>
                {reservation.reservation_status === 4
                  ? parse_status_raison(reservation.status_reason).status
                  : ""}
                {/* {reservation.reservation_status === 2 ? parse_qr_status} */}
              </span>
              {reservation.reservation_status === 4 ? (
                <>
                  <span
                    className={`badge ${parse_status(reservation.reservation_status).class
                      }`}
                  >
                    {parse_status_raison(reservation.status_reason).status}
                  </span>
                </>
              ) : (
                ""
              )}
              {/* <br /> */}
              {/* <span>{reservation?.status_reason_by_user}</span> */}
            </div>
          ),
          driver_qr_status:
            reservation.action_from_driver ||
              reservation.action_from_driver === 0 ? (
              parse_qr_status({
                action_from_driver: reservation.action_from_driver,
                action_from_passenger: reservation.action_from_passenger,
                txt_qr_code: reservation.txt_qr_code,
              })
            ) : reservation.reservation_status === 2 ? (
              <i
                className="link"
                onClick={() => {
                  handle_generate_qrc(reservation);
                }}
              >
                Generate
              </i>
            ) : (
              ""
            ),
          last_action_date: reservation.last_update,
          actions: (
            <select
              onChange={(e) => {
                handle_onchange(e, reservation);
              }}
            >
              <option disabled selected>
                Select
              </option>
              {reservation_statuses[0].options.map((element) => {
                return <option value={element.value}>{element.label}</option>;
              })}
            </select>
          ),
          info: (
            <>
              <i
                class="ri-message-3-line"
                onClick={() => {
                  toggleDialog();
                  setSelectedReservation(reservation);
                }}
              ></i>
              {reservation.reservation_status === 3 ? (
                <i
                  class="ml-1 ri-thumb-up-fill"
                  onClick={() => {
                    // toggleReviewDialog();
                    setDialogReviewOpen(true);
                    // console.log('Review clicked');
                    setSelectedReservation(reservation);
                  }}
                ></i>
              ) : (
                ""
              )}
            </>
          ),
        });
      }
      setReservations(temp);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSeatChange = (e, reservationId) => {
    const { value } = e.target;
    setUpdatedSeats({ ...updatedSeats, [reservationId]: value });

    console.log('value : ', value, ' org seats : ', updatedSeats[reservationId])

    if (value !== updatedSeats[reservationId]) {
      setButtonColor({ [reservationId]: '#1aacac' });
      setActiveButton({ [reservationId]: true });
    } else {
      setButtonColor({ [reservationId]: '#9db2bf' });
      setActiveButton({ [reservationId]: false });
    }

  };

  const updateRideSeats = async (ride_id, reservation_id, updated_seats) => {
    try {
      const response = await post(GET_RIDES + "update-seats/", {
        ride_id,
        reservation_id,
        updated_seats
      });

      // alert('reservation seats updated')
      fetch_reservations()
      setActiveButton(false)
      setButtonColor({ [reservation_id]: '#9db2bf' })

    } catch (error) {
      console.log('something went wrong 2')
    }
  }

  const handle_onchange = async (e, reservation) => {
    console.log(e.target.value, reservation);
    switch (parseInt(e.target.value)) {
      case 0: {
        setModalData({
          action: 0,
          title: "Confirm Reset",
          description: "Are you sure you want to Reset the reservation ?",
          reservation,
        });
        setShowModel(true);
        break;
      }
      case 1: {
        setModalData({
          action: 1,
          title: "Confirm Scan Reservation",
          description:
            "Are you sure you want to convert this reservation to SCAN ?",
          reservation,
        });
        setShowModel(true);
        break;
      }
      case 2: {
        setModalData({
          action: 2,
          title: "Confirm Action",
          description:
            "Are you sure you want to convert this reservation to Passenger Absence ?",
          reservation,
        });
        setShowModel(true);
        break;
      }

      case 3: {
        setModalData({
          action: 3,
          title: "Confirm Action",
          description:
            "Are you sure you want to convert this reservation to Passenger Cancel FREE ?",
          reservation,
        });
        setShowModel(true);
        break;
      }
      case 4: {
        setModalData({
          action: 4,
          title: "Confirm Action",
          description:
            "Are you sure you want to convert this reservation to Passenger Cancel PAID ?",
          reservation,
        });
        setShowModel(true);
        break;

      }

      case 6: {
        setModalData({
          action: 6,
          title: "Confirm Action",
          description:
            "Are you sure you want to convert this reservation to Paid ?",
          reservation,
        });
        setShowModel(true);
        break;

      }

      default:
        break;
    }
  };

  const execute_full_power = async (action, reservation) => {
    setModalLoading(true);
    try {
      const config = {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      };
      await post(
        POST_FULL_POWER,
        {
          reservation_id: reservation.reservation_id,
          action,
          comment: modalData.comment,
        },
        config
      );
      setModalLoading(false);
      fetch_reservations();
      setShowModel(false);
    } catch (error) {
      setModalLoading(false);
      alert(error);
      console.log(error);
    }
  };

  const tog_standard = (reservation_id = null) => {
    console.log(reservation_id);
  };

  const truncateRide = (rideDistricts) => {
    if (rideDistricts.length >= 35) {
      return `${rideDistricts.substring(0, 35)} ...`;
    }
    return rideDistricts;
  };


  const columns = [
    {
      name: "Passenger",
      selector: (row) => row.fullname,
      grow: 1.2,
    },
    {

      name: "Ride",
      cell: (row) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
            <div>{row.ride}</div>
            <div style={{ "fontSize": "11px", "color": "#808080" }}>{row.districts}</div>
          </div>
        );
      },
      grow: 1.4,
    },
    {
      name: "Price",
      selector: (row) => {
        const totalPrice = row.price.props.children * row.reserved_seats;
        return totalPrice.toFixed(2); // Format price to 2 decimal places
      },
      grow: 0.4,
    },
    {
      name: "Seats",
      selector: (row) => (
        props.editRideSeats && (row.reservation_status == 1 || row.reservation_status == 0) ?
          <div>
            <input
              type="number"
              value={updatedSeats[row.reservation_id] || row.reserved_seats}
              min={1}
              max={availableSeats}
              onChange={(e) => handleSeatChange(e, row.reservation_id)}
            />
            <button
              variant="primary"
              style={{ backgroundColor: buttonColor && buttonColor[row.reservation_id] ? buttonColor[row.reservation_id] : '#9db2bf', color: '#ffffff', border: 'none', marginLeft: '4px', fontSize: '14px', padding: '2px 5px', borderRadius: '3px' }}
              onClick={() => {
                updateRideSeats(props.ride_id, row.reservation_id, updatedSeats[row.reservation_id]);
              }}
              disabled={!activeButton[row.reservation_id]}
            >
              save
            </button>
          </div>
          :
          row.seats
      ),
      grow: 1.2,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Qr Status",
      cell: (row) => (
        <span style={{ whiteSpace: 'pre-wrap' }}>
          {row.driver_qr_status}
        </span>
      ),
      grow: 0.5
    },
    {
      name: "Last Action",
      selector: (row) => row.last_action_date,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      grow: 2,
    },
    {
      name: "Info",
      selector: (row) => row.info,
    },
  ];
  return (
    <Card className="custom-br20 custom-bg-soft-white">
      {isLoading ? (
        <div className="loading-container">
          <Spinner color="dark" size="md">
            Load
          </Spinner>
        </div>
      ) : (
        <CardBody>
          <Modal isOpen={showModel} centered={true}>
            <ModalHeader toggle={() => setShowModel(false)}>
              {modalData.title}
            </ModalHeader>
            <ModalBody>
              <h5>{modalData.description}</h5>
              {/* <FormGroup>
                <Label for="exampleText">
                  Comment (optional)
                </Label>
                <Input
                  id="exampleText"
                  name="text"
                  onChange={(e)=>{
                    setModalData({...modalData,comment: e.target.value})
                  }}
                  type="textarea"
                />
              </FormGroup> */}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={() => {
                  setShowModel(false);
                }}
                color="light"
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                type="button"
                color="primary"
                disabled={is_modal_loading}
                className="waves-effect waves-light"
                onClick={() =>
                  execute_full_power(modalData.action, modalData.reservation)
                }
              >
                {is_modal_loading ? (
                  <Spinner color="light" size="sm">
                    Load
                  </Spinner>
                ) : (
                  "Save changes"
                )}
              </Button>
            </ModalFooter>
          </Modal>

          {/* chat modal */}
          {dialogOpen ? (
            <ChatDialog
              dialog_open={dialogOpen}
              toggle_modal={toggleDialog}
              reservation={selectedReservation}
            />
          ) : (
            ""
          )}

          {/* review modal */}
          {dialogReviewOpen ? (
            <ReviewDialog
              dialog_open={dialogReviewOpen}
              toggle_modal={toggleReviewDialog}
              reservation={selectedReservation}
            />
          ) : (
            ""
          )}

          <DataTable
            pagination
            // paginationServer
            columns={columns}
            progressPending={isLoading}
            progressComponent={<Loader />}
            // paginationTotalRows={10}
            // onChangePage={1}
            // onChangeRowsPerPage={()=>{}}
            data={reservations}
          // customStyles={{
          //   cells: {
          //     style: {
          //       maxWidth: '200px', // Adjust maximum width as needed
          //     }
          //   }
          // }}
          />
        </CardBody>
      )}
    </Card>
  );
}
