import React, { useState } from "react";

import { Alert, Button, Col, Container, Label, Row, Spinner } from "reactstrap";

// Redux
import { Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// actions
// import images
// import logodark from "../../assets/images/logo-dark.png";
// import logolight from "../../assets/images/logo-light.png";

import { POST_LOGIN } from "../../constants/api.constants";
import { post } from "../../helpers/api_helper";
import "./Auth.css";


const roles = {
  0: "admin",
  1: "member",
  2: "stagiaire"
}

const Login = () => {

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();




  const auth = async (props) => {
    setIsLoading(true);

    try {
      let params = {
        email,
        password,
      }
      let response = await post(POST_LOGIN, params);
      if (response.token) {
        // Cookies.set("token", response.token);
        localStorage.setItem("token", response.token);
        localStorage.setItem("firstname", response.user.firstname);
        localStorage.setItem("role", response.user.role);
        window.open("/dashboard", "_self");
        // return <Redirect to={"/dashboard"}/>
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.toString());
    }
  }
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={5}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <reCAPTCHA
                      sitekey="6LdtdTcmAAAAAOMgCdLyDJ4Ah805ivcfWpu9teGU"
                    />
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="#" className="logo">
                              <img width={"99px"} src="https://pippipyalah.com/static/images/logo.svg" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">
                            Welcome back to PipPipYalah
                          </h4>
                        </div>


                        {error ? <Alert color="danger">{error}</Alert> : ''}


                        <div className="p-2 mt-5">
                          <AvForm
                            onValidSubmit={auth}
                            className="form-horizontal"
                          >
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-mail-line auti-custom-input-icon"></i>
                              <Label htmlFor="useremail">Email</Label>
                              <AvField
                                name="email"
                                value={email ?? ''}
                                validate={{ email: true, required: true }}
                                type="email"
                                onChange={(element) => {
                                  setEmail(element.target.value)
                                }}
                                className="form-control"
                                id="useremail"
                                placeholder="Enter email"
                              />
                            </div>


                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="userpassword">Password</Label>
                              <AvField
                                name="password"
                                value={password ?? ''}
                                type="password"
                                onChange={(element) => {
                                  setPassword(element.target.value)
                                }}
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                              />
                            </div>
                            {/* <ReCAPTCHA
                                siteKey="6LdtdTcmAAAAAOMgCdLyDJ4Ah805ivcfWpu9teGU"
                               
                              /> */}
                            <div className="text-center">
                              <Button
                                color="success"
                                className="w-md waves-effect waves-light"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading
                                  ? (<Spinner color="white" size="sm">Load</Spinner>)
                                  : "Login"}

                              </Button>
                            </div>


                          </AvForm>
                        </div>

                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}


export default Login;