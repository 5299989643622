import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Table,
  CardText,
  Row,
  Col,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
//Import Breadcrumb
import Select from "react-select";

import { get } from "../../helpers/api_helper";
import { GET_MEMBERS } from "../../constants/api.constants";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { memberDashboardSelection } from "../../data/selects.data";

const columns_rides = [
  {
    name: "Fullname",
    selector: (row) => row.fullname,
  },
  {
    name: "Total",
    selector: (row) => row.total_rides,
  },
];

const MemberDashboard = () => {
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentSelection, setCurrentSelection] = useState({
    value: "0",
    label: "Drivers",
  });

  const fetchStats = async () => {
    try {
      const response = await get(GET_MEMBERS + "state/");
      let top_10_users_by_ride_new = [];
      console.log(response);
      for (let i = 0; i < response.state.top_10_users_by_ride.length; i++) {
        const top_10_users_by_ride_single =
          response.state.top_10_users_by_ride[i];
        top_10_users_by_ride_new.push({
          fullname: (
            <Link to={"/member-detail/" + top_10_users_by_ride_single.user_id}>
              {" "}
              {top_10_users_by_ride_single.first_name +
                " " +
                top_10_users_by_ride_single.last_name}{" "}
            </Link>
          ),
          total_rides: <div>{top_10_users_by_ride_single.num_rides}</div>,
        });
      }

      let new10profitableDrivers = [];
      for (
        let i = 0;
        i < response.state.top_10_users_by_payment_received.length;
        i++
      ) {
        const single = response.state.top_10_users_by_payment_received[i];
        new10profitableDrivers.push({
          fullname: (
            <Link to={"/member-detail/" + single.user_id}>
              {" "}
              {single.first_name + " " + single.last_name}{" "}
            </Link>
          ),
          total_rides: <div>{parseInt(single.total_transactions)} (Dh)</div>,
        });
      }

      let top_10_users_by_res = [];
      for (
        let i = 0;
        i < response.state.top_10_users_by_reservation.length;
        i++
      ) {
        const single = response.state.top_10_users_by_reservation[i];
        top_10_users_by_res.push({
          fullname: (
            <Link to={"/member-detail/" + single.user_id}>
              {" "}
              {single.first_name + " " + single.last_name}{" "}
            </Link>
          ),
          total_rides: <div>{single.num_rides}</div>,
        });
      }
      let top_10_users_by_transactions_sent = [];
      for (
        let i = 0;
        i < response.state.top_10_users_by_payment_sent.length;
        i++
      ) {
        const single = response.state.top_10_users_by_payment_sent[i];
        top_10_users_by_transactions_sent.push({
          fullname: (
            <Link to={"/member-detail/" + single.user_id}>
              {" "}
              {single.first_name + " " + single.last_name}{" "}
            </Link>
          ),
          total_rides: <div>{parseInt(single.total_transactions)} (Dh)</div>,
        });
      }
      setStats({
        ...stats,
        top_10_users_by_ride: top_10_users_by_ride_new,
        new10profitableDrivers,
        top_10_users_by_res,
        top_10_users_by_transactions_sent,
        global: response.state.global_stats[0],
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        {!isLoading ? (
          <Container fluid>
            <Row>
              <Col xl={3}>
                <Card className="custom-card">
                  <CardBody>
                    <CardText>New Users (Today)</CardText>
                    <CardTitle>+ {stats?.global?.today_reg_users}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2}>
                <Card className="custom-card">
                  <CardBody>
                    <CardText>Total Users</CardText>
                    <CardTitle>{stats?.global?.total_users} </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3}>
                <Card className="custom-card">
                  <CardBody>
                    <CardText>Total (Google)</CardText>
                    <CardTitle>{stats?.global?.google_reg_users} </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2}>
                <Card className="custom-card">
                  <CardBody>
                    <CardText>Total (Facebook)</CardText>
                    <CardTitle>{stats?.global?.facebook_reg_users} </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2}>
                <Card className="custom-card">
                  <CardBody>
                    <CardText>Total (Apple)</CardText>
                    <CardTitle>{stats?.global?.apple_reg_users} </CardTitle>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={3}>
                <Card >
                  <Select
                    value={currentSelection}
                    onChange={(event) => {
                      setCurrentSelection(event);
                    }}
                    name="transaction_type"
                    options={memberDashboardSelection}
                    classNamePrefix="select2-selection"
                  />
                </Card>
              </Col>
            </Row>

            {currentSelection?.value === "0" ||
            currentSelection?.value === "2" ? (
              <Row>
                <Col lg={6}>
                  <Card className="custom-card">
                    <CardBody>
                      <h4 className="card-title" style={{ marginBottom: 20 }}>
                        Top 10 Drivers
                      </h4>

                      <div className="table-responsive">
                        <DataTable
                          pagination
                          columns={columns_rides}
                          paginationRowsPerPageOptions={[5, 10]}
                          progressPending={false}
                          paginationPerPage={5}
                          progressComponent={<Loader />}
                          paginationTotalRows={10}
                          data={stats?.top_10_users_by_ride}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="custom-card">
                    <CardBody>
                      <h4 className="card-title" style={{ marginBottom: 20 }}>
                        Top 10 Drivers (by amount recieved)
                      </h4>

                      <div className="table-responsive">
                        <DataTable
                          pagination
                          columns={columns_rides}
                          paginationRowsPerPageOptions={[5, 10]}
                          progressPending={false}
                          paginationPerPage={5}
                          progressComponent={<Loader />}
                          paginationTotalRows={10}
                          data={stats?.new10profitableDrivers}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {currentSelection?.value === "1" ||
            currentSelection?.value === "2" ? (
              <Row>
                <Col lg={6}>
                  <Card className="custom-card">
                    <CardBody>
                      <h4 className="card-title" style={{ marginBottom: 20 }}>
                        Top 10 Passengers
                      </h4>

                      <div className="table-responsive">
                        <DataTable
                          pagination
                          columns={columns_rides}
                          paginationRowsPerPageOptions={[5, 10]}
                          progressPending={false}
                          paginationPerPage={5}
                          progressComponent={<Loader />}
                          paginationTotalRows={10}
                          data={stats?.top_10_users_by_res}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="custom-card">
                    <CardBody>
                      <h4 className="card-title" style={{ marginBottom: 20 }}>
                        Top 10 Passengers (by Spent)
                      </h4>

                      <div className="table-responsive">
                        <DataTable
                          pagination
                          columns={columns_rides}
                          paginationRowsPerPageOptions={[5, 10]}
                          progressPending={false}
                          paginationPerPage={5}
                          progressComponent={<Loader />}
                          paginationTotalRows={10}
                          data={stats?.top_10_users_by_transactions_sent}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </React.Fragment>
  );
};
export default MemberDashboard;
