import {
    UPDATE_UNCOMPLETED_PAYMENT,
    HIDE_UNCOMPLETED_PAYMENT,
    UPDATE_RESERVATIONS,
    HIDE_RESERVATIONS,
    UPDATE_RIDES,
    HIDE_RIDES,
    UPDATE_FRAUD,
    HIDE_FRAUD,
    UPDATE_OPPOSITES_RIDES,
    HIDE_OPPOSITES_RIDES,
    UPDATE_SIGNALEMENTS,
    HIDE_SIGNALEMENTS
} from './actionTypes';

// uncompleted payments
export const updateUncompletedPaymentNotification = (notificationType, count) => ({
    type: UPDATE_UNCOMPLETED_PAYMENT,
    payload: { notificationType, count },
})

export const hideUncompletedPaymentNotification = (notificationType) => ({
    type: HIDE_UNCOMPLETED_PAYMENT,
    payload: { notificationType },
})

// reservations
export const updateReservationNotification = (notificationType, count) => ({
    type: UPDATE_RESERVATIONS,
    payload: {notificationType, count}
})

export const hideReservationNotification = (notificationType) => ({
    type: HIDE_RESERVATIONS,
    payload: { notificationType },
})

// rides
export const updateRidesNotification = (notificationType, count) => ({
    type: UPDATE_RIDES,
    payload: {notificationType, count}
})

export const hideRidesNotification = (notificationType) => ({
    type: HIDE_RIDES,
    payload: { notificationType },
})

// opposites rides
export const updateOppositesRidesNotification = (notificationType, count) => ({
    type: UPDATE_OPPOSITES_RIDES,
    payload: {notificationType, count},
})

export const hideOppositesRidesNotification = (notificationType) => ({
    type: HIDE_OPPOSITES_RIDES,
    payload: {notificationType},
})

// fraud
export const updateFraudNotification = (notificationType, count) => ({
    type: UPDATE_FRAUD,
    payload: {notificationType, count}
})

export const hideFraudNotification = (notificationType) => ({
    type: HIDE_FRAUD,
    payload: { notificationType },
})

// signalements
export const updateSignalementsNotification = (notificationType, count) => ({
    type: UPDATE_SIGNALEMENTS,
    payload: { notificationType, count },
})

export const hideSignalementsNotification = (notificationType) => ({
    type: HIDE_SIGNALEMENTS,
    payload: { notificationType },
})