import "flatpickr/dist/themes/material_blue.css";
import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";

//Import Breadcrumb

// import { orders, rides } from "../../common/data"

import { CITIES, GET_MEMBERS, RIDES, VEHICLES } from "../../constants/api.constants";
import { get, post } from "../../helpers/api_helper";

import Loader from "../../components/Loader/Loader";
import { cities } from "../../data/selects.data";


const PublishRides = () => {
    const [partners, setPartners] = useState([]);
    const [dateRange, setRideRange] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({ label: 'All', value: 0 });
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [fromCity, setFromCity] = useState({ label: 'All', value: 0 });
    const [toCity, setToCity] = useState({ label: 'All', value: 0 });
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [vehicles, setVehicles] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [districtsArr, setDistrictsArr] = useState([]);
    const [interCityModal, setInterCityModal] = useState(false);
    const [intermediateCitiesSingle, setIntermediateCitiesSingle] = useState([]);
    const [intermediateCities, setIntermediateCities] = useState([]);
    const [intermediateCitiesDistricts, setIntermediateCitiesDistricts] = useState([]);
    const [ride, setRide] = useState([]);

    useEffect(() => {
        fetch_partners();
    }, []);



    const fetchVehicles = async (user_id) => {
        try {
            const response = await get(VEHICLES + '?user_id=' + user_id);
            let newVec = [];
            for (let i = 0; i < response.vehicles.length; i++) {
                const vehicle = response.vehicles[i];
                newVec.push({ label: `${vehicle.brand_name} ${vehicle.model_name} ${vehicle.name}`, value: `${vehicle.vehicle_id}` },)
            }
            setVehicles(newVec);
        } catch (error) {
            console.log(error);
        }
    };

    // 0 departure; 1: arrival; 2: inntermediate
    const fetchDistricts = async (city_id, type = 0) => {
        try {
            const response = await get(CITIES + 'districts/?city_id=' + city_id);
            let newVec = [];
            for (let i = 0; i < response.districts.length; i++) {
                const district = response.districts[i];
                newVec.push({ label: `${district.name}`, value: `${district.id}` },)
            }
            if (type === 0) {
                setDistricts(newVec);
            } else if (type === 1) {
                setDistrictsArr(newVec);
            } else if (type === 2) {
                setIntermediateCitiesDistricts(newVec)
            }
        } catch (error) {
            console.log(error);
        }
    };


    const fetch_partners = async () => {
        setIsLoading(true);
        try {
            const response = await get(GET_MEMBERS + '?page=0&per_page=100&is_company=1');
            if (!response) return;
            var tempPartners = []
            for (let i = 0; i < response.members.length; i++) {
                const partner = response.members[i];
                tempPartners.push({ label: `${partner.last_name} ${partner.first_name}`, value: `${partner.id}` },)
            }
            setPartners(tempPartners);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    const save_ride = async () => {
        setIsLoading(true);
        try {
            let intermediate_c = [];
            for (let i = 0; i < intermediateCities.length; i++) {
                const interCity = intermediateCities[i];
                intermediate_c.push({
                    city_id: interCity.city.id,
                    district_id: interCity.district.id
                })
            }

            const response = await post(RIDES, {
                vehicle_id: ride.vehcile.value,
                price: ride.price,
                seats: ride.seats,
                luggage: ride.luggage,
                leaving_date_time: ride.departure_date,
                arrival_date_time: ride.arrival_date,
                from_city: ride.departure_city.value,
                to_city: ride.arrival_city.value,
                from_district_id: ride.departure_distrit.value,
                to_district_id: ride.arrival_district.value,
                user_id: ride.partner.value,
                intermediate_cities: intermediate_c,
                is_direct_booking: ['221894'].includes(ride.partner.value?.toString())
            });

            alert("Ride Published ")

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {!isLoading ? (<Row>
                        <Col xl="6" md={12}>
                            <Card className="custom-card">
                                <CardBody>
                                    <Row>
                                        <Col xs="12">
                                            <div className="col mb-3">
                                                <Label>Partner</Label>
                                                <Select
                                                    onChange={(e) => {
                                                        fetchVehicles(e.value);
                                                        setRide({ ...ride, partner: e })
                                                    }}
                                                    name="city"
                                                    options={partners}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                        <Col xs="12">
                                            <div className="col mb-3">
                                                <Label>Vehicle</Label>
                                                <Select
                                                    onChange={(e) => {
                                                        setRide({ ...ride, vehcile: e })
                                                    }}
                                                    name="city"
                                                    options={vehicles}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div className="col mb-3">
                                                <Label>Departure</Label>
                                                <Select
                                                    name="city"
                                                    onChange={(e) => {
                                                        fetchDistricts(e.value, 0);
                                                        setRide({ ...ride, departure_city: e })
                                                    }}
                                                    options={cities}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div className="col mb-3">
                                                <Label>Arrival</Label>
                                                <Select
                                                    onChange={(e) => {
                                                        fetchDistricts(e.value, 1);
                                                        setRide({ ...ride, arrival_city: e })
                                                    }}
                                                    name="city"
                                                    options={cities}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div className="col mb-3">
                                                <Label>Departure district</Label>
                                                <Select
                                                    name="city"
                                                    onChange={(e) => {
                                                        setRide({ ...ride, departure_distrit: e })
                                                    }}
                                                    options={districts}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div className="col mb-3">
                                                <Label>Arrival district</Label>
                                                <Select
                                                    onChange={(e) => {
                                                        setRide({ ...ride, arrival_district: e })
                                                    }}
                                                    name="city"
                                                    options={districtsArr}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </Col>

                                        {/* <Col xs="6">
                                            <div className="col mb-3" xl="3">
                                                <Label>Departure Date</Label>
                                                <InputGroup>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="dd M,yyyy"
                                                        onChange={(e) => {
                                                            setRide({ ...ride, departure_date: e })
                                                        }}
                                                        options={{
                                                            mode: "range",
                                                            dateFormat: "Y-m-d"
                                                        }}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col> */}
                                        <Col xs="6">
                                            <div className="col mb-3" xl="3">
                                                <Label>Departure Date/Time (v2)</Label>
                                                <InputGroup>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="HH:MM"

                                                        onChange={(e) => {

                                                            // const inputDateString = "Wed Aug 02 2023 17:30:00 GMT+0100 (GMT+01:00)";

                                                            // Step 1: Create a new Date object from the given date string
                                                            const dateObject = new Date(e[0]);

                                                            // Step 2: Extract the year, month, and day from the Date object
                                                            const year = dateObject.getFullYear();
                                                            const month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1 to get the correct month number
                                                            const day = dateObject.getDate();
                                                            const hours = dateObject.getHours();
                                                            const minutes = dateObject.getMinutes();
                                                            const seconds = dateObject.getSeconds();


                                                            // Step 3: Format the extracted values into the desired format "yyyy-m-d T"
                                                            const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hours}:${minutes}:${seconds}`;

                                                            console.log(formattedDate); // Output: "2023-08-02 T"
                                                            console.log(e[0]);
                                                            setRide({ ...ride, departure_date: formattedDate })
                                                        }}
                                                        options={{
                                                            time_24hr: true,
                                                            enableTime: true,
                                                            dateFormat: 'h:i K'
                                                        }}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div className="col mb-3" xl="3">
                                                <Label>Arrival Date/Time (v2)</Label>
                                                <InputGroup>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="HH:MM"
                                                        onChange={(e) => {

                                                            // const inputDateString = "Wed Aug 02 2023 17:30:00 GMT+0100 (GMT+01:00)";

                                                            // Step 1: Create a new Date object from the given date string
                                                            const dateObject = new Date(e[0]);

                                                            // Step 2: Extract the year, month, and day from the Date object
                                                            const year = dateObject.getFullYear();
                                                            const month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1 to get the correct month number
                                                            const day = dateObject.getDate();
                                                            const hours = dateObject.getHours();
                                                            const minutes = dateObject.getMinutes();
                                                            const seconds = dateObject.getSeconds();


                                                            // Step 3: Format the extracted values into the desired format "yyyy-m-d T"
                                                            const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hours}:${minutes}:${seconds}`;

                                                            console.log(formattedDate); // Output: "2023-08-02 T"
                                                            console.log(e[0]);
                                                            setRide({ ...ride, arrival_date: formattedDate })
                                                        }}
                                                        options={{
                                                            time_24hr: true,
                                                            enableTime: true,
                                                            dateFormat: 'h:i K'
                                                        }}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col xs="8">
                                            <div className="col mb-3" xl="3" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                <Label>Intermediate Cities</Label>
                                                {intermediateCities.map((city, id) => {
                                                    return (<>
                                                        <Link key={id} className="inter-city-area">{city.city.city_name} - {city.district.district_name}</Link>
                                                    </>)
                                                })}
                                                <Link onClick={() => {
                                                    setInterCityModal(true);
                                                }}>Add</Link>
                                            </div>
                                            <Modal
                                                isOpen={interCityModal}
                                                toggle={() => setInterCityModal(!interCityModal)}
                                                centered={true}
                                            >
                                                <ModalHeader toggle={() => setInterCityModal(false)}>
                                                    Add Intermediate city
                                                </ModalHeader>
                                                <ModalBody>
                                                    <Row>
                                                        <Col xs="6">
                                                            <div className="col mb-3">
                                                                <Label>City name</Label>
                                                                <Select
                                                                    onChange={(e) => {
                                                                        fetchDistricts(e.value, 2)
                                                                        setIntermediateCitiesSingle({
                                                                            ...intermediateCitiesSingle, city: {
                                                                                city_name: e.label,
                                                                                id: e.value,
                                                                            }
                                                                        })
                                                                    }}
                                                                    name="city"
                                                                    options={cities}
                                                                    classNamePrefix="select2-selection"
                                                                />

                                                            </div>
                                                        </Col>
                                                        <Col xs="6">
                                                            <div className="col mb-3">
                                                                <Label>District</Label>
                                                                <Select
                                                                    onChange={(e) => {
                                                                        setIntermediateCitiesSingle({
                                                                            ...intermediateCitiesSingle, district: {
                                                                                district_name: e.label,
                                                                                id: e.value,
                                                                            }
                                                                        })
                                                                    }}
                                                                    name="district"
                                                                    options={intermediateCitiesDistricts}
                                                                    classNamePrefix="select2-selection"
                                                                />

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button
                                                        type="button"
                                                        onClick={() => setInterCityModal(false)}
                                                        color="light"
                                                        className="waves-effect"
                                                    >
                                                        Close
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        onClick={() => {
                                                            setIntermediateCities([...intermediateCities, intermediateCitiesSingle]);
                                                            setInterCityModal(false)
                                                            setRide({ ...ride, intermediate_cities: intermediateCities })
                                                        }}
                                                        className="waves-effect waves-light"
                                                    >
                                                        Add
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>
                                        </Col>
                                        <Col xs="6">
                                            <div className="col mb-3" xl="3">
                                                <Label className="form-label">Price (DH)</Label>
                                                <Input
                                                    onChange={(e) => {
                                                        setRide({ ...ride, price: e.target.value })
                                                    }}
                                                    type="number"
                                                    placeholder="99.99"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div className="col mb-3" xl="3">
                                                <Label className="form-label">Seats</Label>
                                                <Input
                                                    onChange={(e) => {
                                                        setRide({ ...ride, seats: e.target.value })
                                                    }}
                                                    type="number"
                                                    placeholder="5"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div className="col mb-3" xl="3">
                                                <Label className="form-label">Luggage</Label>
                                                <Select
                                                    onChange={(e) => {
                                                        setRide({ ...ride, luggage: e.value })
                                                    }}
                                                    options={[
                                                        { label: "Small", value: 1 },
                                                        { label: "Medium", value: 2 },
                                                        { label: "Larg", value: 3 },
                                                    ]}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="12">
                                            <div className="col mt-4" xl="3" style={{ "margin-top": "28px !important" }}>
                                                <Button color="success" type="button" className="waves-effect waves-light me-1" onClick={() => {
                                                    console.log(ride);
                                                    save_ride();
                                                }}>
                                                    <i className="ri-search-line align-middle me-2"></i> Publish Now
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>) : <Loader />}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PublishRides;
