// src/pages/BrandsPage.js
import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    Button,
    Card,
    CardBody,
    Container,
    Table
} from "reactstrap";
import { BASE_URL } from "../../constants/api.constants";
import { get } from "../../helpers/api_helper";
import BrandModal from "./components/BrandModal";

function BrandsPage() {
    const [modal, setModal] = useState(false);
    const [editingBrand, setEditingBrand] = useState(null);
    const queryClient = useQueryClient();

    const toggle = () => {
        setModal(!modal);
        if (!modal) {
            setEditingBrand(null);
        }
    };

    const { data: brands, isLoading, error } = useQuery("brands", () =>
        get(BASE_URL + "vehicles/brands")
    );

    const createBrandMutation = useMutation(
        (newBrand) => axios.post(BASE_URL + "vehicles/brands", newBrand),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("brands");
                toggle();
            },
        }
    );

    const updateBrandMutation = useMutation(
        (data) => axios.put(`${BASE_URL}vehicles/brands/${data.id}`, { brand_name: data.brand_name }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("brands");
                toggle();
            },
        }
    );

    const handleSubmit = (data) => {
        console.log(data)
        if (editingBrand) {
            updateBrandMutation.mutate({
                id: editingBrand.brand_id,
                brand_name: data.brand_name
            });
        } else {
            createBrandMutation.mutate({
                brand_name: data.brand_name
            });
        }
    };

    const handleEdit = (brand) => {
        setEditingBrand(brand);
        setModal(true);
    };

    const handleDelete = (brandId) => {
        if (window.confirm("Are you sure you want to delete this brand?")) {
            // deleteBrandMutation.mutate(brandId);
        }
    };

    return (
        <Container fluid>
            <Card className="mt-5">
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h2 className="mb-0">Brand Management</h2>
                        <Button color="primary" onClick={toggle}>
                            Add New Brand
                        </Button>
                    </div>

                    {isLoading && <p>Loading brands...</p>}
                    {error && <p>Error loading brands</p>}
                    {brands && (
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Brand Name</th>
                                    <th>Models Count</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {brands.map((brand) => (
                                    <tr key={brand.brand_id}>
                                        <td>{brand.brand_id}</td>
                                        <td>{brand.brand_name}</td>
                                        <td>{brand.models_count}</td>
                                        <td>
                                            <Button
                                                color="warning"
                                                size="sm"
                                                className="me-2"
                                                onClick={() => handleEdit(brand)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                color="danger"
                                                size="sm"
                                                onClick={() => handleDelete(brand.brand_id)}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </CardBody>
            </Card>

            <BrandModal
                isOpen={modal}
                toggle={toggle}
                onSubmit={handleSubmit}
                editingBrand={editingBrand}
            />
        </Container>
    );
}

export default BrandsPage;
