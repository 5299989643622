import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Table,
  Button,
  CardImg,
  CardText,
  Input,
  Row,
  FormGroup,
  Label,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Modal,
} from "reactstrap";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import img1 from "../../assets/images/small/img-1.jpg";
import { del, get, post, put } from "../../helpers/api_helper";
import {
  GET_MEMBERS,
  GET_RESERVATIONS,
  GET_RIDES,
} from "../../constants/api.constants";
import SweetAlert from "react-bootstrap-sweetalert";
import Cookies from "js-cookie";
import { V4 } from "../../constants/api.constants";
import { Link } from "react-router-dom";
import { block_periods } from "../../data/selects.data";
import Vehicles from "../users/Vehicles.component";
import DataListComponent from "./RenderHistoric";
const memberCommission = [
  {
    name: "Bilal ",
    commission: "5309.4 Dhs",
  },
  {
    name: "khan ",
    commission: "5309.4 Dhs",
  },
  {
    name: "huzaifa ",
    commission: "5309.4 Dhs",
  },
  {
    name: "ali ",
    commission: "5309.4 Dhs",
  },
  {
    name: "dev ",
    commission: "5309.4 Dhs",
  },
  {
    name: "test ",
    commission: "5309.4 Dhs",
  },
];

const membercancelingRides = [
  {
    name: "Bilal ",
    number: 502,
  },
  {
    name: "talha ",
    number: 505,
  },
  {
    name: "hasham ",
    number: 695,
  },
  {
    name: "rana ",
    number: 41,
  },
  {
    name: "muzzamil ",
    number: 687,
  },
  {
    name: "armani ",
    number: 62,
  },
];

class MemberDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Dashboard", link: "/member-dashboard" },
        { title: "Members", link: "#" },
      ],
      reports: [
        { data: [{ title: "Active Members Today", value: "1452" }] },
        {
          data: [
            { title: "Members Registered today", value: " 38452" },
            { title: "Avg Registration per day", value: "1422" },
          ],
        },
        {
          data: [
            { title: "Cancelled Empty Ride", value: "15" },
            { title: "Cancelled Ride", value: "15" },
            { title: "canceled", value: "15" },
          ],
        },
        {
          data: [
            { title: "Published Offers", value: "200" },
            { title: "Cancelled Offers", value: "100" },
          ],
        },
      ],
      member: {},
      login_history: [],
      block_history: [],
      user_ribs: [],
      dropdownBlock: false,
      memberCommission: memberCommission,
      memberRides: [],
      total_rides: 0,
      rides: {
        total_rides: 0,
        completed_rides: 0,
        ongoing_rides: 0,
        cancelled_ride: 0,
      },
      reservations: {
        total_reservations: 0,
        completed_reservations: 0,
        pending_reservations: 0,
        cancelled_reservations: 0,
        paid_reservations: 0,
      },
      alerts: {
        total_alerts: 0,
      },
      vehicles: {
        total_vehicles: 0,
      },
      membercancelingRides: membercancelingRides,
      user_comments: [],
      modal_standard: false,
      isBtnLoading: false,
      comment: "",
      modal_block: false,
      block_status: null,
      memberReservations: [],
      isMemberLoading: true,
    };
  }

  tog_standard() {
    this.setState({ modal_standard: !this.state.modal_standard });
  }
  tog_block() {
    this.setState({ modal_block: !this.state.modal_block });
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.fetchMember(id);
    this.fetchMemberComments(id);
    this.fetchMemberRides(id);
    this.fetchMemberState(id);
    this.fetchMemberReservations(id);
  }

  fetchMember = async (id) => {
    this.setState({ isMemberLoading: true });
    try {
      const response = await get(GET_MEMBERS + "member/" + id);
      this.setState({
        member: { ...response.member, reviews: response.reviews, app_version: response.app_version },
        login_history: response.login_history,
        block_history: response.block_history,
        user_ribs: response.user_ribs,

      });
      this.setState({ isMemberLoading: false })
    } catch (error) {
      console.log(error);
    }
  };
  fetchMemberComments = async (id) => {
    const config = {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
    try {
      const response = await get(V4 + "comments-history?user_id=" + id, config);
      this.setState({ user_comments: response.history });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  toggleBlockDropdown = () => {
    this.setState({ dropdownBlock: !this.dropdownBlock });
  };

  fetchMemberRides = async (id) => {
    try {
      const response = await post(GET_RIDES + "user_rides/", {
        user_id: id,
      });
      this.setState({ memberRides: response.rides });
      console.log(response.rides);
    } catch (error) {
      console.log(error);
    }
  };
  fetchMemberReservations = async (id) => {
    try {
      const response = await get(GET_RESERVATIONS + id);
      this.setState({ memberReservations: response.reservations });
      console.log(response.reservations);
    } catch (error) {
      console.log(error);
    }
  };
  fetchMemberState = async (id) => {
    try {
      const response = await get(GET_MEMBERS + "/state/" + id);
      // this.setState({ memberState: response.state });
      // let tot =
      this.setState({ rides: response.state.rides });
      this.setState({ reservations: response.state.reservations });
      this.setState({ alerts: response.state.alerts });
      this.setState({ vehicles: response.state.vehicles });
      // console.log(this.state.memberState);
    } catch (error) {
      console.log(error);
    }
  };

  handleBlockUser = async () => {
    try {
      await post(GET_MEMBERS + "member_status", {
        user_id: this.state.member.id,
        action: this.state.member.ban_type === 0 ? 1 : 2,
        block_status: this.state.block_status.value,
        comments: this.state.comment,
      });
      this.setState({ dropdownBlock: false, modal_block: false, comment: "" });
      this.fetchMember(this.state.member.id);
    } catch (error) {
      alert("An Error occurred");
    }
  };

  update_user = async () => {
    try {
      await put(`${GET_MEMBERS}member/${this.state.member.id}`, {
        ...this.state.member,
        comments: this.state.comment,
      });
      this.fetchMember(this.state.member.id);
      this.setState({ isBtnLoading: false, comment: "" });
      this.tog_standard();
    } catch (error) {
      console.log(error);
      alert("An Error occurred");
    }
  };

  save_comment = async () => {
    this.setState({ isBtnLoading: true });
    try {
      await post(`${GET_MEMBERS}member/add-comment/${this.state.member.id}`, {
        comment: this.state.comment,
      });
      this.setState({ isBtnLoading: false, comment: "" });
      this.fetchMemberComments(this.state.member.id);
    } catch (error) {
      console.log(error);
      this.setState({ isBtnLoading: false });
      alert("An Error occurred");
    }
  };
  getRating = (totalReviews, positiveReviews, negativeReviews) => {
    if (totalReviews === 0) {
      return "N/A";
    }

    // Calculate the percentage of positive reviews
    const percentage = positiveReviews / totalReviews * 5;

    // Round the percentage to 1 decimal place
    const roundedPercentage = Math.round(percentage * 10) / 10;

    // Return the rating as a string, with the percentage and the /5
    return `${roundedPercentage}/${5}`;
  }
  render() {
    let member_rides_results = this.state.memberRides.map((ride, i) => (
      <tr>
        <th className="d-none">{ride.id}</th>
        <td>
          <Link
            to={`/ride-detail/${ride.id}`}
          >{`${ride.departure_city} > ${ride.arrival_city}`}</Link>
        </td>
        <td>{ride.departure_date}</td>
        <td>{ride.seats}</td>
        <td>{ride.status === 1 ? <span className="badge bg-warning">Ongoing</span> : (ride.status === 2 ? <p className="badge bg-danger">Cancelled</p> : <p className="badge bg-info">Completed</p>)}</td>
        <td></td>
      </tr>
    ));
    let member_reservations_results = this.state.memberReservations.map(
      (ride, i) => (
        <tr>
          <th className="d-none">{ride.id}</th>
          <td>
            <Link
              to={`/ride-detail/${ride.ride_id}`}
            >{`${ride.departure_city} > ${ride.arrival_city}`}</Link>
          </td>
          <td>{ride.departure_date}</td>
          <td>{ride.seats}</td>
          <td>{ride.status === 0 ? <span className="badge bg-warning">Pending</span> : (ride.status === 1 ? <p className="badge bg-info">Pre-approved</p> : (ride.status === 2 ? <p className="badge bg-paid">Paid</p> : (ride.status === 3 ? <p className="badge bg-completed">Completed</p> : <p className="badge bg-danger">Cancelled</p>)))}</td>
          <td></td>
        </tr>
      )
    );

    return (
      <React.Fragment>
        {this.state.dropdownBlock ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={this.handleBlockUser}
            onCancel={() =>
              this.setState({
                dropdownBlock: false,
              })
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : (
          ""
        )}

        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xl={2}>
                <Card className="custom-card">
                  <CardBody>
                    <CardTitle>{this.state.rides.total_rides}</CardTitle>
                    <CardText>Total Rides</CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2}>
                <Card className="custom-card">
                  <CardBody>
                    <CardTitle>
                      {this.state.rides.completed_rides ?? 0}
                    </CardTitle>
                    <CardText>Completed Rides</CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2}>
                <Card className="custom-card">
                  <CardBody>
                    <CardTitle>
                      {this.state.rides.cancelled_ride ?? 0}{" "}
                    </CardTitle>
                    <CardText>Cancelled Rides</CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2}>
                <Card className="custom-card">
                  <CardBody>
                    <CardTitle>
                      {this.state.reservations.total_reservations}{" "}
                    </CardTitle>
                    <CardText>Total Reservations</CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2}>
                <Card className="custom-card">
                  <CardBody>
                    <CardTitle>
                      {this.state.reservations.completed_reservations}{" "}
                    </CardTitle>
                    <CardText>Completed Reservations</CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2}>
                <Card className="custom-card">
                  <CardBody>
                    <CardTitle>
                      {this.state.reservations.cancelled_reservations}{" "}
                    </CardTitle>
                    <CardText>Cancelled Reservations</CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* ---------------------------------------2nd section =========== */}

            <Row>
              {/* col 1 */}
              <Col xl={4}>
                <Card className="custom-card">
                  <CardImg
                    className="card-profile"
                    src={
                      `https://pippipyalahcom.s3.eu-west-3.amazonaws.com/` +
                      (this.state.member?.profile_photo
                        ? this.state.member.profile_photo?.includes("undefined")
                          ? "images/users/user_default_pic.svg"
                          : this.state.member.profile_photo
                        : "images/users/user_default_pic.svg")
                    }
                    alt={this.state.member.first_name ?? "0"}
                  />

                  <CardBody>
                    <CardText>Wallet Amount</CardText>
                    <CardTitle>
                      {this.state.member.wallet_amount ?? 0}{" "}
                    </CardTitle>
                    <CardText>Gift Wallet</CardText>
                    <CardTitle>{this.state.member.gift_wallet ?? 0}</CardTitle>
                    <CardText>CIN Number</CardText>
                    <CardTitle>{this.state.member.doc_number ?? 0}</CardTitle>
                    <CardText>Email address</CardText>
                    <CardTitle>{this.state.member.user_email ?? ""}</CardTitle>
                    <CardText>Phone</CardText>
                    <CardTitle>
                      {this.state.member.mobile_number ?? 0}
                    </CardTitle>
                    <CardText>Address</CardText>
                    <CardTitle>{this.state.member.address ?? 0}</CardTitle>
                    <CardText>Gender</CardText>
                    <CardTitle>
                      {this.state.member.gender == null
                        ? "Unknown"
                        : this.state.member.gender === 2
                          ? "Female"
                          : "Male"}
                    </CardTitle>
                    <CardText>Date Of Birth</CardText>
                    <CardTitle>{this.state.member.dob ?? 0}</CardTitle>
                    <CardText>Registration Date</CardText>
                    <CardTitle>{this.state.member.created_date ?? 0}</CardTitle>
                    <CardText>Registration From</CardText>
                    <CardTitle>{this.state.member.device_brand ?? 0}</CardTitle>
                    <CardText>Number ID</CardText>
                    <CardTitle>
                      {this.state.member.document_number ?? ""}
                    </CardTitle>
                    <CardText>App Verison</CardText>
                    <CardTitle>
                      {this.state.member.app_version ?? ""}
                    </CardTitle>
                  </CardBody>
                </Card>
                <Card className="custom-card">
                  <CardBody>
                    <h4>Historic</h4>
                    <>
                      <DataListComponent data={this.state.user_comments.length ? this.state.user_comments : []} />
                      {/* {this.state.user_comments.map((e) => {
                        return (
                          <div className="user-comment-history">
                            <p>{e.comment}</p>
                            <i>
                              {" "}
                              - {e.firstname + " " + e.lastname} at{" "}
                              {e.created_date}
                            </i>
                          </div>
                        );
                      })} */}
                    </>
                  </CardBody>
                </Card>
                <Card className="custom-card">
                  <CardBody className="chat-container">
                    <h4>Login History</h4>
                    {this.state.login_history.map((item) => {
                      return (
                        <div className="user-comment-history">
                          <p>
                            Device:{" "}
                            <strong>
                              {item.device_brand} {item.base_os}
                            </strong>{" "}
                            <br />
                            IP: <strong>{item.ip_address}</strong>
                            <br />
                            MAC Address: <strong>{item.mac_address}</strong>
                            <br />
                            System Version :{" "}
                            <strong>{item.system_version}</strong>
                          </p>
                          <i>- {item.login_date}</i>
                        </div>
                      );
                    })}
                  </CardBody>
                </Card>
                <Card className="custom-card">
                  <CardBody className="chat-container">
                    <h4>Block History</h4>
                    {this.state.block_history.map((item) => {
                      return (
                        <div className="user-comment-history">
                          <h5>
                            {item.action_id === 1 ? "Block" : ""}
                            {item.action_id === 2 ? "Unblock" : ""}
                            {item.action_id === 3 ? "Add comment" : ""}
                            { }
                            {item.action_id !== 2 ? (
                              <strong>
                                {JSON.parse(item.data)?.block_status === "0"
                                  ? " for 15 days"
                                  : ""}
                                {JSON.parse(item.data)?.block_status === "-1"
                                  ? " forever"
                                  : ""}
                                {JSON.parse(item.data)?.block_status === "1"
                                  ? " for 1 month"
                                  : ""}
                                {JSON.parse(item.data)?.block_status === "2"
                                  ? " for 3 months"
                                  : ""}
                                {JSON.parse(item.data)?.block_status === "3"
                                  ? " for 8 hours"
                                  : ""}
                              </strong>
                            ) : (
                              ""
                            )}

                            {/* <strong>
                              {item.device_brand} {item.base_os}
                            </strong>{" "}
                            <br />
                            IP: <strong>{item.ip_address}</strong>
                            <br />
                            MAC Address: <strong>{item.mac_address}</strong>
                            <br />
                            System Version :{" "}
                            <strong>{item.system_version}</strong> */}
                          </h5>
                          <p>{item.comments}</p>
                          <i>
                            -{item.firstname} {item.date}
                          </i>
                        </div>
                      );
                    })}
                  </CardBody>
                </Card>
                <Card className="custom-card">
                  <CardBody className="chat-container">
                    <h4>User Ribs</h4>
                    {this.state.user_ribs.map((item) => {
                      return (
                        <div className="user-comment-history">
                          <p>
                            <strong>{item.rib_name}</strong> <br />
                            {item.rib_number}
                            <br /> {item.is_deleted === 1 ? " (deleted)" : ""}
                            {/* <strong>
                              {item.device_brand} {item.base_os}
                            </strong>{" "}
                            <br />
                            IP: <strong>{item.ip_address}</strong>
                            <br />
                            MAC Address: <strong>{item.mac_address}</strong>
                            <br />
                            System Version :{" "}
                            <strong>{item.system_version}</strong> */}
                          </p>
                          <i>
                            -{item.firstname} {item.date}
                          </i>
                        </div>
                      );
                    })}
                  </CardBody>
                </Card>
              </Col>
              {/* col 2 */}
              <Col xl={8}>
                {/* ------------row 1--------- */}
                <Row>
                  <Col xl={8}>
                    <div style={{ marginTop: "28px" }}>
                      <h4>
                        {`${this.state.member.first_name
                          ?.charAt(0)
                          .toUpperCase() +
                          this.state.member.first_name?.slice(1)
                          } ${this.state.member.last_name?.toUpperCase()}`}{" "}
                        {this.state.isMemberLoading ? '..' : this.getRating(
                          this.state.member.reviews?.totalReviews,
                          this.state.member.reviews?.positiveReviews,
                          this.state.member.reviews?.negativeReviews
                        )} ⭐
                      </h4>
                      <Link
                        className="btn btn-success"
                        color="success"
                        to={"/transactions/" + this.state.member.id}
                        style={{
                          width: "120px",
                          borderRadius: 20,
                          cursor: "pointer",
                          margin: 5,
                          marginTop: 10,
                        }}
                      >
                        Transaction
                      </Link>
                      <Button
                        color="info"
                        onClick={() => this.tog_standard()}
                        style={{
                          width: "120px",
                          borderRadius: 20,
                          cursor: "pointer",
                          margin: 5,
                          marginTop: 10,
                        }}
                      >
                        Edit Memeber
                      </Button>
                      <Button
                        color={
                          this.state.member.ban_type === 0
                            ? "danger"
                            : "warning"
                        }
                        caret
                        style={{
                          width: "120px",
                          borderRadius: 20,
                          cursor: "pointer",
                          margin: 5,
                          marginTop: 10,
                        }}
                        onClick={() => this.tog_block()}
                      >
                        {this.state.member.ban_type === 0 ? "Block" : "Unblock"}{" "}
                        <i
                          className={
                            this.state.member.ban_type === 0
                              ? "mdi mdi-block-helper"
                              : ""
                          }
                        ></i>
                      </Button>

                      <Modal
                        isOpen={this.state.modal_standard}
                        toggle={this.tog_standard}
                      >
                        <ModalHeader
                          toggle={() =>
                            this.setState({ modal_standard: false })
                          }
                        >
                          Edit user
                        </ModalHeader>
                        <ModalBody>
                          <Row className="mb-3">
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-3 col-form-label"
                            >
                              First name
                            </Label>
                            <Col md={8}>
                              <Input
                                type="text"
                                defaultValue={this.state.member.first_name}
                                onChange={(e) =>
                                  this.setState({
                                    member: {
                                      ...this.state.member,
                                      first_name: e.target.value,
                                    },
                                  })
                                }
                                id="example-text-input"
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-3 col-form-label"
                            >
                              Last name
                            </Label>
                            <Col md={8}>
                              <Input
                                type="text"
                                defaultValue={this.state.member.last_name}
                                onChange={(e) =>
                                  this.setState({
                                    member: {
                                      ...this.state.member,
                                      last_name: e.target.value,
                                    },
                                  })
                                }
                                id="example-text-input"
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-3 col-form-label"
                            >
                              Email
                            </Label>
                            <Col md={8}>
                              <Input
                                type="text"
                                defaultValue={this.state.member.user_email}
                                onChange={(e) =>
                                  this.setState({
                                    member: {
                                      ...this.state.member,
                                      user_email: e.target.value,
                                    },
                                  })
                                }
                                id="example-text-input"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-3 col-form-label"
                            >
                              Document
                            </Label>
                            <Col md={8}>
                              <Link to={'/member-cin/' + this.props.match.params.id} >Edit Document </Link>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-3 col-form-label"
                            >
                              Gender
                            </Label>
                            <Col md={8}>
                              <select
                                className="input"
                                onChange={(e) =>
                                  this.setState({
                                    member: {
                                      ...this.state.member,
                                      gender: e.target.value,
                                    },
                                  })
                                }
                              >
                                <option disabled selected>
                                  Select Gender
                                </option>
                                <option
                                  value={1}
                                  selected={this.state.member.gender === 1}
                                >
                                  Male
                                </option>
                                <option
                                  value={2}
                                  selected={this.state.member.gender === 2}
                                >
                                  Female
                                </option>
                              </select>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-3 col-form-label"
                            >
                              Mobile
                            </Label>
                            <Col md={8}>
                              <Input
                                type="text"
                                defaultValue={this.state.member.mobile_number}
                                onChange={(e) =>
                                  this.setState({
                                    member: {
                                      ...this.state.member,
                                      mobile_number: e.target.value,
                                    },
                                  })
                                }
                                id="example-text-input"
                              />
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-3 col-form-label"
                            >
                              Profile Picture
                            </Label>
                            <Col md={8}>
                              <Input
                                type="text"
                                defaultValue={this.state.member?.profile_photo}
                                onChange={(e) =>
                                  this.setState({
                                    member: {
                                      ...this.state.member,
                                      profile_photo: e.target.value,
                                    },
                                  })
                                }
                                id="example-text-input"
                              />
                            </Col>
                          </Row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Comment *
                          </Label>
                          <textarea
                            type="text"
                            value={this.state.comment}
                            onChange={(e) =>
                              this.setState({ comment: e.target.value })
                            }
                            style={{
                              height: "200px",
                              width: "100%",
                              borderRadius: "9px",
                              padding: "10px",
                              border: "1px solid #d9d6d6",
                            }}
                            rows={10}
                            id="example-text-input"
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_standard: false })
                            }
                            color="light"
                            className="waves-effect"
                          >
                            Close
                          </Button>
                          <Button
                            type="button"
                            onClick={this.update_user}
                            color="primary"
                            className="waves-effect waves-light"
                          >
                            Save changes
                          </Button>
                        </ModalFooter>
                      </Modal>

                      <Modal
                        isOpen={this.state.modal_block}
                        toggle={this.tog_block}
                      >
                        <ModalHeader
                          toggle={() => this.setState({ modal_block: false })}
                        >
                          Block User
                        </ModalHeader>
                        <ModalBody>
                          Chose the duration of the block
                          <Select
                            value={this.state.block_status ?? ""}
                            onChange={(e) => this.setState({ block_status: e })}
                            name="type"
                            options={block_periods}
                            color="danger"
                            classNamePrefix="select2-selection "
                          />
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Comment:
                          </Label>
                          <textarea
                            type="text"
                            value={this.state.comment}
                            onChange={(e) =>
                              this.setState({ comment: e.target.value })
                            }
                            style={{
                              height: "200px",
                              width: "100%",
                              borderRadius: "9px",
                              padding: "10px",
                              border: "1px solid #d9d6d6",
                            }}
                            rows={10}
                            id="example-text-input"
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_block: false })
                            }
                            color="light"
                            className="waves-effect"
                          >
                            Close
                          </Button>
                          <Button
                            type="button"
                            onClick={this.handleBlockUser}
                            color="primary"
                            className="waves-effect waves-light"
                          >
                            Block
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <h2>
                        <i
                          className="fas fa-address-card"
                          style={{ color: "grey" }}
                        ></i>
                      </h2>
                      <h2>
                        <i
                          className={
                            this.state.member.is_approved === 1
                              ? "fas fa-check-circle"
                              : "fas fa-times-circle"
                          }
                          style={
                            this.state.member.is_approved === 1
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        ></i>
                      </h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <h2>
                        <i
                          className="fas fa-envelope"
                          style={{ color: "grey" }}
                        ></i>
                      </h2>
                      <h2>
                        <i
                          className={
                            this.state.member.is_email_approved === 1
                              ? "fas fa-check-circle"
                              : "fas fa-times-circle"
                          }
                          style={
                            this.state.member.is_email_approved === 1
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        ></i>
                      </h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <h2>
                        <i
                          className="fas fa-mobile"
                          style={{ color: "grey" }}
                        ></i>
                      </h2>
                      <h2>
                        <i
                          className="fas fa-check-circle "
                          style={{ color: "green" }}
                        ></i>
                      </h2>
                    </div>
                  </Col>
                </Row>
                {/* -----row 2 ------------ */}
                <Row>
                  <Col lg={12}>
                    <Card className="custom-card">
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <h4 className="card-title">
                            Published offers details
                          </h4>
                          <Button
                            color="success mt-0"
                            onClick={() =>
                              window.open(`/all-rides/${this.props.match.params.id}`, '_blank')
                            }
                            style={{
                              width: "120px",
                              borderRadius: 20,
                              cursor: "pointer",
                              margin: 5,
                            }}
                          >
                            View All
                          </Button>
                        </div>

                        <div className="table-responsive">
                          <Table className="mb-0">
                            <thead>
                              <tr>
                                <th className="d-none">#</th>
                                <th>Ride</th>
                                <th>Date</th>
                                <th>Seats</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>{member_rides_results}</tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {/* ---------------row 3---------- */}
                <Row>
                  <Col lg={12}>
                    <Card className="custom-card">
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <h4 className="card-title">Requests details</h4>
                          <Button
                            color="success mt-0"
                            onClick={() =>
                              // window.open(`/all-rides/${this.props.match.params.id}`, '_blank')
                              window.open(`/reservations/${this.props.match.params.id}`)
                            }
                            style={{
                              width: "120px",
                              borderRadius: 20,
                              cursor: "pointer",
                              margin: 5,
                            }}
                          >
                            View All
                          </Button>
                        </div>

                        <div className="table-responsive">
                          <Table className="mb-0">
                            <thead>
                              <tr>
                                <th>Ride</th>
                                <th>Date</th>
                                <th>Seats</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>{member_reservations_results}</tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {/* ------row 4------- */}
                <Row>
                  <Col lg={12}>
                    <Card className="custom-card">
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <h4 className="card-title">Vehicle</h4>
                        </div>

                        <Vehicles user_id={this.props.match.params.id} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {/* --------------row 5----------- */}
                {/* <Row>
                  <Col lg={12}>
                    <Card className="custom-card">
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <h4 className="card-title">Alerts</h4>
                        </div>

                        <div className="table-responsive">
                          <Table className="mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Departure City</th>
                                <th>Arrival City</th>
                                <th>Gender</th>
                                <th>Departure Date</th>
                                <th>Departure Time</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                          
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> */}
                {/* ---row 6-- */}
                {/* <Row>
                  <Col lg={12}>
                    <Card className="custom-card">
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <h4 className="card-title">Old Profile Data</h4>
                        </div>

                        <div className="table-responsive">
                          <Table className="mb-0">
                            <thead>
                              <tr>
                                <th>Mobile Number</th>
                                <th>Email</th>
                                <th>Created Date</th>
                              </tr>
                            </thead>
                        
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> */}

                {/* ------------row 7 comments */}
                <Row>
                  <Col lg={12}>
                    <Card className="custom-card">
                      <CardBody>
                        <h4 className="card-title">Comment</h4>
                        {/* <FormGroup row className="mb-0">
                          <Label className="col-md-2 col-form-label">
                            Type:
                          </Label>
                          <Col md={4}>
                            <select className="form-select">
                              <option defaultValue>Mobile</option>
                              <option value="1">Email</option>
                              <option value="2">Crisp:Web</option>
                              <option value="3">Crisp:facebook</option>
                              <option value="4">Instagram</option>
                              <option value="4">Linkdin</option>
                            </select>
                          </Col>
                        </FormGroup> */}
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Comment:
                          </Label>
                        </FormGroup>
                        <Row className="mb-3">
                          <Col md={12}>
                            <textarea
                              type="text"
                              value={this.state.comment}
                              onChange={(e) =>
                                this.setState({ comment: e.target.value })
                              }
                              style={{
                                height: "200px",
                                width: "100%",
                                borderRadius: "9px",
                                padding: "10px",
                                border: "1px solid #d9d6d6",
                              }}
                              rows={10}
                              id="example-text-input"
                            />
                          </Col>
                        </Row>
                        <Button
                          color="success mt-0"
                          disabled={this.state.isBtnLoading}
                          onClick={this.save_comment}
                          style={{
                            width: "120px",
                            borderRadius: 20,
                            cursor: "pointer",
                            margin: 5,
                          }}
                        >
                          Save
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {/* --------row 8  */}

                {/* ----------row 9-------------------------- */}
                {/* <Row>
                  <Col lg={12}>
                    <Card className="custom-card">
                      <CardBody>
                        <h4 className="card-title">Comment</h4>

                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-8 col-form-label"
                          >
                            Send private push :
                          </Label>
                        </FormGroup>
                        <Row className="mb-3">
                          <Col md={12}>
                            <Input
                              type="text"
                              style={{ height: "200px" }}
                              id="example-text-input"
                            />
                          </Col>
                        </Row>
                        <div className="form-check mb-3">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="defaultCheck1"
                          >
                            Open popup
                          </Label>
                        </div>
                        <Button
                          color="success mt-0"
                          onClick={() =>
                            this.props.history.push("/member-detail")
                          }
                          style={{
                            width: "120px",
                            borderRadius: 20,
                            cursor: "pointer",
                            margin: 5,
                          }}
                        >
                          Send
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> */}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default MemberDetail;
