import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdHistory } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import Loader from "../../components/Loader/Loader";
import { BASE_URL } from "../../constants/api.constants";
import { get, put } from "../../helpers/api_helper";
import useDate from "../../helpers/date";
import { queryClient } from "../../index";

const PartnerCodePromo = () => {
  const partner = useParams().partner;

  const { dayjs } = useDate();

  const [page, setPage] = useState(0);
  const [showHistory, setShowHistory] = useState(undefined);
  const [perPage, setPerPage] = useState(10);
  const [dataPage, setData] = useState([]);
  const { data, isLoading } = useQuery({
    queryKey: ["codePromo", partner],

    queryFn: () => get(`${BASE_URL}/code-promo/codes/${partner}`),
  });

  const { mutate, isLoading: isUpdating } = useMutation(
    ({ id, data }) => put(`${BASE_URL}code-promo/update/${id}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["codePromo", partner]);
      },
    }
  );

  useEffect(() => {
    if (data) {
      const start = perPage * page;
      const end = start + perPage;
      setData(data.slice(start, end));
    }
  }, [data, page, perPage]);

  const columns = [
    // {
    //     name: 'ID',
    //     selector: row => row.id,
    // },
    // {
    //   name: "Validation",
    //   grow: 0.2,
    //   selector: (row) =>
    //     row.is_valid ? (
    //       <FaCheckCircle style={{ color: "green", fontSize: "1.3rem" }} />
    //     ) : (
    //       <IoCloseCircle style={{ color: "red", fontSize: "1.5rem" }} />
    //     ),
    // },
    {
      name: "Code",
      selector: (row) => (
        <span className="text-primary font-bold">{row.promo_code}</span>
      ),
      grow: 0.5,
    },

    {
      name: "Value",
      selector: (row) =>
        row.promo_value ? (row.promo_value * 100).toString() + "%" : "--",
      grow: 0.5,
    },
    {
      name: "Usage count",
      selector: (row) => row.usage_count,
      grow: 0.4,
    },
    {
      name: "Amount saved",
      selector: (row) => (row.total_amount_deducted ?? 0) + " MAD",
      grow: 0.6,
    },
    {
      name: "Last usage",
      selector: (row) =>
        row.last_used
          ? `${dayjs(row.last_used).fromNow()} (${dayjs(row.last_used).format(
              "LLL"
            )})`
          : "--",
      // grow: 0.6,
    },
    {
      name: "",
      selector: (row) => (
        <div className="flex gap-2 items-center">
          {!row.is_active ? (
            <button
              //  disabled={isUpdating}
              onClick={() => mutate({ id: row.id, data: { isValid: 1 } })}
              className="btn btn-secondary btn-sm"
            >
              Activate
            </button>
          ) : (
            <button
              // disabled={isUpdating}
              onClick={() => mutate({ id: row.id, data: { isValid: 0 } })}
              className="btn btn-danger btn-sm"
            >
              Desactivate
            </button>
          )}
          <button
            disabled={row.usage_count === 0}
            onClick={() => setShowHistory(row.promo_code)}
            className="btn btn-primary btn-sm flex items-center gap-2"
          >
            <MdHistory className="text-xl" />
            History
          </button>
        </div>
      ),
      grow: 0.9,
    },
  ];
  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <div
      style={{
        marginTop: "90px",
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <Modal
        size="lg"
        isOpen={!!showHistory}
        toggle={() => setShowHistory(undefined)}
      >
        <ModalHeader toggle={() => setShowHistory(undefined)}>
          Modal title
        </ModalHeader>
        <ModalBody>
          <ModalUserPromo promoCode={showHistory} />
        </ModalBody>
      </Modal>
      <Card className="custom-card">
        <CardBody>
          <DataTable
            pagination
            paginationServer
            columns={columns}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            progressPending={isLoading}
            progressComponent={<Loader />}
            paginationTotalRows={data?.length ?? 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            data={dataPage ?? []}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default PartnerCodePromo;

const ModalUserPromo = ({ promoCode }) => {
  const { dayjs } = useDate();
  const { data, isLoading } = useQuery({
    queryKey: ["codePromo", "users", promoCode],
    queryFn: () => get(`${BASE_URL}/code-promo/users/${promoCode}`),
    enabled: !!promoCode,
  });

  return (
    <DataTable
      noHeader
      columns={[
        {
          name: "User",
          selector: (row) => (
            <a href={`/member-detail/${row.id}`}>
              {row.first_name + " " + row.last_name}
            </a>
          ),
        },

        {
          name: "Amount saved",
          selector: (row) => row.total_amount_deducted + " MAD",
          grow: 0.6,
        },
        {
          name: "Last usage",
          selector: (row) =>
            `${dayjs(row.last_used).fromNow()} (${dayjs(row.last_used).format(
              "LLL"
            )})`,
        },
      ]}
      progressPending={isLoading}
      data={data ?? []}
    />
  );
};
