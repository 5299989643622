import axios from "axios";
import Cookies from "js-cookie";
//pass new generated access token here
const token = Cookies.get("token")

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
  // headers: {
  //   // "Content-Type": "application/json",
  //   // "Accept": "application/json",
  //   "x-access-token": token
  // },
})

// axiosApi.defaults.headers.common["Authorization"] = token
// axiosApi.defaults.headers.common["x-access-token"] = token

axiosApi.interceptors.request.use((config) => {
  //set token
  const token = localStorage.getItem("token");
  if (token) {
    console.log("token", token)
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["x-access-token"] = token;
  }
  return config;
});


axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const status = error.response.status;
      const url = error.config.url;

      // List of endpoints to exclude from global error handling
      if (url.includes('notifications')) {
        return Promise.reject(error);
      }



      // Check if the URL is in the list of excluded endpoints

      if (status === 401) {
        console.log("401 error");
        // Logout and redirect to login
        // Cookies.remove("token");
        localStorage.removeItem("token");
        window.location.href = "/login";
      } else if (status === 403) {
        console.log("403 error");
        // Logout and redirect to login
        /// Cookies.remove("token");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }

    }

    return Promise.reject(error);
  }
);



export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}
export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
