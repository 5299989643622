import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Loader from "../../components/Loader/Loader";
import {
  CHATS
} from "../../constants/api.constants";
import { forbiddenWords } from "../../data/forbidden.words";
import { get, post } from "../../helpers/api_helper";
import { censorWords } from "../../helpers/utils";
//import { useDebounce } from "@uidotdev/usehooks";
// import TransactionsV4 from "./transactionsv4";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Clear the timeout if the value changes before the delay period elapses.
    return () => clearTimeout(debounceTimeout);
  }, [value, delay]);

  return debouncedValue;
}

const Frauds = () => {
  const [chats, setChats] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(9);
  const [totalRows, setTotalRows] = useState(0);
  // const [isLoading, setIsLoading] = useState(true);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [showSweetAlertRAS, setShowSweetAlertRAS] = useState(false);
  const [chat, setChat] = useState([]);
  // const [wordsDictionary, setWordsDictionary] = useState([]);
  const [word, setWord] = useState([])
  const [wordExample, setWordExample] = useState([])
  const [buttonLoading, setButtonLoading] = useState(false);
  const [wordExist, setWordExist] = useState(undefined);
  const [modalWords, setModalWords] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const debouncedWord = useDebounce(word, 300);
  toastr.options = {
    positionClass: "toast-bottom-left",
    closeButton: true,
    progressBar: true,
  };
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['fraud', page, perPage],
    queryFn: () => get(
      `${CHATS}detected-fraud?per_page=${perPage}&page=${page}`)
  })

  const handle_save_fraud = async () => {
    setShowSweetAlert(false);
    const config = {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
    const data = {
      user_id: chat.user_id,
      detected_from: "chat",
      ride_id: chat.ride_id,
      chat_id: chat.chat_id,
    };
    try {
      await post(`${CHATS}save-fraud`, data, config);
      refetch()
    } catch (error) {
      alert('Something went wrong')
    }
  };
  const handle_ignore_fraud = async () => {
    setShowSweetAlertRAS(false);
    const config = {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
    const data = {
      user_id: chat.user_id,
      detected_from: "chat",
      ride_id: chat.ride_id,
      chat_id: chat.chat_id,
    };
    try {
      await post(`${CHATS}ignore-fraud`, data, config);
      refetch()
    } catch (error) {
      alert('Something went wrong')
    }
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // useEffect(() => {
  //   fetchChats();
  // }, [page, perPage]);
  // useEffect(() => {
  //   fetchWordsDictionary();

  //   setTimeout(() => {
  //     setPerPage(10);
  //   }, 5000)
  // }, []);
  // useEffect(() => {
  //   console.log('chat changed : ', chats)
  // }, [chats])

  // const fetchChats = async (show_loader = true) => {
  //   setIsLoading(show_loader);
  //   try {
  //     const config = {
  //       headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  //     };
  //     const response = await get(
  //       `${CHATS}detected-fraud?per_page=${perPage}&page=${page}`,
  //       config
  //     );

  //     const validChats = response._pagination.all_chats.filter(chat => chat && chat.user_id);

  //     let temp = [];
  //     for (let index = 0; index < response.chats.length; index++) {
  //       const chat = response.chats[index];
  //       if (chat && chat.user_id) {
  //         temp.push(get_html(chat));
  //       } else {
  //         // Handle the case where user_id is undefined
  //         console.error(`Invalid user_id at index ${index}`);
  //       }
  //     }

  //     console.log('response length : ', response.chats.length)
  //     console.log('temp length : ', temp.length)

  //     setTotalRows(validChats.length);
  //     setChats(temp);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };



  const { data: wordsDictionary, isLoading: isLoadindWordsDictionary } = useQuery({
    queryKey: ['words'],
    queryFn: () => get(`${CHATS}words-dictionary`).then((r) => r.words),
    initialData: []
  })
  // const fetchWordsDictionary = async (show_loader = true) => {
  //   try {
  //     const response = await get(`${CHATS}words-dictionary`);
  //     setWordsDictionary(response.words);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const saveWord = async () => {
    if (!word) {
      alert('Please enter the word you want to add!');
      return;
    }
    try {
      await post(`${CHATS}save-words-dictionary`, { word });
      setModalWords(false);
    } catch (error) {
      console.log(error);
    }
  };

  const checkWord = async (word) => {
    setIsChecking(true);
    try {
      const res = await post(`${CHATS}check-words-dictionary`, { word });
      setWordExist(res.exist);
    } catch (error) {
      setWordExist(true);
    }
    setIsChecking(false);
  }
  useEffect(() => {
    if (debouncedWord && debouncedWord.length > 0) {
      checkWord(debouncedWord);
    } else {
      setWordExist(undefined);
    }
  }, [debouncedWord]);

  const get_html = (chat) => {
    return {
      ...chat,
      fullname: (
        <Link to={"/member-detail/" + chat.user_id}>
          {" "}
          {chat.from_fname + " " + chat.from_lname}{" "}
        </Link>
      ),
      message: (
        <div className="custom-div-container">
          {chat.ride_id ? (
            <>
              <Link to={"/ride-detail/" + chat.ride_id}>
                {/* <HighlightForbiddenWords text={chat.msg} /> */}
                <p className="table-p">{censorWordsCustom(chat.msg, wordsDictionary)}</p>
              </Link>
              Original:
              <Link to={"/ride-detail/" + chat.ride_id}>
                {/* <HighlightForbiddenWords text={chat.msg} /> */}
                <p className="table-p">{(chat.msg)}</p>
              </Link>
            </>
          ) : (
            <HighlightForbiddenWords text={chat.msg} />
            // <p className="table-p">{HighlightWords(chat.msg)}</p>
          )}
        </div>
      ),
      date: <p>{chat.date}</p>,
      action:
        chat.is_fraud === 0 ? (
          <div>
            <button
              type="button"
              class="waves-effect waves-light me-1 btn btn-danger btn-sm"
              onClick={() => {
                setChat(chat);
                setShowSweetAlert(true);
              }}
            >
              <i class="ri-check-line align-middle me-2"></i> Confirm
            </button>
            <button
              type="button"
              class="waves-effect waves-light me-1 btn btn-secondary btn-sm"
              onClick={() => {
                setChat(chat);
                setShowSweetAlertRAS(true);
              }}
            >
              <i class="align-middle me-2"></i> RAS
            </button>
          </div>
        ) : chat.is_fraud === -1 ? (
          "RAS"
        ) : (
          "Fraud Confirmed"
        ),
    };
  };

  const columns = [
    {
      name: "Fullname",
      selector: (row) => row.fullname,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      grow: 3,
    },

    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Button
            color="warning"
            size="sm"
            style={{ marginBottom: "20px" }}
            onClick={() => {
              setModalWords(true);
            }}
          >
            Manage Dictionary
          </Button>

          <Modal
            size="xl"
            isOpen={modalWords}
            scrollable={true}
            toggle={() => {
              setModalWords(false);
            }}
          >
            <ModalHeader toggle={() => setModalWords(false)}>
              Words Dictionary
            </ModalHeader>
            <ModalBody>
              <form style={{ width: "50%", margin: "20px" }}>
                <div class="form-group">

                  <FormGroup className="position-relative">
                    <Label for="examplePassword">
                      Word*
                    </Label>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}>
                      <Input
                        invalid={wordExist === true}
                        valid={wordExist === false}
                        placeholder="Enter word"
                        type="text"
                        onChange={(wrd) => {
                          setWord(wrd.target.value)
                        }}
                        value={word} />
                      {isChecking && <Spinner color="secondary">
                        Loading...
                      </Spinner>}
                    </div>
                    {wordExist === true && <FormText color="red" style={{ color: "red" }}>
                      Word exist in dictionary
                    </FormText>}
                  </FormGroup>
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputEmail1">Example</label>
                  <input
                    type="text"
                    class="form-control"
                    value={wordExample}
                    onChange={(wrd) => {
                      setWordExample(wrd.target.value)
                    }}
                    aria-describedby="emailHelp"
                    placeholder="Enter text example to preview"
                  />
                  <small id="emailHelp" class="form-text text-muted">
                    {censorWords(wordExample, [...wordsDictionary, { word }])}
                  </small>
                </div>
                <button disabled={!(wordExist === false)} type="button" class="btn btn-success mt-2" onClick={saveWord}>
                  Add word
                </button>
              </form>
              <div>
                <DataTable
                  pagination
                  columns={[
                    {
                      name: "Word",
                      selector: (row) => row.word,
                    },
                    {
                      name: "Created date",
                      selector: (row) => row.created_at,
                    },

                    {
                      name: "Action",
                      selector: (row) => row.action,
                    },
                  ]}
                  paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                  progressComponent={<Loader />}
                  paginationTotalRows={5}
                  // onChangePage={handlePageChange}
                  // onChangeRowsPerPage={handlePerRowsChange}
                  data={wordsDictionary}
                />
              </div>

            </ModalBody>
          </Modal>
          <Row>
            <Col className="col-12">
              <Card>
                {showSweetAlert ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => handle_save_fraud()}
                    onCancel={() => setShowSweetAlert(false)}
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : (
                  ""
                )}
                {showSweetAlertRAS ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => handle_ignore_fraud()}
                    onCancel={() => setShowSweetAlertRAS(false)}
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : (
                  ""
                )}

                <CardBody>
                  {data?._pagination.total}
                  <CardTitle className="h4" style={{ textAlign: "center" }}>
                    <DataTable
                      pagination
                      paginationServer
                      columns={columns}
                      paginationRowsPerPageOptions={[10, 20, 50, 100]}
                      progressPending={isLoading}
                      progressComponent={<Loader />}
                      paginationTotalRows={data?._pagination.total_items ?? 0}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                      data={data?.chats?.map(get_html)}
                    />
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Frauds;

const HighlightForbiddenWords = ({ text }) => {
  let words = text.split(" ");

  const isPhoneNumber = (word) => /^\d{10}$/.test(word);

  words = words.map((word) => {
    if (forbiddenWords.includes(word) || isPhoneNumber(word)) {
      return <mark key={word}>{word}</mark>;
    } else {
      return word;
    }
  });

  return (
    <p>
      {words.map((word, index) => (
        <React.Fragment key={index}>{word} </React.Fragment>
      ))}
    </p>
  );
};


const censorWordsCustom = (text, forbiddenWords) => {
  let newTxt = text.toLowerCase();
  forbiddenWords.forEach(function (item) {
    if (newTxt.includes(item.word)) {
      newTxt = newTxt.replace(
        item.word,
        `*`
      );
    }
  });
  return newTxt;
};

