import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Input, InputGroup, Row } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { GET_CHART_PASSENGERS_RESERVATIONS } from '../../constants/api.constants';
import { get } from '../../helpers/api_helper';

class PassengersAnalytics extends Component {
    state = {
        totalOngoingReservations: 0,
        totalPassengers: 0,
        totalCancelledPassengers: 0,

        showTotalOngoingReservations: true,
        showTotalPassengers: true,
        showTotalCancelledPassengers: true,

        dateRange: [],

        tempSeries: [],
        tempColors: [],

        series: [],
        options: {
            chart: {
                toolbar: {
                    show: false,
                }
            },
            stroke: {
                width: [2, 2, 2],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    vertical:false,
                    columnWidth: '20%',
                },
            },
            dataLabels: {
                enabled: false,
            },

            legend: {
                show: false,
            },
            colors: [],
            labels: [],
        }
    }

    async componentDidMount() {
        const endDate = new Date(); // Current date
        const startDate = new Date(endDate); // Start date is the current date
        startDate.setFullYear(startDate.getFullYear() - 1, startDate.getMonth() + 1);
        const tempDate = [startDate, endDate];
        this.setState({
            dateRange: tempDate
        });
    
        setTimeout(() => {
            this.handleOnSearch();
        }, 1000); 
    }    

    componentDidUpdate (prevProps, prevState) {
        if(
            prevState.showTotalPassengers !== this.state.showTotalPassengers ||
            prevState.showTotalOngoingReservations !== this.state.showTotalOngoingReservations ||
            prevState.showTotalCancelledPassengers !== this.state.showTotalCancelledPassengers
        ){
            this.updateSeriesState()
        }
    }

    updateSeriesState = () => {
        const { tempSeries, tempColors, showTotalPassengers, showTotalOngoingReservations, showTotalCancelledPassengers } = this.state;
        const updatedOptions = { ...this.state.options };

        const updatedSeries = [];
        const updatedColors = [];

        if(showTotalOngoingReservations){
            updatedSeries.push(tempSeries[0])
            updatedColors.push(tempColors[0])
        }
        if(showTotalPassengers){
            updatedSeries.push(tempSeries[1])
            updatedColors.push(tempColors[1])
        }
        if(showTotalCancelledPassengers){
            updatedSeries.push(tempSeries[2])
            updatedColors.push(tempColors[2])
        }

        updatedOptions.colors = updatedColors;

        this.setState({
            series: updatedSeries,
            options: updatedOptions
        })
    }

    fetchAllPassengers = async () => {
        try {
            const { dateRange } = this.state;
            let url = `${GET_CHART_PASSENGERS_RESERVATIONS}?`;
            
            const updatedSeries = [];
            const updatedColors = [];
            const updatedOptions = { ...this.state.options }
    
            if (dateRange !== null && dateRange.length === 2) {   
                url += `startDate=${dateRange[0]}&endDate=${dateRange[1]}`
            }
            const response = await get(url)
            if(response){
                console.log('passengers response : ', response);

                updatedSeries.push({
                    name: 'Ongoing Reservations',
                    type: 'line',
                    data: response.series.map(item => item.totalOngoingReservations)
                })
                updatedColors.push("#5356FF")

                updatedSeries.push({
                    name: 'Traveled Passengers',
                    type: 'line',
                    data: response.series.map(item => item.totalPassengers)
                })
                updatedColors.push("#fcdc2a")

                updatedSeries.push({
                    name: 'Canceled Passengers',
                    type: 'line',
                    data: response.series.map(item => item.totalCanceledReservation)
                })
                updatedColors.push("#ff0000")

                updatedOptions.labels = response.series.map(item => item.label)
                this.setState({
                    series: updatedSeries,
                    tempSeries: updatedSeries,
                    tempColors: updatedColors,
                    options: updatedOptions,
                    totalPassengers: response.totalAllPassengers,
                    totalOngoingReservations: response.totalOngoingReservations,
                    totalCancelledPassengers: response.totalCanceledReservationsByPassenger
                })
            } else {
                console.error('error while fetching passengers response : ');
            }
        } catch (error) {
            console.error("Error While Fetching Passengers Data : ", error)
        }
    }

    handleOnSearch = async () => {
        await this.fetchAllPassengers();
        this.updateSeriesState();
    }

    render() {
        const {totalPassengers, totalOngoingReservations, totalCancelledPassengers, showTotalOngoingReservations, showTotalPassengers, showTotalCancelledPassengers} = this.state;
        let {dateRange} = this.state

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                    <Row className="float-end d-none d-md-flex align-items-center">
                        <Col>
                            <InputGroup style={{ marginRight: "10px" }}>
                                <Flatpickr
                                    className="form-control d-block"
                                    style={{ width: 210 }}
                                    placeholder="dd M,yyyy"
                                    value={dateRange}
                                    onChange={(e) => {
                                        this.setState({
                                            dateRange: e
                                        })
                                    }}
                                    options={{
                                        mode: "range",
                                        dateFormat: "Y-m-d"
                                    }}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <Button color="success" type="button" onClick={this.fetchAllPassengers} className="waves-effect waves-light me-1">
                                <i className="ri-search-line align-middle me-2"></i> Search
                            </Button>
                        </Col>
                    </Row>
                        <h4 className="card-title mb-4">Passengers</h4>
                        <div>
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="280" />
                            </div>
                        </div>
                    </CardBody>

                    <CardBody className="border-top text-center">
                        <Row className='mb-4 d-flex justify-content-around align-items-center'>
                                    
                                <Col sm={3}>
                                    <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                        <Input type="checkbox" className="me-2" checked={showTotalOngoingReservations} onChange={(val) => this.setState({ showTotalOngoingReservations: val.target.checked })}/>
                                        <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle font-size-10 me-1" style={{ color: '#5356FF' }}></i>Ongoing Reservations</p>
                                    </div>
                                    {/* <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Total members:</p> */}
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0 me-2">{totalOngoingReservations}</h5>
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                        <Input type="checkbox" className="me-2" checked={showTotalPassengers} onChange={(val) => this.setState({ showTotalPassengers: val.target.checked })}/>
                                        <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle font-size-10 me-1" style={{ color: '#fcdc2a' }}></i>Traverled Passengers</p>
                                    </div>
                                    {/* <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Total members:</p> */}
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0 me-2">{totalPassengers}</h5>
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                        <Input type="checkbox" className="me-2" checked={showTotalCancelledPassengers} onChange={(val) => this.setState({ showTotalCancelledPassengers: val.target.checked })}/>
                                        <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle font-size-10 me-1" style={{ color: '#ff0000' }}></i>Cancelled Passengers</p>
                                    </div>
                                    {/* <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Total members:</p> */}
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0 me-2">{totalCancelledPassengers}</h5>
                                    </div>
                                </Col>
                     
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default PassengersAnalytics;