var BASE_URL = "https://bo-api.pippipyala.com/api/";
// var BASE_URL = "http://127.0.0.1:3035/api/";

exports.BASE_URL = BASE_URL;
exports.POST_LOGIN = BASE_URL + "admin/login"
exports.GET_TRANSACTIONS = BASE_URL + "transactions"
exports.POST_TRANSACTION = BASE_URL + "transactions"
exports.TRANSACTIONS = BASE_URL + "transactions/"
exports.POST_WITHDRAWS = BASE_URL + "withdraws"
exports.GET_WITHDRAWS = BASE_URL + "withdraws"
exports.GET_OPPOSITE_RIDES = BASE_URL + "rides/opposites"
exports.GET_RIDES = BASE_URL + "rides/"
exports.GET_DOCUMENTS = BASE_URL + "members/documents/"
exports.PUT_DOCUMENT = BASE_URL + "members/documents/"
exports.GET_MEMBERS = BASE_URL + "members/"
exports.GET_CHART_MEMBERS = BASE_URL + "members/chart"
exports.GET_CHART_NEW_MEMBERS = BASE_URL + "members/chart/newMembers"
exports.RESERVATIONS = BASE_URL + "reservations/"
exports.GET_RESERVATIONS = BASE_URL + "reservations/"
exports.GET_CANCELLED_RESERVATIONS = BASE_URL + "reservations/cancelled"
exports.GET_CHART_PASSENGERS_RESERVATIONS = BASE_URL + "reservations/chart"
exports.PUT_RESERVATION = BASE_URL + "reservations/"
exports.GET_RIDES_FOLLOW_UP = BASE_URL + "rides/handles/"
exports.PUT_RIDES_FOLLOW_UP = BASE_URL + "rides/handles/"
exports.RIDES = BASE_URL + "rides/"
exports.GET_ALL_CHART_RIDES = BASE_URL + "rides/chart"
exports.POST_FULL_POWER = BASE_URL + "full-power/action"
exports.CHATS = BASE_URL + "chats/"
exports.REVIEWS = BASE_URL + "reviews/"
exports.VEHICLES = BASE_URL + "vehicles/"
exports.CITIES = BASE_URL + "cities/"
exports.V4 = BASE_URL + "v4/"
exports.GET_RESERVATIONS_NOTIFICATIONS = BASE_URL + "notifications/reservations"
exports.GET_RIDES_NOTIFICATIONS = BASE_URL + "notifications/rides"
exports.GET_OPPOSITES_RIDES_NOTIFICATIONS = BASE_URL + "notifications/opposites"
exports.GET_FRAUDE_NOTIFICATIONS = BASE_URL + "notifications/fraud"
exports.GET_SIGNALEMENTS_NOTIFICATIONS = BASE_URL + "notifications/signalements"
exports.GET_UNCOMPLETED_PAYMENTS_NOTIFICATIONS = BASE_URL + "notifications/uncompletedPayments"
exports.STATS = {
  PAYMENT: BASE_URL + "transactions/payment-stats",
};

exports.ASSETS = "https://pippipyalahcom.s3.eu-west-3.amazonaws.com/";
