import React from "react";
import { Redirect, Route } from "react-router-dom";

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	...rest
}) => (
	<Route
		{...rest}

		render={props => {
			const token = localStorage.getItem("token");
			if (isAuthProtected && !token) {

				return (
					<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
				);
			}

			// const role = localStorage.getItem("role");
			// if (role == 2 && !props.path.includes("member-cin")) {
			// 	return <Redirect to={"/member-cin"} />
			// }

			return (
				<Layout >
					<Component {...props} />
				</Layout>
			);
		}}
	/>
);

export default AppRoute;

