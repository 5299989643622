import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle } from 'reactstrap'
import Loader from '../../components/Loader/Loader';
import { V4 } from '../../constants/api.constants';
import { get } from '../../helpers/api_helper';

const columns = [
    // {
    //   name: "Title",
    //   selector: (row) => row.title,
    // },
    {
      name: "Description",
      selector: (row) => row.title,
      grow: 3,
    },
    {
      name: "Payment ID",
      selector: (row) => row.payment_id,
      grow: 2,
    },

    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    // {
    //   name: "Payment ID",
    //   selector: (row) => row.payment_id,
    // },
    {
      name: "Date",
      selector: (row) => row.date,
    },
  ];

export default function TransactionsV4({user}) {
    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState([])

    const fetchTransactions = async (show_loader = true) => {
        setIsLoading(show_loader)
        let url = V4 + 'transactions?user_id=' +user ;
        const config = {
            headers: { Authorization: `Bearer ${  Cookies.get("token")}` },
          };
        try {
          const response = await get(url,config);
          let temp = [];
          for (let index = 0; index < response.transactions.length; index++) {
            const transaction = response.transactions[index];
            temp.push({
              ...transaction,
            //   fullname: (
            //     <Link to={"/member-detail/" + transaction.user_id}>
            //       {" "}
            //       {transaction.first_name + " " + transaction.last_name}{" "}
            //     </Link>
            //   ),
              title: (
                <div className="custom-div-container">
                  {transaction.params?.ride_id ? (
                    <Link to={"/ride-detail/" + transaction.params.ride_id}>
                      <p className="table-p">{transaction.msg_heading}</p>
                    </Link>
                  ) : (
                    <>
                      <p className="table-p">{transaction.msg_heading}</p>
                      <p className="table-p">{transaction.message}</p>
                    </>
                  )}
                  <p>{transaction.msg_sub_heading}</p>
                </div>
              ),
              payment_id: transaction.order_number ?? '',
              date: transaction.showing_date, 
              amount: (
                <div
                  className={
                    transaction.type === 1
                      ? "text-success"
                      : transaction.type === 2
                      ? "text-danger"
                      : "text-warning"
                  }
                >
                    {
                         transaction.type === 1
                         ?  "+" + transaction.price.toFixed(2)
                         : transaction.price.toFixed(2)
                       
                    }
                  {/* {transaction.type === 1
                    ? "+" + transaction.price.toFixed(2)
                    : transaction.price.toFixed(2)} */}
                </div>
              ),
            });
          }
        
          setTransactions(temp);
          setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
          console.log(error);
        }
      };


      useEffect(() => {
        fetchTransactions()
      }, [])
      

  return (
    <Card>
        <CardBody>
            <h4>Older Transactions</h4>
            {/* <button className='btn-fetch' onClick={fetchTransactions}>Fetch</button> */}
            <CardTitle className="h4" style={{ textAlign: "center" }}>
            <DataTable
                pagination
                columns={columns}
                progressPending={isLoading}
                progressComponent={<Loader />}
                data={transactions}
            />
            </CardTitle>
        </CardBody>
        </Card>
  )
}
