exports.forbiddenWords = [
  "0",
  "1",
  "١",
  "2",
  "٢",
  "3",
  "٣",
  "4",
  "٤",
  "5",
  "٥",
  "6",
  "٦",
  "7",
  "٧",
  "8",
  "٨",
  "9",
  "٩",
  "%",
  ".",
  "@",
  "2alf",
  "2lf",
  "3achra",
  "3achrin",
  "3chrin",
  "3echrin",
  "abandonne",
  "achra",
  "adresse",
  "alf",
  "annul",
  "annulé",
  "annuler",
  "arbaea",
  "arobase",
  "bi3",
  "billet",
  "billion",
  "blablacar",
  "cancel",
  "Cent",
  "cents",
  "ch7al",
  "chri",
  "cinq",
  "cinquante",
  "comission",
  "commission",
  "comobila",
  "deux",
  "dh",
  "dirham",
  "dirhams",
  "dix",
  "dot",
  "douze",
  "eashra",
  "eflmya",
  "eigh",
  "eighteen",
  "eighty",
  "eleven",
  "email",
  "fabor",
  "face",
  "facebook",
  "fifteen",
  "fifty",
  "five",
  "forty",
  "four",
  "fourteen",
  "free",
  "ghadijay",
  "gmail",
  "gratuit",
  "hatif",
  "hdach",
  "hdch",
  "hotmail",
  "huit",
  "hundred",
  "imil",
  "insta",
  "instagram",
  "jouj",
  "khamsa",
  "khamsoun",
  "khamssa",
  "khamssin",
  "khamstach",
  "khemssin",
  "khemstach",
  "khmsa",
  "khmssa",
  "khmssin",
  "khmstach",
  "ladrissa",
  "lien",
  "live",
  "mahmoul",
  "mail",
  "mchina",
  "meliar",
  "melioun",
  "melyar",
  "melyoun",
  "mia",
  "millard",
  "mille",
  "milles",
  "milliards",
  "million",
  "millions",
  "mliar",
  "mlioun",
  "mlyar",
  "mlyoun",
  "mobile",
  "mya",
  "n9esli",
  "n9eslia",
  "namra",
  "nemra",
  "neuf",
  "nine",
  "nineteen",
  "ninety",
  "nmertek",
  "nmertha",
  "nmerto",
  "nmira",
  "number",
  "numéro",
  "one",
  "onze",
  "outlook",
  "percentage",
  "phone",
  "point",
  "portable",
  "pourcent",
  "quarante",
  "quatorze",
  "quatre",
  "quinze",
  "r9m",
  "ra9m",
  "rab3a",
  "rab3in",
  "rb3a",
  "rb3in",
  "rb3tach",
  "rbaa",
  "rbe3tach",
  "reb3in",
  "sab3in",
  "sabea",
  "satach",
  "satin",
  "sb3a",
  "sb3in",
  "sb3tach",
  "sba3tach",
  "sbaa",
  "sbe3tach",
  "seb3a",
  "seb3in",
  "sebaa",
  "seize",
  "sept",
  "seta",
  "setach",
  "setin",
  "setta",
  "seven",
  "seventeen",
  "seventy",
  "signal",
  "sita",
  "six",
  "sixteen",
  "sixty",
  "soixante",
  "stach",
  "stin",
  "talatatoun",
  "talatin",
  "taltach",
  "taman",
  "tamanya",
  "tas3in",
  "telatin",
  "telegram",
  "telephone",
  "téléphone",
  "teltach",
  "ten",
  "tes3in",
  "thamania",
  "thirteen",
  "thirty",
  "thousand",
  "three",
  "ticket",
  "tiktok",
  "tilifoun",
  "tiss3a",
  "titiz",
  "tlata",
  "tlatin",
  "tltach",
  "tmanin",
  "tmantach",
  "tmenia",
  "tmenin",
  "tmentach",
  "tmenya",
  "tmntach",
  "tnach",
  "tnech",
  "train",
  "treize",
  "trente",
  "trois",
  "ts3in",
  "ts3od",
  "ts3oud",
  "ts3tach",
  "tsa3tach",
  "tse3tach",
  "twelve",
  "twenty",
  "two",
  "un",
  "uns",
  "vingt",
  "wahed",
  "whatapp",
  "whatsapp",
  "whatzapp",
  "wsselni maak",
  "yahoo",
  "zero",
  "zid",
  "ziro",
  "zouj",
  "إثنان",
  "اثني عشر",
  "أحد عشر",
  "أربعة",
  "أربعين",
  "البريد الإلكتروني",
  "ألف",
  "النسبة",
  "انستغرام",
  "انلي",
  "تران",
  "تسع",
  "تسعة",
  "تسعطاش",
  "تسعون",
  "تسعين",
  "تكتوك",
  "تلاتة",
  "تلاتين",
  "تليفون",
  "تمانين",
  "تمنطاش",
  "توبيس",
  "ثلاثة",
  "ثلاثة عشر",
  "ثلاثين",
  "ثمانون",
  "ثمانية",
  "جوج",
  "حضاش",
  "خمسة",
  "خمسون",
  "خمسين",
  "خمصطاش",
  "درهم",
  "رابط",
  "ربعطاش",
  "ربعين",
  "رقم",
  "زائد",
  "زيرو",
  "سبعة",
  "سبعطاش",
  "سبعون",
  "سبعين",
  "ستة",
  "ستين",
  "صطاش",
  "صفر",
  "طلطاش",
  "طناش",
  "عشرة",
  "عشرين",
  "فيسبوك",
  "لالغاء",
  "محمول",
  "مليار",
  "مليون",
  "مئة",
  "نقطة",
  "نمرة",
  "هاتف",
  "واتساب",
  "واحد",
];
