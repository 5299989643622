import {
    UPDATE_UNCOMPLETED_PAYMENT,
    HIDE_UNCOMPLETED_PAYMENT,
    UPDATE_RESERVATIONS,
    HIDE_RESERVATIONS,
    UPDATE_RIDES,
    HIDE_RIDES,
    UPDATE_FRAUD,
    HIDE_FRAUD,
    UPDATE_OPPOSITES_RIDES,
    HIDE_OPPOSITES_RIDES,
    UPDATE_SIGNALEMENTS,
    HIDE_SIGNALEMENTS,
} from './actionTypes';

const initState = {
    uncompletedPayment: 0,
    newReservations: 0,
    newRides: 0,
    newOpposites: 0,
    newFraud: 0,
    newSignalements: 0
};

const Notifications = (state = initState, action) => {
    switch (action.type) {
        case UPDATE_UNCOMPLETED_PAYMENT:
            return {
                ...state,
                [action.payload.notificationType]: action.payload.count
            };
        case HIDE_UNCOMPLETED_PAYMENT:
            return {
                ...state,
                [action.payload.notificationType]: 0
            };
        case UPDATE_RESERVATIONS:
            return {
                ...state,
                [action.payload.notificationType]: action.payload.count
            };
        case HIDE_RESERVATIONS:
            return {
                ...state,
                [action.payload.notificationType]: 0
            };
        case UPDATE_RIDES:
            return {
                ...state,
                [action.payload.notificationType]: action.payload.count
            };
        case HIDE_RIDES:
            return {
                ...state,
                [action.payload.notificationType]: 0
            };
        case UPDATE_OPPOSITES_RIDES:
            return {
                ...state,
                [action.payload.notificationType]: action.payload.count
            };
        case HIDE_OPPOSITES_RIDES:
            return {
                ...state,
                [action.payload.notificationType]: 0
            };
        case UPDATE_FRAUD:
            return {
                ...state,
                [action.payload.notificationType]: action.payload.count
            };
        case HIDE_FRAUD:
            return {
                ...state,
                [action.payload.notificationType]: 0
            };
            case UPDATE_SIGNALEMENTS:
                return {
                    ...state,
                    [action.payload.notificationType]: action.payload.count
                };
            case HIDE_SIGNALEMENTS:
                return {
                    ...state,
                    [action.payload.notificationType]: 0
                };
        default:
            return state
    }
}

export default Notifications;
