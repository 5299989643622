// calendar data

import { calenderDefaultCategories, events } from "./calender";
import {
    productsData,
    recentProducts,
    comments,
    discountData,
    orders,
    shops,
    customerData,
    cartData
} from "./ecommerce"
import {rides} from './rides'
import { chats, contacts, groups, messages } from "./chat";
import {virements} from './virements'
export {
    calenderDefaultCategories,
    events,
    productsData,
    recentProducts,
    comments,
    discountData,
    orders,
    shops,
    customerData,
    cartData,
    chats,
    groups,
    contacts,
    messages,
    rides,
    virements
};