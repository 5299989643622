import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import * as moment from "moment";

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// import { orders, rides } from "../../../common/data"
import RideColumns from "./RideColumns";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import { del, get, post, put } from "../../../helpers/api_helper";
import { GET_OPPOSITE_RIDES } from "../../../constants/api.constants";
import DataTable from "react-data-table-component";
import Loader from "../../../components/Loader/Loader";


const columns = [
  {
    name: "Driver",
    selector: (row) => row.driver,
  },
  {
    name: "Passenger",
    selector: (row) => row.passenger,
    // grow: 3,
  },

  {
    name: "Ride",
    cell: (row) => {
      return (
          <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
              <div>{row.ride}</div>
              <div style={{"fontSize": "11px", "color": "#808080", "marginTop": "3px"}}>{row.districts}</div>
          </div>
      );
    },
  },
  {
    name: "Last update",
    selector: (row) => row.date,
  },
];

const OppositeRides = () => {
  const [rides, setRides] = useState([]);
  const [dateRange, setRideRange] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({ label: 'All', value: 0 });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [totalItems, setTotalItems] = useState(0)
  
  const handlePageChange = page => {
    setPage(page - 1)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(0)
  }

  useEffect(() => {
    filter();
    console.log('page : ', page)
    console.log('perPage : ', perPage)
    console.log('oppostite rides : ', rides)
  }, [perPage, page]);



  const filter = () => {
    let _query = `?page=${page}&per_page=${perPage}`;
    // console.log(dateRange);
    // console.log(dateRange.length);


    // if (dateRange.length === 2) {
    //   let from_range = dateRange[0];
    //   let to_range = dateRange[1];
    //   let d1 = new Date(from_range);
    //   let d2 = new Date(to_range);
    //   from_range = d1.toISOString().split('T')[0];
    //   to_range = d2.toISOString().split('T')[0];
    //   _query += `?from_range=${from_range}&to_range=${to_range}`;
    // }

    // if (selectedStatus.value !== 0) {
    //   if (isEmpty(_query)) {
    //     _query = `?status=${selectedStatus.value}`
    //   } else {
    //     _query += `&status=${selectedStatus.value}`
    //   }
    // }
    fetch_rides(_query)
  }


  const fetch_rides = async (_query) => {
    try {
      const response = await get(GET_OPPOSITE_RIDES + '/' + _query);
      let temp = [];
      if(response){
        response.rides.forEach((ride) => {
          temp.push({
            // id: "1",

            // some items have the same of this id 'ride_id' soo it causes some duplicated data in console errors & on dataTable
            // id: ride.ride_id,

            id: ride.qr_id,
            
            driver: (<Link to={"/member-detail/" + ride.driver_id}>{ride.driver_fname} {ride.driver_lname}</Link>),
            passenger: (<Link to={"/member-detail/" + ride.passenger_id}>{ride.passenger_fname} {ride.passenger_lname}</Link>),
            ride: (<Link to={"/ride-detail/" + ride.ride_id}>{ride.departure_city} {'>'} {ride.arrival_city}</Link>),
            districts: (
              (ride.departure_district && ride.departure_district !== "unknown") ? 
              ride.departure_district : 
              "Other"
          ) + " > " + (
              (ride.arrival_district && ride.arrival_district !== "unknown") ? 
              ride.arrival_district : 
              "Other"
          ),
            date: `${ride.departure_date}`,
          });
        });
      }
      // test it
      setTotalRows(response._pagination.total_items);
      
      setIsLoading(false)
      setRides(temp);
    } catch (error) {
      console.log(error);
    }
  };






  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="4">
              <Card className="custom-card">
                <CardBody>
                  <CardText>Remaining Rides</CardText>
                  <CardTitle>{totalRows}</CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle className="h4" style={{ textAlign: "center" }}>
                    <DataTable
                      pagination
                      paginationServer
                      columns={columns}
                      paginationRowsPerPageOptions={[10, 20, 50, 100]}
                      progressPending={isLoading}
                      progressComponent={<Loader />}
                      paginationTotalRows={totalRows}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                      data={rides}
                    />
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default OppositeRides;
