export const virements = [
    {
        id: "1",
        status: 'Encours',
        member: 'Hanan zahir',
        requestDate: "20-02-2000",
        amount: '95',
        bankFee: '100',
        toSend: "22/05/2017",
        paymentId: "12987",
        bank: 'SGMB',
        

    },
    {
        id: "2",
        status: 'Encours',
        member: 'Hanan zahir',
        requestDate: "20-02-2000",
        amount: '95',
        bankFee: '100',
        toSend: "22/05/2017",
        paymentId: "12987",
        bank: 'SGMB',

    },
    {
        id: "3",
        status: 'Encours',
        member: 'Hanan zahir',
        requestDate: "20-02-2000",
        amount: '95',
        bankFee: '100',
        toSend: "22/05/2017",
        paymentId: "12987",
        bank: 'SGMB',

    },
    {
        id: "4",
        status: 'Encours',
        member: 'Hanan zahir',
        requestDate: "20-02-2000",
        amount: '95',
        bankFee: '100',
        toSend: "22/05/2017",
        paymentId: "12987",
        bank: 'SGMB',

    },
    {
        id: "5",
        status: 'Encours',
        member: 'Hanan zahir',
        requestDate: "20-02-2000",
        amount: '95',
        bankFee: '100',
        toSend: "22/05/2017",
        paymentId: "12987",
        bank: 'SGMB',

    },
    {
        id: "6",
        status: 'Encours',
        member: 'Hanan zahir',
        requestDate: "20-02-2000",
        amount: '95',
        bankFee: '100',
        toSend: "22/05/2017",
        paymentId: "12987",
        bank: 'SGMB',

    }
]
