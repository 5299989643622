import "flatpickr/dist/themes/material_blue.css";
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  InputGroup,
  Label,
  Row
} from "reactstrap";

//Import Breadcrumb

// import { orders, rides } from "../../../common/data"
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../../components/Loader/Loader";
import { GET_RIDES } from "../../../constants/api.constants";
import { cities } from "../../../data/selects.data";
import { get } from "../../../helpers/api_helper";
const rides = [
  {
    id: "1",
    driverName: "Hamza",
    rideType: "Credit Card",
    departureCity: "Casablanca",
    arrivalCity: "Fes",
    departureDate: "22/05/2017",
    departureTime: "09:30",
    requests: 0,
    totalSeats: 4,
    availableSeats: 2,
    status: "Encours",
    // oldprice: 0,
    // newprice: 240,
    // islable: true,
    // lable: 'Trending',
    // extraimgs :[product2,product3,product4],
    // shortspecifications: ['Full Sleeve', 'Cotton', 'All Sizes available', '4 Different Color'],
    // category: "T-shirts",
    // reviews: 132,
    // shortservices:[
    //   { icon: "mdi-sync", value: "10 Days Replacement" },
    //   { icon: "mdi-currency-usd-circle", value: "Cash on Delivery available" },
    // ],
    // colorOptions: [
    //   { image: product2, color: "Blue" },
    //   { image: product3, color: "Cyan" },
    //   { image: product4, color: "Green" },
    // ],
    // specification: [
    //   { type: "Category", value: "T-shirt" },
    //   { type: "Brand", value: "Jack & Jones" },
    //   { type: "Color", value: "Blue" },
    //   { type: "Material", value: "Cotton" },
    //   { type: "Weight", value: "140 Gm" },
    // ],
  },
];

const AllRides = () => {
  const [rides, setRides] = useState([]);
  const [dateRange, setRideRange] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({ label: 'Ongoing', value: 1 });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [fromCity, setFromCity] = useState({ label: 'All', value: 0 });
  const [toCity, setToCity] = useState({ label: 'All', value: 0 });
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  let { user_id } = useParams();

  useEffect(() => {
    // fetch_rides();
    filter();
  }, []);
  useEffect(() => {
    filter();
  }, [perPage, page]);



  const filter = () => {
    let _query = '';

    console.log('Filtering..');

    if (dateRange.length === 2) {
      let from_range = new Date(dateRange[0]);
      let to_range = new Date(dateRange[1]);

      from_range.setDate(from_range.getDate() + 1);
      to_range.setDate(to_range.getDate() + 1);

      let fromISO = from_range.toISOString().split('T')[0];
      let toISO = to_range.toISOString().split('T')[0];

      // Append date range to query string
      _query += `?from_range=${fromISO}&to_range=${toISO}`;
    } else if (dateRange.length === 1) {
      // If only one date is selected, treat it as a single-day range
      let selectedDate = new Date(dateRange[0]);

      // Add one day to the selected date
      selectedDate.setDate(selectedDate.getDate() + 1);

      // Convert date to ISO string
      let selectedISO = selectedDate.toISOString().split('T')[0];

      // Append single-day range to query string
      _query += `?from_range=${selectedISO}&to_range=${selectedISO}`;
    }

    // Append other filters to the query string
    if (selectedStatus.value !== 0) {
      _query += `${_query ? '&' : '?'}status=${selectedStatus.value}`;
    }

    _query += `${_query ? '&' : '?'}page=${page}&per_page=${perPage}`;

    if (fromCity.value !== 0 && toCity.value !== 0) {
      _query += `&from_city=${fromCity.value}&to_city=${toCity.value}`;
    }

    if (user_id) {
      _query += `&user_id=${user_id}`;
    }

    console.log(_query);
    fetch_rides(_query);
  };



  const fetch_rides = async (_query = null) => {
    console.log(user_id);
    setIsLoading(true);
    try {
      let link = GET_RIDES + (_query ?? '');
      console.log(link);
      const response = await get(GET_RIDES + (_query ?? ''));

      let totalSize = response._pagination.total_items;

      let temp = [];
      console.log("remaining", response.remaining)
      response.rides.forEach((ride) => {
        let status = {
          class: "primary",
          text: "default",
        };
        switch (ride.status) {
          case 1:
            status.class = "warning";
            status.text = "ongoing";
            break;
          case 2:
            status.class = "danger";
            status.text = "cancelled";
            break;
          case 3:
            status.class = "info";
            status.text = "completed";
            break;

          default:
            break;
        }
        console.log(ride.state);
        temp.push({
          key: ride.ride_id,
          driverName: (<Link to={"/member-detail/" + ride.driver_id}>{ride.first_name} {ride.last_name}</Link>),
          ride: (<Link to={"/ride-detail/" + ride.ride_id}>{ride.departure_city} {'>'} {ride.arrival_city}</Link>),
          districts: (
            (ride.departure_district && ride.departure_district !== "unknown") ?
              ride.departure_district :
              "Other"
          ) + " > " + (
              (ride.arrival_district && ride.arrival_district !== "unknown") ?
                ride.arrival_district :
                "Other"
            ),
          price: ride.price,
          departureDate: `${ride.departure_date}`,
          seats: ride.seats,
          pending: ride.state ? (ride.state.pending ?? 0) : 0,
          preapproved: ride.state ? (ride.state.pre_approved ?? 0) : 0,
          status: <span class={`badge bg-${status.class}`}>{status.text}</span>,
          actions: (
            <Link to={'/ride-detail/' + ride.ride_id} className="text-dark">
              <i className="fas fa-eye font-size-18"></i>
            </Link>
          ),
        });
      });
      setRides(temp);
      console.log('total size : ', totalSize);
      // console.log('total size 2 : ', totalSize2)

      setTotalRows(totalSize);

      console.log('the total rides : ', totalRows)
      console.log(temp);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const breadcrumbItems = [
    { title: "Rides", link: "#" },
    { title: "All Rides", link: "#" },
  ];






  const columns = [
    // {
    //     name: 'ID',
    //     selector: row => row.id,
    // },
    {
      name: 'Driver Name',
      selector: row => row.driverName,
    },
    {
      name: 'Ride',
      cell: (row) => {
        const maxDistrictLength = 40;

        let districtsText = row.districts;
        if (districtsText.length > maxDistrictLength) {
          districtsText = districtsText.substring(0, maxDistrictLength) + '...';
        }

        return (
          <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
            <div>{row.ride}</div>
            <div style={{ fontSize: '11px', color: '#393e46' }}>{districtsText}</div>
          </div>
        );
      },

      grow: 1.8
    },
    {
      name: 'Price',
      selector: row => row.price,
      grow: 0.5
    },
    {
      name: 'Departure Date',
      selector: row => row.departureDate,
      grow: 1.5
    },
    {
      name: 'Seats',
      selector: row => row.seats,
      grow: 0.6
    },
    {
      name: 'Pending',
      selector: row => row.pending,
      grow: 0.6
    },
    {
      name: 'Pre-approved',
      selector: row => row.preapproved,
      grow: 0.6
    },
    {
      name: 'Status',
      selector: row => row.status,
      grow: 0.6
    },

  ];


  const handlePageChange = page => {
    setPage(page - 1)

  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card className="custom-card">
                <CardBody>
                  <Row>
                    <Col xs="3">
                      <div className="col mb-3">
                        <Label>Departure</Label>
                        <Select
                          value={
                            cities.length
                              ? cities[0].options.filter(
                                (o) =>
                                  parseInt(o.value) ===
                                  parseInt(document.city)
                              )[0]
                              : {}
                          }
                          onChange={(e) => {
                            setFromCity(e);
                          }}
                          name="city"
                          options={cities}
                          classNamePrefix="select2-selection"
                        />

                      </div>
                    </Col>
                    <Col xs="3">
                      <div className="col mb-3">
                        <Label>Arrival</Label>
                        <Select
                          value={
                            cities.length
                              ? cities[0].options.filter(
                                (o) =>
                                  parseInt(o.value) ===
                                  parseInt(document.city)
                              )[0]
                              : {}
                          }
                          onChange={(e) => {
                            setToCity(e);
                          }}
                          name="city"
                          options={cities}
                          classNamePrefix="select2-selection"
                        />

                      </div>
                    </Col>
                    <Col xs="2">
                      <div className="col mb-3" xl="3">
                        <Label>Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            value={dateRange}
                            onChange={(e) => {
                              console.log('changed');
                              setRideRange(e);
                            }}
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d"
                            }}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col xs="2">
                      <div className="col mb-3" xl="3">
                        <Label className="form-label">Status</Label>
                        <Select
                          value={selectedStatus}
                          onChange={(e) => {
                            setSelectedStatus(e);
                          }}
                          options={[
                            { label: "All", value: 0 },
                            { label: "Ongoing", value: "1" },
                            { label: "Cancelled by driver", value: "5" },
                            { label: "Cancelled by system", value: "6" },
                            { label: "Completed", value: "3" }
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col xs="2">
                      <div className="col mt-4" xl="3" style={{ "marginTop": "28px !important" }}>
                        <Button color="success" type="button" onClick={filter} className="waves-effect waves-light me-1">
                          <i className="ri-search-line align-middle me-2"></i>
                          <span className="align-middle">Search</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="custom-card">
                <CardBody>
                  <DataTable
                    pagination
                    paginationServer
                    columns={columns}
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    progressPending={isLoading}
                    progressComponent={<Loader />}
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    data={rides}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllRides;
