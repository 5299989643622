import React, { Component, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Badge,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

import DataTable from "react-data-table-component";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import { del, get, post, put } from "../../helpers/api_helper";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import "./datatables.scss";
import { Link, withRouter } from "react-router-dom";
import Input from "react-bootstrap-sweetalert/dist/components/Input";
import { cities, countries, id_types } from "../../data/selects.data";
import { basicDateFormat } from "../../helpers/date.helper";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { ToastContainer } from "react-toastr";
import {
  GET_DOCUMENTS,
  GET_MEMBERS,
  PUT_DOCUMENT,
} from "../../constants/api.constants";
import Loader from "../../components/Loader/Loader";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// Table data

const MemberCIN = () => {
  var [documents, setDocuments] = useState([]);
  const [documentsUI, setDocumentsUI] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [document, setDocument] = useState([]);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [showFoundDocument, setShowFoundDocument] = useState(false);
  const [showFoundDocumentLoading, setShowFoundDocumentLoading] =
    useState(false);
  const [extraInputs, setExtraInputs] = useState([]);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [rotationAngle2, setRotationAngle2] = useState(0);
   let { user_id } = useParams();
 

  var tog_standard = (document_id = null) => {
    // console.log(document_id);
    if (document_id !== null) {
      console.log(documents);
      let cur = documents.find((doc) => doc.id === document_id);
      console.log(cur);
      setDocument(cur);
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [page, perPage]);

  const handlePageChange = (page) => {
    console.log();
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  const fetchDocuments = async () => {
    setIsLoading(true);
    var query = `?page=${page}&per_page=${perPage}`
    if(user_id){
      query += `&user_id=${user_id}`
    }
    try {
      const response = await get(
        GET_DOCUMENTS + query
      );
      documents = response.documents;

      let documents_arr = [];
      for (let i = 0; i < documents.length; i++) {
        const doc = documents[i];
        let new_doc = {
          id: i + 1,
          uploaded: doc.uploaded_date,
          fullname: (
            <Link
              to={`member-detail/${doc.user_id}`}
            >{`${doc.first_name} ${doc.last_name}`}</Link>
          ),
          name: doc.first_name,
          surName: doc.last_name,
          dob: doc.dob,
          cinStatus:
            doc.approved === 0 ? (
              <span class="badge bg-warning">Waiting</span>
            ) : doc.approved === 1 ? (
              <span class="badge bg-success">Approved</span>
            ) : (
              <span class="badge bg-danger">Disapproved</span>
            ),
          approval: (
            <Button
              type="button"
              onClick={() => {
                tog_standard(doc.id);
              }}
              color="bg-primary"
              className="waves-effect waves-light"
            >
              <i class="ri-eye-fill"></i>
            </Button>
          ),
        };
        documents_arr.push(new_doc);
      }

      setDocumentsUI(documents_arr);
      setTotalRows(response._pagination.total_rows);
      console.log(response._pagination.total_rows);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const update_member_cin = async () => {
    setIsModalLoading(true);
    try {
      const response = await put(PUT_DOCUMENT + document.id, document);
      setIsModalLoading(false);
      tog_standard(null);
      fetchDocuments();
      toastr.success(
        "Document " +
          (document.approved === "1"
            ? "Approved"
            : document.approved === "2"
            ? "Disapproved"
            : "Saved"),
        ""
      );
    } catch (error) {
      setIsModalLoading(false);
      toastr.error("Unable to update document, check your inputs!", "");
      console.log(error);
    }
  };
  const check_id = async () => {
    setShowFoundDocumentLoading(true);
    try {
      const config = {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      };
      const response = await post(
        GET_MEMBERS + "check_data",
        { document_id: document.document_number },
        config
      );
      if (response.id) {
        setShowFoundDocument(response);
      } else {
        setShowFoundDocument(false);
      }
      setShowFoundDocumentLoading(false);
    } catch (error) {
      console.log(error);
      setShowFoundDocumentLoading(false);
      setShowFoundDocument(false);
    }
  };

  const handle_on_change = (event, is_date = false) => {
    if (is_date) {
      try {
        let date = event.event[0];
        setDocument({ ...document, dob: basicDateFormat(date) });
      } catch (error) {}
      return;
    }
    if (event.target) {
      const { name, value } = event.target;
      setDocument({ ...document, [name]: value });
    } else {
      const { name } = event;
      setDocument({ ...document, [name]: event.event.value });
    }
  };

  const handleRotate = () => {
    // Increment the rotation angle by 90 degrees on each click
    setRotationAngle(angle => angle + 90);
  };

  const handleRotate2 = () => {
    // Increment the rotation angle by 90 degrees on each click
    setRotationAngle2(angle => angle + 90);
  };

  const documents_columns = [
    {
      selector: (row) => row.uploaded,
      name: "Uploaded On",
    },
    {
      selector: (row) => row.fullname,
      name: "Fullname",
    },
    {
      selector: (row) => row.dob,
      name: "Date Of Birth",
    },
    {
      selector: (row) => row.cinStatus,
      name: "CIN Status",
    },
    {
      selector: (row) => row.approval,
      name: "Approval",
    },
  ];

  toastr.options = {
    positionClass: "toast-bottom-left",
    closeButton: true,
    progressBar: true,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Modal size="xl" isOpen={showModal}>
                    <ModalHeader toggle={() => setShowModal(false)}>
                      Document Details
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col xl={3} className="mb-3">
                          <Label
                            htmlFor="first_name"
                            className="col-md-6 col-form-label"
                          >
                            First name *
                          </Label>
                          <Col md={10}>
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              onChange={handle_on_change.bind(this)}
                              value={document.first_name}
                            />
                            {/* <Input
                                type="text"
                                defaultValue="hunter2"
                                onChangeInput={handle_on_change.bind(this)}
                                onInputKeyDown={() =>{} }
                                inputValue={document.first_name}
                                name="first_name"
                                id="first_name"
                              /> */}
                          </Col>
                        </Col>
                        <Col xl={3} className="mb-3">
                          <Label
                            htmlFor="last_name"
                            className="col-md-6 col-form-label"
                          >
                            Last name *
                          </Label>
                          <Col md={10}>
                            <input
                              type="text"
                              className="form-control"
                              name="last_name"
                              onChange={handle_on_change.bind(this)}
                              value={document.last_name}
                            />
                          </Col>
                        </Col>
                        <Col xl={3} className="mb-3">
                          <Label
                            htmlFor="dob"
                            className="col-md-6 col-form-label"
                          >
                            Birthday *
                          </Label>

                          <Col md={10}>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              onChange={(e) => {
                                handle_on_change(
                                  { event: e, name: "dob" },
                                  true
                                );
                              }}
                              options={{
                                EnableTime: false,
                                DateFormat: "Y-m-d",
                                altFormat: "Y-m-d",
                              }}
                              value={document.dob}
                            />
                          </Col>
                        </Col>
                        <Col xl={3} className="mb-3">
                          <Label
                            htmlFor="document_number"
                            className="col-md-6 col-form-label"
                          >
                            Number *
                          </Label>
                          <Col md={10}>
                            <input
                              type="text"
                              className="form-control"
                              name="document_number"
                              onBlur={check_id}
                              onChange={handle_on_change.bind(this)}
                              value={document.document_number}
                            />
                            {showFoundDocumentLoading ? (
                              <i
                                className="mdi mdi-spin mdi-loading"
                                target="_blank"
                              ></i>
                            ) : (
                              ""
                            )}
                            {showFoundDocument ? (
                              <a
                                href={"/member-detail/" + showFoundDocument.id}
                                className="pt-1 text-danger"
                                target="_blank"
                                rel="noreferrer"
                              >
                                User Already Exists
                              </a>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Col>
                        {/* Second line */}
                        <Col xl={3} className="mb-3">
                          <Label
                            htmlFor="address"
                            className="col-md-6 col-form-label"
                          >
                            Address
                          </Label>
                          <Col md={10}>
                            <input
                              type="text"
                              className="form-control"
                              name="adresse"
                              onChange={handle_on_change.bind(this)}
                              value={document.adresse}
                            />
                          </Col>
                        </Col>
                        <Col xl={3} className="mb-3">
                          <Label
                            htmlFor="city"
                            className="col-md-6 col-form-label"
                          >
                            City *
                          </Label>
                          <Col md={10}>
                            <Select
                              value={
                                cities.length
                                  ? cities[0].options.filter(
                                      (o) =>
                                        parseInt(o.value) ===
                                        parseInt(document.city)
                                    )[0]
                                  : {}
                              }
                              onChange={(event) => {
                                handle_on_change({
                                  event,
                                  name: "city",
                                });
                              }}
                              name="city"
                              options={cities}
                              classNamePrefix="select2-selection"
                            />
                            {/* <input
                                type="text"
                                className="form-control"
                                name="city"
                                onChange={handle_on_change.bind(this)}
                                value={document.city}
                              /> */}
                          </Col>
                        </Col>
                        <Col xl={3} className="mb-3">
                          <Label
                            htmlFor="country"
                            className="col-md-6 col-form-label"
                          >
                            Country *
                          </Label>
                          <Col md={10}>
                            <Select
                              value={
                                countries.length
                                  ? countries[0].options.filter(
                                      (o) =>
                                        parseInt(o.value) ===
                                        parseInt(document.country)
                                    )[0]
                                  : {}
                              }
                              onChange={(event) => {
                                handle_on_change({
                                  event,
                                  name: "country",
                                });
                              }}
                              name="country"
                              options={countries}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                        </Col>
                        <Col xl={3} className="mb-3">
                          <Label
                            htmlFor="nationality"
                            className="col-md6 col-form-label"
                          >
                            Nationality *
                          </Label>
                          <Col md={10}>
                            <Select
                              value={
                                countries.length
                                  ? countries[0].options.filter(
                                      (o) =>
                                        parseInt(o.value) ===
                                        parseInt(document.nationality)
                                    )[0]
                                  : {}
                              }
                              onChange={(event) => {
                                handle_on_change({
                                  event,
                                  name: "nationality",
                                });
                              }}
                              name="nationality"
                              options={countries}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                        </Col>
                        <Col xl={2} className="mb-3">
                          <Label
                            htmlFor="nationality"
                            className="col-md6 col-form-label"
                          >
                            Type
                          </Label>
                          <Col md={10}>
                            <Select
                              value={
                                id_types.length
                                  ? id_types[0].options.filter(
                                      (o) => o.value === document.type
                                    )[0]
                                  : {}
                              }
                              onChange={(event) => {
                                handle_on_change({
                                  event,
                                  name: "type",
                                });
                              }}
                              name="type"
                              options={id_types}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                          
                        </Col>
                       {document.type !== 'id' ? <Col xl={4} className="mb-3">
                            <Label
                              htmlFor="address"
                              className="col-md-6 col-form-label"
                            >
                              {document.type === 'passport' ? 'Passport Nb *' : 'Driving license Nb *'}
                            </Label>
                            <Col md={10}>
                              <input
                                type="text"
                                className="form-control"
                                name={`${document.type}_id`}
                                onChange={handle_on_change.bind(this)}
                                value={document[`${document.type}_id`]}
                              />
                            </Col>
                          </Col> : ''} 
                      </Row>

                      <Row>
                        {document ? (
                          <Col xl={6}> {/* Limiting maximum width to prevent taking too much horizontal space */}
                            <div className="d-flex flex-column align-items-center">
                                <a
                                    href={`https://pippipyalahcom.s3.eu-west-3.amazonaws.com/${document.front_image}`}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="me-2"
                                >
                                    View in new Tab
                                </a>
                                <button onClick={handleRotate} className="btn btn-primary me-2">
                                    Rotate Image
                                </button>
                            </div>
                            <div className="image-container" style={{ position: "relative", paddingBottom: "100%", overflow: "hidden" }}> {/* Setting a fixed aspect ratio */}
                                <img
                                    className="rounded p-3 me-2"
                                    alt="Pip Pip yalah"
                                    width="100%"
                                    src={`https://pippipyalahcom.s3.eu-west-3.amazonaws.com/${document.front_image}`}
                                    style={{ 
                                        position: "absolute",
                                        top: "0",
                                        left: "0",
                                        width: "100%",
                                        height: "100%",
                                        transform: `rotate(${rotationAngle}deg)` 
                                    }}
                                />
                            </div>
                        </Col>
                        ) : (
                          ""
                        )}
                        {document ? (
                          <Col xl={6}> 
                            <div className="d-flex flex-column align-items-center">
                                <a
                                    href={`https://pippipyalahcom.s3.eu-west-3.amazonaws.com/${document.back_image}`}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="me-2"
                                >
                                    View in new Tab
                                </a>
                                <button onClick={handleRotate2} className="btn btn-primary me-2">
                                    Rotate Image
                                </button>
                            </div>
                            <div className="image-container" style={{ position: "relative", paddingBottom: "100%", overflow: "hidden" }}> {/* Setting a fixed aspect ratio */}
                                <img
                                    className="rounded p-3 me-2"
                                    alt="Pip Pip yalah"
                                    width="100%"
                                    src={`https://pippipyalahcom.s3.eu-west-3.amazonaws.com/${document.back_image}`}
                                    style={{ 
                                        position: "absolute",
                                        top: "0",
                                        left: "0",
                                        width: "100%",
                                        height: "100%", 
                                        transform: `rotate(${rotationAngle2}deg)` 
                                    }}
                                />
                            </div>
                        </Col>
                      
                        ) : (
                          ""
                        )}
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-14 mb-4">Status</h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="approved"
                                onChange={handle_on_change}
                                id="approve"
                                value="1"
                                checked={parseInt(document.approved) === 1}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="approve"
                              >
                                Approve
                              </Label>
                            </div>
                            <div className="form-check">
                              {/* <Input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" /> */}
                              <input
                                className="form-check-input"
                                type="radio"
                                name="approved"
                                onChange={handle_on_change}
                                id="disapprove"
                                value="2"
                                checked={parseInt(document.approved) === 2}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="disapprove"
                              >
                                Disapprove
                              </Label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        onClick={() => {
                          tog_standard(null);
                        }}
                        color="light"
                        className="waves-effect"
                      >
                        Close
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        disabled={isModalLoading}
                        className="waves-effect waves-light"
                        onClick={() => update_member_cin()}
                      >
                        {isModalLoading ? (
                          <Spinner color="light" size="sm">
                            Load
                          </Spinner>
                        ) : (
                          "Save changes"
                        )}
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <DataTable
                    pagination
                    paginationServer
                    columns={documents_columns}
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    progressPending={isLoading}
                    progressComponent={<Loader />}
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    data={documentsUI}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MemberCIN;