import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Input, InputGroup, Button } from "reactstrap";

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";
import { GET_ALL_CHART_RIDES } from '../../constants/api.constants';
import { get } from '../../helpers/api_helper';

class RidesAnalytics extends Component {
    state = {
        activeRides: 0,
        publishedRides: 0,
        finishedRides: 0,
        canceledRides: 0,
        canceledEmptyRides: 0,

        showActiveRides: true,
        showPublishedRides: true,
        showFinishedRides: true,
        showCanceledRides: true,
        showCanceledEmptyRides: true,

        dateRange: [],

        tempSeries: [],
        tempColors: [],

        series: [],
        options: {
            chart: {
                toolbar: {
                    show: false,
                }
            },
            stroke: {
                width: [2, 2, 2, 2, 2],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    vertical:false,
                    columnWidth: '20%',
                },
            },
            dataLabels: {
                enabled: false,
            },

            legend: {
                show: false,
            },
            colors: [],
            labels: [],
        }
    }

    async componentDidMount () {
        const endDate = new Date(); // Current date
        const startDate = new Date(endDate); // Start date is the current date
        startDate.setFullYear(startDate.getFullYear() - 1, startDate.getMonth() + 1); 
        const tempDate = [startDate, endDate]
        this.setState({
            dateRange: tempDate
        })

        setTimeout(async () => {
            await this.handleOnSearch();
        }, 100); 
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.showActiveRides !== this.state.showActiveRides ||
            prevState.showFinishedRides !== this.state.showFinishedRides ||
            prevState.showCanceledRides !== this.state.showCanceledRides ||
            prevState.showCanceledEmptyRides !== this.state.showCanceledEmptyRides ||
            prevState.showPublishedRides !== this.state.showPublishedRides
        ) {
            this.updateSeriesState();
        }
    }

    updateSeriesState = () => {
        const { tempColors, tempSeries, showActiveRides, showPublishedRides, showFinishedRides, showCanceledRides, showCanceledEmptyRides } = this.state;
        const updatedOptions = { ...this.state.options };
        
        const updatedSeries = [];
        const updatedColors = [];

        if(showActiveRides){
            updatedSeries.push(tempSeries[0]);
            updatedColors.push(tempColors[0]);
        }
        if(showPublishedRides){
            updatedSeries.push(tempSeries[1]);
            updatedColors.push(tempColors[1]);
        }
        if(showFinishedRides){
            updatedSeries.push(tempSeries[2]);
            updatedColors.push(tempColors[2]);
        }
        if(showCanceledRides){
            updatedSeries.push(tempSeries[3]);
            updatedColors.push(tempColors[3]);
        }
        if(showCanceledEmptyRides){
            updatedSeries.push(tempSeries[4]);
            updatedColors.push(tempColors[4]);
        }

        updatedOptions.colors = updatedColors;

        this.setState({
            series: updatedSeries,
            options: updatedOptions
        })
    }

    fetchAllChartRides = async () => {
        try {
            const { dateRange } = this.state;
            let url = `${GET_ALL_CHART_RIDES}?`;

            if (dateRange !== null && dateRange.length === 2) {
                let from_range = dateRange[0];
                let to_range = dateRange[1];
   
                url += `startDate=${from_range}&endDate=${to_range}`
            }

            const response = await get(url);

            if(response){
                const updatedOptions = { ...this.state.options };
                const updatedSeries = [];
                const updatedColors = [];

               
                updatedSeries.push({
                    name: 'Active Rides',
                    type: 'line',
                    data: response.series.map(data => data.activeRides)
                });
                updatedColors.push("#007bff")

                updatedSeries.push({
                    name: 'Published Rides',
                    type: 'line',
                    data: response.series.map(data => data.publishedRides)
                });
                updatedColors.push("#6420aa")

                updatedSeries.push({
                    name: 'Finished Rides',
                    type: 'line',
                    data: response.series.map(data => data.finishedRides)

                });
                updatedColors.push("#1cbb8c")

                updatedSeries.push({
                    name: 'Canceled Rides',
                    type: 'line',
                    data: response.series.map(data => data.canceledRides)
                });
                updatedColors.push("#ffc107")

                updatedSeries.push({
                    name: 'Canceled Empty Rides',
                    type: 'line',
                    data: response.series.map(data => data.canceledEmptyRides)
                });
                updatedColors.push("#dc3545")

                updatedOptions.labels = response.series.map(data => data.label);
    
                this.setState({
                    options: updatedOptions,
                    series: updatedSeries,
                    tempSeries: updatedSeries,
                    tempColors: updatedColors,
                    activeRides: response.data[0].totalActiveRides,
                    publishedRides: response.data[0].totalPublishedRides,
                    finishedRides: response.data[0].totalFinishedRides,
                    canceledRides: response.data[0].totalCanceledRides,
                    canceledEmptyRides: response.data[0].totalCanceledEmptyRides
                }, () => {
                    console.log(response);
                });
            
            } else {
                console.error('Error: Response data is missing or invalid');
            }

        } catch (error) {
            console.error('Error while fetching all users: ', error);
        }
    }

    handleOnSearch = async () => {
        await this.fetchAllChartRides();
        this.updateSeriesState();
    }

    render() {
        const { showActiveRides, showPublishedRides, showFinishedRides, showCanceledRides, showCanceledEmptyRides } = this.state;
        const { activeRides, publishedRides, canceledRides, finishedRides, canceledEmptyRides } = this.state;
        const { dateRange } = this.state;

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <Row className="float-end d-none d-md-flex align-items-center">
                            <Col>
                                <InputGroup>
                                    <Flatpickr
                                        className="form-control d-block"
                                        style={{width: 210}}
                                        placeholder="dd M,yyyy"
                                        value={dateRange}
                                        onChange={(e) => {
                                            this.setState({
                                                dateRange: e
                                            })
                                        }}
                                        options={{
                                        mode: "range",
                                        dateFormat: "Y-m-d"
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Button color="success" type="button" onClick={this.handleOnSearch} className="waves-effect waves-light me-1">
                                    <i className="ri-search-line align-middle me-2"></i> Search
                                </Button>
                            </Col>
                        </Row>
                        {/* <div className="float-end">
                            <select className="form-select form-select-sm">
                                <option defaultValue>Apr</option>
                                <option value="1">Mar</option>
                                <option value="2">Feb</option>
                                <option value="3">Jan</option>
                            </select>
                        </div> */}
                        <h4 className="card-title mb-4">Rides</h4>

                        <div id="donut-chart" className="apex-charts">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="250" />
                        </div>
                    </CardBody>
                    <CardBody className="border-top text-center">

                        <Row className='mb-4 d-flex justify-content-around align-items-center'>
                            <Col xs={3}>
                                <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showPublishedRides} onChange={(val) => this.setState({showPublishedRides: val.target.checked})}  />
                                    <p className="mb-0 text-truncate"><i className="mdi mdi-circle font-size-10 me-1" style={{ color: '#6420aa' }}></i>Published Rides</p>
                                </div>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{publishedRides}</h5>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showActiveRides} onChange={(val) => this.setState({showActiveRides: val.target.checked})}  />
                                    <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Active Rides</p>
                                </div>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{activeRides}</h5>
                                </div>
                            </Col>
                        </Row>

                        <Row className='d-flex justify-content-around align-items-center'>
                            <Col xs={3}>
                                <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showFinishedRides} onChange={(val) => this.setState({ showFinishedRides: val.target.checked })} />
                                    <p className="mb-0 text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> Finished Rides</p>
                                </div>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{finishedRides}</h5>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showCanceledRides} onChange={(val) => this.setState({ showCanceledRides: val.target.checked })} />
                                    <p className="mb-0 text-truncate"><i className="mdi mdi-circle text-warning font-size-10 me-1"></i> Canceled Rides</p>
                                </div>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{canceledRides}</h5>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showCanceledEmptyRides} onChange={(val) => this.setState({ showCanceledEmptyRides: val.target.checked })} />
                                    <p className="mb-0 text-truncate "><i className="mdi mdi-circle text-danger font-size-10 me-1"></i> Cancelled Empty Rides</p>
                                </div>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{canceledEmptyRides}</h5>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default RidesAnalytics;