import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Loader from "../../components/Loader/Loader";
import { BASE_URL } from "../../constants/api.constants";
import { get } from "../../helpers/api_helper";
import useDate from "../../helpers/date";

const StatisticPage = () => {
  const { dayjs } = useDate();
  const { data, isLoading } = useQuery({
    queryKey: ["codePromo", "statistics"],
    queryFn: () => get(`${BASE_URL}/code-promo/statistics`),
  });

  const columns = [
    {
      title: "Total Codes",
      dataIndex: "total_promo_codes",
    },
    {
      title: "Total Transactions",
      dataIndex: "total_transactions",
    },
    {
      title: "Total Used Codes",
      dataIndex: "used_promo_codes",
    },
    {
      title: "Total Amount Deducted",
      dataIndex: "total_amount_deducted",
      suffix: "MAD",
    },
  ];

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div>
          <Loader />
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        padding: "90px 30px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",
          width: "100%",
        }}
        className="flex gap-3 w-full  flex-col container"
      >
        {data?.map((item, i) => (
          <div
            key={i}
            style={{
              padding: "40px",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              width: "100%",
            }}
            className="t-flex t-flex-col items-start tw-w-full bg-white"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <h4>{item.partner}</h4>
              <Link
                to={`/code-promo/partner/${item.partner?.replaceAll(" ", "_")}`}
              >
                View all codes
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              {columns.map((column, i) => (
                <div
                  key={i}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #f1f1f1",
                    borderRadius: "10px",
                    gap: "10px",
                  }}
                  className="p-3"
                >
                  <span>{column.title}</span>
                  <span
                    style={{
                      color: "black",
                      fontSize: "1.5rem",
                    }}
                    className="text-lg"
                  >
                    {item[column.dataIndex] ?? 0} {column.suffix}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatisticPage;
