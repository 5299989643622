import React, { useEffect, useState } from "react";
import { get } from "../../helpers/api_helper";
import { VEHICLES } from "../../constants/api.constants";
import DataTable from "react-data-table-component";
import { timeAgo } from "../../helpers/utils";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const columns = [
  {
    name: "Vehicle",
    selector: (row) => row.vehicle,
  },
  {
    name: "Matricule",
    selector: (row) => row.matricule,
  },
  {
    name: "Added Date",
    selector: (row) => row.date,
  },
];
export default function Vehicles({ user_id }) {
  const [vehicles, setVehicles] = useState([]);

  const fetchVehicles = async () => {
    try {
      const response = await get(VEHICLES + '?user_id=' + user_id);
      let newVec = [];
      for (let i = 0; i < response.vehicles.length; i++) {
        const vehicle = response.vehicles[i];

        newVec.push({
          vehicle: <Link to={'#'}>{vehicle.brand_name} {vehicle.model_name} {vehicle.name}</Link>,
          matricule: `${
            vehicle.type === 2
              ? "WW" + vehicle.vehicle_number_one
              : vehicle.vehicle_number_one +
                " - " +
                vehicle.vehicle_number_two +
                " - " +
                vehicle.vehicle_number_three
          }`,
          date: timeAgo(vehicle.created_date),
        });
      }
      setVehicles(newVec);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVehicles(user_id);
  }, []);

  return (
    <div className="table-responsive">
      <DataTable
        pagination
        columns={columns}
        paginationRowsPerPageOptions={[5, 10]}
        progressPending={false}
        paginationPerPage={5}
        progressComponent={<Loader />}
        paginationTotalRows={10}
        data={vehicles}
      />
    </div>
  );
}
