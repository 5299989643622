import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import MemberDashboard from "../pages/MemberDashboard";
import MemberDetail from "../pages/MemberDashboard/MemberDetail";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

// Pages Component
import Chat from "../pages/Chat/Chat";

//Ecommerce Pages
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";

//rides
import AllRides from "../pages/Rides/AllRides";
import CovidCampaign from "../pages/Rides/CovidCampaign";
import PublishOffers from "../pages/Rides/PublishOffers";
import RidesDashboard from "../pages/Rides/RidesDashboard";
//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/jquery-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import RemixIcons from "../pages/Icons/RemixIcons";

//Utility
import CommingSoon from "../pages/Utility/CommingSoon";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import FAQs from "../pages/Utility/FAQs";
import Maintenance from "../pages/Utility/Maintenance";
import Pricing from "../pages/Utility/Pricing";
import StarterPage from "../pages/Utility/StarterPage";
import Timeline from "../pages/Utility/Timeline";

// Forms
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormElements from "../pages/Forms/FormElements";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormValidations from "../pages/Forms/FormValidations";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UIRoundSlider from "../pages/Ui/UIRoundSlider";
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiRating from "../pages/Ui/UiRating";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiNotifications from "../pages/Ui/ui-notifications";

//Tables
import AllMembers from "../pages/MemberDashboard/AllMembers";
import MemberCIN from "../pages/MemberDashboard/MembersCIN";
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import EditableTables from "../pages/Tables/EditableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";

// Inner Authentication
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";

//Kanban Board
import AutomatizationPage from "../pages/Automatizations";
import Frauds from "../pages/Chat/Frauds";
import Signalements from "../pages/Chat/Signalements";
import CodePromo from "../pages/CodePromo";
import PartnerCodePromo from "../pages/CodePromo/partnerCodePage";
import StatisticPage from "../pages/CodePromo/statisticPage";
import KanbanBoard from "../pages/KanbanBoard/index";
import PaymentDashboard from "../pages/Payment/Dashboard";
import UncompletedPayments from "../pages/Payment/UncompletedPayments";
import Virements from "../pages/Payment/Virements";
import OppositeRides from "../pages/Rides/AllRides/oppositeRides.component";
import PublishRides from "../pages/Rides/PublishRide";
import RideDetail from "../pages/Rides/RideDetail";
import RidesHandles from "../pages/Rides/rides.handle.page";
import Transaction from "../pages/Transactions/transaction.component";
import Blogs from "../pages/blogs";
import BlogForm from "../pages/blogs/blogForm";
import CancelledReservations from "../pages/reservations/CancelledReservations";
import ReservationsDashboard from "../pages/reservations/dashboard.page";
import ReservationsPage from "../pages/reservations/reservation.page";
// import Rides from '../../src/pages/Rides'
const authProtectedRoutes = [
  //Kanban Board
  { path: "/kanban-board", component: KanbanBoard },

  // Tables
  { path: "/basic-tables", component: BasicTables },
  { path: "/datatable-table", component: DatatableTables },
  { path: "/responsive-table", component: ResponsiveTables },
  { path: "/editable-table", component: EditableTables },
  { path: "/all-members", component: AllMembers },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-roundslider", component: UIRoundSlider },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-file-upload", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  //Utility
  { path: "/starter", component: StarterPage },
  { path: "/timeline", component: Timeline },
  { path: "/faqs", component: FAQs },
  { path: "/pricing", component: Pricing },

  //Icons
  { path: "/icons-remix", component: RemixIcons },
  { path: "/material-design", component: MaterialDesign },
  { path: "/dripicons", component: DripiIcons },
  { path: "/font-awesome-5", component: FontAwesome },

  // Maps
  { path: "/google-maps", component: MapsGoogle },
  { path: "/vector-maps", component: MapsVector },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartjs", component: ChartjsChart },
  { path: "/charts-sparkline", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },

  //Ecommerce

  { path: "/ecommerce-products", component: EcommerceProducts },
  { path: "/ecommerce-product-detail/:id", component: EcommerceProductDetail },
  { path: "/ecommerce-orders", component: EcommerceOrders },
  { path: "/ecommerce-customers", component: EcommerceCustomers },
  { path: "/ecommerce-cart", component: EcommerceCart },
  { path: "/ecommerce-checkout", component: EcommerceCheckout },
  { path: "/ecommerce-shops", component: EcommerceShops },
  { path: "/ecommerce-add-product", component: EcommerceAddProduct },

  //chat
  { path: "/chat", component: Chat },

  //calendar
  { path: "/calendar", component: Calendar },
  { path: "/automatizations", component: AutomatizationPage },

  { path: "/dashboard", component: Dashboard },

  { path: "/member-detail/:id", component: MemberDetail },
  { path: "/member-dashboard", component: MemberDashboard },
  { path: "/member-cin/:user_id", component: MemberCIN },
  { path: "/member-cin/", component: MemberCIN },

  //rides
  { path: "/all-rides/:user_id", component: AllRides },
  { path: "/all-rides", component: AllRides },
  { path: "/ride-opposites", component: OppositeRides },
  { path: "/rides-dashboard", component: RidesDashboard },

  { path: "/reservations/:user_id", component: ReservationsPage },
  { path: "/reservations", component: ReservationsPage },
  { path: "/reservations-dashboard", component: ReservationsDashboard },
  { path: "/cancelled-reservations", component: CancelledReservations },

  { path: "/covid-campaign", component: CovidCampaign },
  { path: "/publish-offers", component: PublishOffers },
  { path: "/rides/follow-up", component: RidesHandles },
  { path: "/ride-detail/:ride_id", component: RideDetail },
  { path: "/transactions/:user_id", component: Transaction },
  { path: "/transactions/", component: Transaction },
  { path: "/uncompleted-payments/", component: UncompletedPayments },
  { path: "/fraud-detection/", component: Frauds },
  { path: "/signalements/", component: Signalements },

  { path: "/virements", component: Virements },
  { path: "/payments-dashboard", component: PaymentDashboard },
  { path: "/publish-rides", component: PublishRides },
  ///promo code
  { path: "/code-promo/statistics", component: StatisticPage },
  { path: "/code-promo/ORANGE", component: CodePromo },
  { path: "/blogs/:id", component: BlogForm },
  { path: "/blogs/new", component: BlogForm },
  { path: "/blogs", component: Blogs },

  { path: "/code-promo/partner/:partner", component: PartnerCodePromo },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to={localStorage.getItem("role") == 2 ? "/member-cin" : "/dashboard"} /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/lock-screen", component: AuthLockScreen },

  // Authentication Inner
  { path: "/auth-login", component: Login1 },
  { path: "/auth-register", component: Register1 },
  { path: "/auth-recoverpw", component: ForgetPwd1 },

  { path: "/maintenance", component: Maintenance },
  { path: "/comingsoon", component: CommingSoon },
  { path: "/404", component: Error404 },
  { path: "/500", component: Error500 },
];


export { authProtectedRoutes, publicRoutes };

