import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import {
  Button,
  Card,
  CardBody,
  Col,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
// datatable related plugins


//Import Breadcrumb
// import "./datatables.scss";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { GET_RIDES } from "../../constants/api.constants";
import { get } from "../../helpers/api_helper";

const SearchRide = () => {
  // Change useNavigate to useHistory
  const history = useHistory();
  const [dateRange, setRideRange] = useState([]);
  const [searches, setSearches] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState(null);
  const [matchingRides, setMatchingRides] = useState([]);

  const handlePageChange = (page) => {
    // the dataTable pagination starts from page 1 - 10/20.. where it should start from page 0 to get all data
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // Add this function near other handlers
  const handleSearch = () => {
    setIsSearching(true);
    fetchSearches().finally(() => {
      setIsSearching(false);
    });
  };

  // Modify the fetchSearches function
  const fetchSearches = async () => {
    try {
      let url = GET_RIDES + `/search?page=${page}&per_page=${perPage}`;

      // Add search parameters
      const params = [];
      if (keyword) params.push(`keyword=${keyword}`);
      if (dateRange && dateRange.length === 2) {
        params.push(`from=${dateRange[0].toISOString().split('T')[0]}`);
        params.push(`to=${dateRange[1].toISOString().split('T')[0]}`);
      }

      if (params.length > 0) {
        url += '&' + params.join('&');
      }

      const response = await get(url);
      setTotalRows(response._pagination.total_items);

      let formattedSearches = response.searches.map(search => ({
        id: search.id,
        user: (
          <Link to={`/member-detail/${search.user_id}`}>
            {search.first_name} {search.last_name}
          </Link>
        ),
        from: search.from_city_name,
        to: search.to_city_name,
        ride_date: search.ride_date,
        passengers: search.nbr_passengers,
        search_date: search.search_date
      }));

      setSearches(formattedSearches);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSeeRides = async (search) => {
    try {
      const url = GET_RIDES + `/search/${search.id}`;
      const response = await get(url);
      setMatchingRides(response.rides || []);
      setSelectedSearch(search);
      setIsModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };



  // Remove this useEffect
  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     setIsSearching(true);
  //     fetchSearches().finally(() => {
  //       setIsSearching(false);
  //     });
  //   }, 800)
  //   return () => clearTimeout(delayDebounceFn)
  // }, [keyword])

  // Keep this useEffect for pagination
  useEffect(() => {
    fetchSearches();
  }, [page, perPage]);

  const columns = [
    {
      name: "User",
      selector: (row) => row.user,
    },
    {
      name: "From",
      selector: (row) => row.from,
    },
    {
      name: "To",
      selector: (row) => row.to,
    },
    {
      name: "Ride Date",
      selector: (row) => row.ride_date,
    },
    {
      name: "Passengers",
      selector: (row) => row.passengers,
    },
    {
      name: "Search Date",
      selector: (row) => row.search_date,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          color="primary"
          size="sm"
          onClick={() => handleSeeRides(row)}
        >
          See Rides
        </Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl={3} className="mb-3">
                      <Label className="form-label">
                        Search
                      </Label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setKeyword(e.target.value)}
                          value={keyword}
                        />
                      </div>
                    </Col>
                    <Col xl={3} className="mb-3">
                      <Label className="form-label">Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          value={dateRange}
                          onChange={(dates) => {
                            setRideRange(dates);
                          }}
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d"
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col xl={2} className="mb-3">
                      <Label className="form-label">&nbsp;</Label>
                      <div>
                        <Button
                          color="primary"
                          onClick={handleSearch}
                          disabled={isSearching}
                        >
                          {isSearching ? (
                            <>
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                              Searching...
                            </>
                          ) : (
                            'Search'
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <DataTable
                    pagination
                    paginationServer
                    columns={columns}
                    progressPending={isLoading}
                    progressComponent={<Loader />}
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    data={searches}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          {selectedSearch && `Rides from ${selectedSearch.from} to ${selectedSearch.to}`}
        </ModalHeader>
        <ModalBody>
          {matchingRides.length > 0 ? (
            <DataTable
              columns={[
                {
                  name: "Driver",
                  selector: (row) => `${row.first_name} ${row.last_name}`,
                },
                {
                  name: "From",
                  selector: (row) => row.from_city_name,
                },
                {
                  name: "To",
                  selector: (row) => row.to_city_name,
                },
                {
                  name: "Date",
                  selector: (row) => row.ride_date,
                },
                {
                  name: "Price",
                  selector: (row) => row.price + " MD",
                },
                {
                  name: "Action",
                  cell: (row) => (
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => history.push(`/ride-detail/${row.ride_id}`)}
                    >
                      View
                    </Button>
                  ),
                },
              ]}
              data={matchingRides}
              pagination
            />
          ) : (
            <div className="text-center">No matching rides found</div>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SearchRide;
