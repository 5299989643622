import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Table,
  CardText,
  Row,
  Col,
  InputGroup,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
//Import Breadcrumb
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DashedLine from "../../components/Charts/CA";
import { get } from "../../helpers/api_helper";
import { STATS } from "../../constants/api.constants";
import Loader from "../../components/Loader/Loader";
import { convertDate } from "../../helpers/utils";

export default function PaymentDashboard() {
  const [stats, setStats] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(true);

  const getStats = async () => {
    console.log("executing");

    try {
      setLoading(true);
      const now = new Date();
      let start_date = new Date(now.getFullYear(), now.getMonth(), 1);
      let end_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 4);

      if (dateRange.length) {
        console.log("updated");
        start_date = new Date(dateRange[0]);
        end_date = new Date(dateRange[1]);
      }

      var data = await get(
        STATS.PAYMENT +
          `?start_date=${convertDate(start_date)}&end_date=${convertDate(
            end_date
          )}`
      );
      console.log(data);
      setStats(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    if (dateRange.length >= 2 || dateRange.length === 0) getStats();
  }, [dateRange]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Col xl={2}>
            {/* <DateRangeSelector /> */}
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder="dd M,yyyy"
                value={dateRange}
                onChange={(e) => {
                  console.log(e);
                  setDateRange(e);
                }}
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                }}
              />
            </InputGroup>
          </Col>

          <Row>
            <Col xl={2}>
              <Card className="custom-card">
                <CardBody>
                  <CardText>Total Wallet</CardText>
                  <CardTitle>{parseInt(stats?.kpis?.real)} MAD </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col xl={2}>
              <Card className="custom-card">
                <CardBody>
                  <CardText>Total Gift</CardText>
                  <CardTitle>{parseInt(stats?.kpis?.gift)} MAD </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col xl={2}>
              <Card className="custom-card">
                <CardBody>
                  <CardText>Total Negative</CardText>
                  <CardTitle>{parseInt(stats?.kpis?.negative)} MAD </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col xl={2}>
              <Card className="custom-card">
                <CardBody>
                  <CardText>Given Gift</CardText>
                  <CardTitle>{parseInt(stats?.kpis?.given_gift.amount)} MAD </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col xl={2}>
              <Card className="custom-card">
                <CardBody>
                  <CardText>Total Fees</CardText>
                  <CardTitle>
                    {parseInt(stats?.kpis?.total_profit?.profit)} MAD{" "}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col xl={2}>
              <Card className="custom-card">
                <CardBody>
                  <CardText>Total Penalties </CardText>
                  <CardTitle>
                    {parseInt(stats?.kpis?.total_penalties?.amount)} MAD{" "}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card className="custom-card">
                <CardBody>
                  <CardText>Turnover</CardText>
                  {loading ? (
                    <Loader height={"400px"} />
                  ) : stats?.turnover?.length ? (
                    <DashedLine stats={stats} />
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

function DateRangeSelector() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month =
      date.getMonth().toString().length === 1
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day =
      date.getDate().toString().length === 1
        ? `0${date.getDate()}`
        : date.getDate();
    return `${year}-${month}-${day}`;
  };

  return (
    <div>
      <h3>Start Date:</h3>
      <input
        type="date"
        value={formatDate(startDate)}
        onChange={(e) => handleStartDateChange(new Date(e.target.value))}
      />
      <h3>End Date:</h3>
      <input
        type="date"
        value={formatDate(endDate)}
        onChange={(e) => handleEndDateChange(new Date(e.target.value))}
      />
      <br />
      <br />
      <p>
        Selected Range: {formatDate(startDate)} - {formatDate(endDate)}
      </p>
    </div>
  );
}
