import React from "react";
import { useForm } from "react-hook-form";
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";

const BrandModal = ({ isOpen, toggle, onSubmit, editingBrand }) => {
    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            brand_name: editingBrand?.brand_name || ""
        }
    });

    const handleFormSubmit = (data) => {
        onSubmit(data);
        reset();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <Form onSubmit={handleSubmit(handleFormSubmit)}>
                <ModalHeader toggle={toggle}>
                    {editingBrand ? 'Edit Brand' : 'Add New Brand'}
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="brand_name">Brand Name</Label>
                        <Input
                            id="brand_name"
                            {...register("brand_name", { required: true })}
                            placeholder="Enter brand name"
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                    <Button color="primary" type="submit">
                        {editingBrand ? 'Update' : 'Create'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default BrandModal;