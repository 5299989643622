import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { get, post, put } from "../../helpers/api_helper";
import { Link, useParams } from "react-router-dom";
import {
  CHATS,
  GET_MEMBERS,
  GET_TRANSACTIONS,
  POST_TRANSACTION,
} from "../../constants/api.constants";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import Select from "react-select";
import { transactions_types, wallet_types } from "../../data/selects.data";
import SweetAlert from "react-bootstrap-sweetalert";
import Cookies from "js-cookie";
import { forbiddenWords } from "../../data/forbidden.words";
// import TransactionsV4 from "./transactionsv4";

const Signalements = () => {
  const [signalements, setSignalements] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [signalement, setSignalement] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  toastr.options = {
    positionClass: "toast-bottom-left",
    closeButton: true,
    progressBar: true,
  };

  const handle_update_signalement = async () => {
    setShowSweetAlert(false)
    const response = await put(`${GET_MEMBERS}signalements?report_id=${signalement.id}`);
    if (response.message) {
      let newSignalements = [...signalements];
      const indexToUpdate = newSignalements.findIndex(
        (obj) => obj.id === signalement.id
      );
      console.log(indexToUpdate);
      if (indexToUpdate !== -1) {
        newSignalements[indexToUpdate] = get_html({...signalement,status: 1});
      }
      setSignalements(newSignalements);
      
    }
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  useEffect(() => {
    fetchSignalements();
  }, [page, perPage]);

  const fetchSignalements = async (show_loader = true) => {
    setIsLoading(show_loader);
    try {
    
      const response = await get(
        `${GET_MEMBERS}signalements?per_page=${perPage}&page=${page}`
      );
      let temp = [];
      for (let index = 0; index < response.signalements.length; index++) {
        const signalement = response.signalements[index];
        temp.push(get_html(signalement));
      }
      setTotalRows(response._pagination.total_items);
      setSignalements(temp);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const get_html = (signalement) => {
    return {
        ...signalement,
        report: (
          <Link to={"/member-detail/" + signalement.report_id}>
            {" "}
            {signalement.report_fname + " " + signalement.report_lname}{" "}
          </Link>
        ),
        reported: (
          <Link to={"/member-detail/" + signalement.reported_id}>
            {" "}
            {signalement.reported_fname + " " + signalement.reported_lname}{" "}
          </Link>
        ),
        ride: (
          <Link to={"/ride-detail/" + signalement.ride_id}>
            {" "}
            View Ride
          </Link>
        ),
        message: (
          <div className="custom-div-container">
               {/* <Link to={"/ride-detail/" + signalement.ride_id}> */}
                 {/* {LongText(signalement.msg)} */}
                 {/* <HighlightForbiddenWords text={signalement.msg} /> */}
                 {/* <p className="table-p">{HighlightWords(chat.msg)}</p> */}
               {/* </Link> */}

                <LongText text={signalement.msg}/>
               {/* <HighlightForbiddenWords text={signalement.msg} />
               <p className="table-p">{HighlightWords(chat.msg)}</p> */}
          </div>
        ),
        date: <p>{signalement.date}</p>,
        action:
          signalement.status === 0 ? (
            <button
              type="button"
              class="waves-effect waves-light me-1 btn btn-danger btn-sm"
              onClick={() => {
                setSignalement(signalement);
                setShowSweetAlert(true);
              }}
            >
              <i class="ri-check-line align-middle me-2"></i> Archive
            </button>
          ) : (
            "Report Archived"
          ),
      }
  }
  
  const columns = [
    {
        name: "Signalant",
      selector: (row) => row.report,
    },
    {
        name: "Signalé",
      selector: (row) => row.reported,
    },
    {
      name: "Ride",
      selector: (row) => row.ride,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      grow: 3,
    },

    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                {showSweetAlert ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => handle_update_signalement()}
                    onCancel={() => setShowSweetAlert(false)}
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : (
                  ""
                )}
                <CardBody>
                  <CardTitle className="h4" style={{ textAlign: "center" }}>
                    <DataTable
                      pagination
                      paginationServer
                      columns={columns}
                      paginationRowsPerPageOptions={[10, 20, 50, 100]}
                      progressPending={isLoading}
                      progressComponent={<Loader />}
                      paginationTotalRows={totalRows}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                      data={signalements}
                    />
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Signalements;

const HighlightForbiddenWords = ({ text }) => {
  let words = text.split(" ");

  const isPhoneNumber = (word) => /^\d{10}$/.test(word);

  words = words.map((word) => {
    if (forbiddenWords.includes(word) || isPhoneNumber(word)) {
      return <mark key={word}>{word}</mark>;
    } else {
      return word;
    }
  });

  return (
    <p>
      {words.map((word, index) => (
        <React.Fragment key={index}>{word} </React.Fragment>
      ))}
    </p>
  );
};


function LongText({ text }) {
    const [showPopup, setShowPopup] = useState(false);
  
    // Split the text into words and get the first 5
    const words = text.split(" ");
    const shortText = words.slice(0, 5).join(" ");
  
    const togglePopup = () => setShowPopup(!showPopup);
  
    return (
      <div>
        <p>
          {shortText}{" "}
          {words.length > 5 && (
            <Button className="btn btn-sm btn-primary btn-link-adj" onClick={togglePopup}>See more</Button>
          )}
        </p>
        {showPopup && (
          <div className="popup">
          <div className="popup-content">
            <p>{text}</p>
            <button onClick={togglePopup}>close</button>
          </div>
        </div>
        )}
      </div>
    );
  }