import "flatpickr/dist/themes/material_blue.css";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  InputGroup,
  Label,
  Row
} from "reactstrap";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

// import { orders, rides } from "../../../common/data"

import {
  GET_CANCELLED_RESERVATIONS,
  GET_RESERVATIONS,
  PUT_RESERVATION,
} from "./../../constants/api.constants";

import { get, put } from "../../helpers/api_helper";

import Cookies from "js-cookie";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../components/Loader/Loader";
import { updateReservationNotification } from "../../store/actions";
import { FaCaretDown } from "react-icons/fa";

const CancelledReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [originalReservations, setOriginalReservations] = useState([]);
  const [activeBtn, setActiveBtn] = useState("reset");
  const [dateRange, setRideRange] = useState([]);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: 5,
  });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [current_reservation, setCurrentReservation] = useState([]);
  const [nbrNewCancelledRes, setNbrNewCancelledRes] = useState(0)
  const {user_id } = useParams();
  
  toastr.options = {
    positionClass: "toast-bottom-left",
    closeButton: true,
    progressBar: true,
  };

  const dispatch = useDispatch();
  const newReservationCount = useSelector(state => state.Notifications.newReservations)

  useEffect(() => {
    // fetch_fill_rate();
    filter();
    console.log('newReservationCount : ', newReservationCount)
  }, []);
  useEffect(() => {
    filter();
    console.log(reservations)
  }, [perPage, page]);

  const handleChangeReservationHandle = (e, reservation_id, reservationDate) => {
    setCurrentReservation({ reservation_id, val: e.target.value });
    setShowSweetAlert(true);

    const today = new Date().toISOString().split('T')[0];
    if (reservationDate === today) {
      dispatch(updateReservationNotification('newReservations', newReservationCount - 1))
    }
  };

  const handleChangeReservationStatus = async () => {
    const config = {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
    try {
      await put(PUT_RESERVATION, current_reservation, config);
      toastr.success("Reservation Updated");
      setShowSweetAlert(false);
      filter();
    } catch (error) {
      toastr.error("System Error");
      console.log(error);
    }
  };
  const filter = () => {
    let _query = "";

    console.log("Filtering..");

    if (dateRange.length === 2) {
      let from_range = dateRange[0];
      let to_range = dateRange[1];
      
      // Constructing date strings in the format 'YYYY-MM-DD'
      let d1 = new Date(from_range);
      let d2 = new Date(to_range);
      let from_range_formatted = `${d1.getFullYear()}-${(d1.getMonth() + 1).toString().padStart(2, '0')}-${d1.getDate().toString().padStart(2, '0')}`;
      let to_range_formatted = `${d2.getFullYear()}-${(d2.getMonth() + 1).toString().padStart(2, '0')}-${d2.getDate().toString().padStart(2, '0')}`;
      
      _query += `?from_range=${from_range_formatted}&to_range=${to_range_formatted}`;
      // fetch_fill_rate(from_range_formatted, to_range_formatted);
    }

    _query += (_query === "" ? "?" : "&") + `page=${page}&per_page=${perPage}`;
    _query += user_id ? `&user_id=${user_id}` : ''
    console.log(_query);
    fetch_reservations(_query);
    
  };

  const fetch_reservations = async (_query = null) => {
    setIsLoading(true);
    try {
      const response = await get(GET_CANCELLED_RESERVATIONS + (_query ?? ""));
      let totalSize = response._pagination.total_items;
      setNbrNewCancelledRes(response._pagination.total_new_reservations)
      let temp = [];
      console.log('response res : ', response)
      console.log('request url : ', GET_RESERVATIONS + _query)
      console.log('reservation : ', response.reservations)
      response.reservations.forEach((reservation) => {
        temp.push({
          key: reservation.ride_id,
          driverName: (
            <ul>
              <li>
                <Link to={"/member-detail/" + reservation.passenger_id}>
                  {reservation.first_name} {reservation.last_name}
                </Link>
              </li>
              <li>0{reservation.passenger_number}</li>
            </ul>
          ),
          ride: (
            <Link to={"/ride-detail/" + reservation.ride_id}>
              {reservation.departure_city} {">"} {reservation.arrival_city}
            </Link>
          ),
          districts: (
              (reservation.departure_district && reservation.departure_district !== "unknown") ? 
              reservation.departure_district : 
              "Other"
          ) + " > " + (
              (reservation.arrival_district && reservation.arrival_district !== "unknown") ? 
              reservation.arrival_district : 
              "Other"
          ),
          price: reservation.price.toFixed(2),
          departureDate: `${reservation.departure_date}`,
          reservationDate: `${reservation.reservation_date}`,
          seats: reservation.seats,
          //   status: <span class={`badge bg-${status.class}`}>{status.text}</span>,
          actions:
            reservation.reservation_handle?.status !== 2 ? (
              <select
                className={`${reservation.reservation_handle?.status === 1 ? 'custom-select-not-responding' : 'custom-select' }`}
                onChange={(e) => {
                  handleChangeReservationHandle(e, reservation.reservation_id, reservation.reservation_date);
                }}
              >
                <option disabled selected={!reservation.reservation_handle}>
                  New
                </option>
                <option
                  value="1"
                  selected={reservation.reservation_handle?.status === 1}
                >
                  Not responding
                </option>
                <option
                  value="2"
                  selected={reservation.reservation_handle?.status === 2}
                >
                  Called
                </option>
              </select>
            ) : (
              <span class="badge bg-primary">Called ✓</span>
            ),
        });
      });
      console.log("reservation",temp)
      setReservations(temp);
      setOriginalReservations(temp);
      console.log(totalSize);
      setTotalRows(totalSize);
      console.log(temp);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleResetFilters = () => {
    setActiveBtn("reset")
    setReservations(originalReservations);
  };

  const handleFilterChange = (filterType) => {
    if (filterType === "price") {
      setActiveBtn("price")
      const sortedReservations = [...reservations].sort(
        (a, b) => parseFloat(b.price) - parseFloat(a.price)
      );
      setReservations(sortedReservations);
    } else if (filterType === "date") {
      setActiveBtn("date")
      const sortedReservations = [...reservations].sort((a, b) => {
        const dateA = new Date(a.departureDate);
        const dateB = new Date(b.departureDate);
        return dateA - dateB;
      });
      setReservations(sortedReservations);
    }
  };  

  // const fetch_fill_rate = async (from_range = false,to_range = false) => {
  //   const config = {
  //     headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  //   };
  //   var response;
  //   if(from_range){
  //     response = await get(GET_RESERVATIONS + "fill-rate?from_range="+from_range+"&to_range="+to_range, config);
  //   }else{
  //     response = await get(GET_RESERVATIONS + "fill-rate", config);
  //   }
  //   if (response.rate) {
  //     setFillRate(response.rate);
  //   }
  //   if(response.nbr_reservations){
  //     setNbrReservations(response.nbr_reservations)
  // }
  // if(response.ratio_reservations_travelled){
  //     setRatioTravelReservation(response.ratio_reservations_travelled)
  // }
  //   if (response.total_seats) {
  //     setStats(response);
  //   }
  // };

  const CustomHeader = ({ title, onClick, activeBtn, column }) => (
    <span onClick={onClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
      {title}
      {activeBtn === column ? (
        <span style={{ marginLeft: '8px', width: '7px', height: '7px', backgroundColor: '#FFD700', borderRadius: '50%' }}></span>
      ) : (
        <FaCaretDown className="down-angle-icon" style={{marginLeft: '5px'}} />
      )}
    </span>
  );

  const columns = [
    {
      name: "Fullname",
      selector: (row) => row.driverName,
    },
    {
      name: "Ride",
      cell: (row) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                <div>{row.ride}</div>
                <div style={{"fontSize": "11px", "color": "#808080"}}>{row.districts}</div>
            </div>
        );
      },
      grow: 1.5
    },
    {
      name: "Seats",
      width: "50px",
      selector: (row) => row.seats,
    },
    {
      name: (
        <CustomHeader
          title="Price"
          onClick={() => {
            handleFilterChange('price')
          }}
          activeBtn={activeBtn}
          column='price'
        />
      ),
      selector: (row) => row.price,
      filterComponent: (
        <input
          type="checkbox"
          onChange={() => handleFilterChange("price")}
        />
      ),
      allowOverflow: true,
      grow: 0.6
    },
    {
      name: "Reservation Date",
      selector: (row) => row.reservationDate,
    },
    {
      name: (
        <CustomHeader
          title="Departure Date"
          onClick={() => {
            handleFilterChange('date')
          }}
          activeBtn={activeBtn}
          column='date'
        />
      ),
      selector: (row) => row.departureDate,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
    },
  ];

  const handlePageChange = (page) => {
    setPage(page - 1);
    setActiveBtn("reset");
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setActiveBtn("reset");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
            
            
           
            <Col xs="12">
              <Card className="custom-card">
                <CardBody>
                 
                  <Row>
                    <Col xs="3">
                      <div className="col mb-3" xl="3">
                        <Label>Departure Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            value={dateRange}
                            onChange={(e) => {
                              console.log("changed");
                              setRideRange(e);
                            }}
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col xs="3">
                      <div
                        className="col mt-4"
                        xl="3"
                        style={{ "margin-top": "28px !important" }}
                      >
                        <Button
                          color="success"
                          type="button"
                          onClick={filter}
                          className="waves-effect waves-light me-1"
                        >
                          <i className="ri-search-line align-middle me-2"></i>
                          <span className="align-middle">Search</span>
                        </Button>
                      </div>
                    </Col>
                    <Col xs="2">
                      <Card className="custom-card">
                        <CardBody>
                          <>
                            <p>To Treat</p>
                            <h4>{nbrNewCancelledRes}</h4>
                          </>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="custom-card">
                <CardBody>
                  <div className="d-flex gap-2 mb-3">
                    <Button
                      color={activeBtn === "reset" ? "primary" : "warning"}
                      type="button"
                      onClick={handleResetFilters}
                      className="mr-3"
                    >
                      Reset Filters
                    </Button>
                    {/* <Button
                      color={activeBtn === "price" ? "primary" : "warning"}
                      type="button"
                      onClick={() => handleFilterChange('price')}
                    >
                      Filter by Price
                    </Button>
                    <Button
                      color={activeBtn === "date" ? "primary" : "warning"}
                      type="button"
                      onClick={() => handleFilterChange('date')}
                    >
                      Filter by Date
                    </Button> */}
                  </div>
                  <DataTable
                    pagination
                    paginationServer
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    columns={columns}
                    progressPending={isLoading}
                    progressComponent={<Loader />}
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    data={reservations}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CancelledReservations;
