import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Label,
  Row
} from "reactstrap";

// datatable related plugins


//Import Breadcrumb
// import "./datatables.scss";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { GET_MEMBERS } from "../../constants/api.constants";
import { get } from "../../helpers/api_helper";

const AllMembers = () => {
  const [members, setMembers] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handlePageChange = (page) => {
    // the dataTable pagination starts from page 1 - 10/20.. where it should start from page 0 to get all data
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const fetchMembers = async () => {
    // setIsLoading(true);
    try {
      let url = GET_MEMBERS + `?page=${page}&per_page=${perPage}`;
      const response = await get(keyword !== '' ? url + "&keyword=" + keyword : url);
      //  setTotalRows(response._pagination.total_items);
      setTotalRows(response._pagination.total_items);

      setMembers(response.members);
      let temporary_members = [];
      for (let i = 0; i < response.members.length; i++) {
        const member = response.members[i];
        temporary_members.push({
          is_company: member.is_company,
          id: member.id,
          fullname: (
            <Link to={"member-detail/" + member.id}>
              {member.first_name} {member.last_name}
            </Link>
          ),
          gender:
            member.gender == null
              ? "Unknown"
              : member.gender === 2
                ? "Female"
                : "Male",
          email: member.user_email,
          mobile: member.mobile_number == null ? "" : `+${member.country_code} ${0 + member.mobile_number}`,
          dob: member.dob,
          wallet_amount: member.wallet_amount,
          gift_wallet: member.gift_wallet,
        });
      }
      setMembers(temporary_members);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchMembers();
    }, 800)
    return () => clearTimeout(delayDebounceFn)
  }, [keyword])


  useEffect(() => {
    fetchMembers();
    console.log(members)
  }, [page, perPage]);

  const columns = [
    {
      name: "Fullname",
      selector: (row) => <span>{row.fullname} {row.is_company ? <i className="fas fa-building"></i> : null}</span>,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },

    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Date of birth",
      selector: (row) => row.dob,
    },
    {
      name: "Phone number",
      selector: (row) => row.mobile,
    },
    {
      name: "Wallet",
      selector: (row) => row.wallet_amount,
    },
    {
      name: "Gift",
      selector: (row) => row.gift_wallet,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Col xl={2} className="mb-3">
                    <Label
                      htmlFor="document_number"
                      className="col-md-6 col-form-label"
                    >
                      Search
                    </Label>
                    <Col md={10}>
                      <input
                        type="text"
                        className="form-control"
                        name="amount"
                        onChange={(e) => setKeyword(e.target.value)}
                        value={keyword}
                      />
                    </Col>
                  </Col>
                  <DataTable
                    pagination
                    paginationServer
                    columns={columns}
                    progressPending={isLoading}
                    progressComponent={<Loader />}
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    data={members}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllMembers;
