import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Loader from "../../components/Loader/Loader";
import {
  GET_MEMBERS,
  GET_TRANSACTIONS,
  POST_TRANSACTION,
  TRANSACTIONS,
} from "../../constants/api.constants";
import { transactions_types, wallet_types } from "../../data/selects.data";
import { del, get, patch, post, put } from "../../helpers/api_helper";
import TransactionsV4 from "./transactionsv4";
const conditionalRowStyles = [
  {
    when: (row) => row.is_archived === 1,
    style: (row) => ({
      backgroundColor: row.is_archived === 1 ? "pink" : "green",
    }),
  },
  {
    when: (row) => row.is_archived > 0,
    style: {
      backgroundColor: "#eaeaea94 !important",
      fontSize: "7px",
      height: "5px",
      "&:hover": {
        cursor: "pointer",
        fontSize: "12px",
      },
    },
  },
];
const columns = [
  {
    name: "Fullname",
    selector: (row) => row.fullname,
  },
  {
    name: "Description",
    selector: (row) => row.title,
    grow: 3,
  },

  {
    name: "Amount",
    selector: (row) => row.amount,
  },
  {
    name: "Payment ID",
    selector: (row) => row.payment_id,
  },
  {
    name: "Used Promo code",
    selector: (row) => row.promo_code,
  },
  {
    name: "Date",
    selector: (row) => row.date,
  },
  {
    name: ".",
    selector: (row) => row.actions,
  },
];
const Transaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [member, setMember] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [transactionType, setTransactionType] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [showSweetAlertDel, setShowSweetAlertDel] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [transactionModalInfo, setTransactionModalInfo] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [walletModalHistory, setWalletModalHistory] = useState(false);

  let { user_id } = useParams();
  toastr.options = {
    positionClass: "toast-bottom-left",
    closeButton: true,
    progressBar: true,
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  useEffect(() => {
    fetchTransactions();
    if (user_id) {
      fetchUserWallet();
    }
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [page, perPage]);

  function getBrowserAndOsInfo(userAgent) {
    const regex =
      /(firefox|chrome|safari|opera|edge|trident(?=\/))\/?\s*(\d+)/i;
    const match = userAgent.match(regex);
    if (!match) return "Unknown";
    const browser = match[1].toLowerCase();
    const version = match[2];
    let os = "Unknown";
    if (userAgent.indexOf("Windows") != -1) {
      os = "Windows";
    } else if (userAgent.indexOf("Mac") != -1) {
      os = "MacOS";
    } else if (userAgent.indexOf("Linux") != -1) {
      os = "Linux";
    } else if (userAgent.indexOf("iPhone") != -1) {
      os = "iOS";
    } else if (userAgent.indexOf("iPad") != -1) {
      os = "iPadOS";
    } else if (userAgent.indexOf("Android") != -1) {
      os = "Android";
    }
    return `${browser} ${version} on ${os}`;
  }

  const fetchUserWallet = async () => {
    try {
      const response = await get(GET_MEMBERS + "/member/" + user_id);
      console.log(response.member);
      setMember(response.member);
    } catch (error) {
      console.log(error);
      setMember(undefined);
    }
  };
  const fetchTransactions = async (show_loader = true) => {
    setIsLoading(show_loader);
    let url = user_id
      ? GET_TRANSACTIONS + "?user_id=" + user_id
      : GET_TRANSACTIONS;
    url += (user_id ? `&` : `?`) + `per_page=${perPage}&page=${page}`;
    url += transactionType ? `&transaction_type=${transactionType}` : "";
    try {
      const response = await get(url);
      let temp = [];
      for (let index = 0; index < response.transactions.length; index++) {
        const localTransaction = response.transactions[index];
        temp.push({
          ...localTransaction,
          logs: localTransaction.logs ? JSON.parse(localTransaction.logs) : "",
          payment_id: localTransaction.withdraw_id
            ? localTransaction.withdraw_id
            : localTransaction.payment_id,
          fullname: (
            <Link to={"/member-detail/" + localTransaction.user_id}>
              {" "}
              {localTransaction.first_name +
                " " +
                localTransaction.last_name}{" "}
            </Link>
          ),
          promo_code: localTransaction.promo_code,
          title: (
            <div className="custom-div-container">
              {localTransaction.ride_id ? (
                <Link to={"/ride-detail/" + localTransaction.ride_id}>
                  <p className="table-p">{localTransaction.message}</p>
                </Link>
              ) : (
                <p className="table-p">{localTransaction.message}</p>
              )}
              <p>{localTransaction.sub_message}</p>
            </div>
          ),
          amount: (
            <div
              className={
                localTransaction.type === 0
                  ? "text-warning"
                  : localTransaction.type === 1
                  ? "text-success"
                  : "text-danger"
              }
            >
              {localTransaction.amount > 0
                ? "+" + localTransaction.amount.toFixed(2)
                : localTransaction.amount.toFixed(2)}
            </div>
          ),
          actions: (
            <div>
              <button
                className="btn-table text-danger"
                onClick={() => {
                  console.log("clicked");
                  setTransaction(localTransaction);
                  setShowSweetAlertDel(true);
                }}
              >
                <i className="ri-delete-bin-2-line"></i>
              </button>
              <button
                className="btn-table"
                onClick={() => {
                  // transactionTemp = localTransaction;
                  setTransaction(localTransaction);
                  console.log(localTransaction);
                  setTransactionModal(true);
                }}
              >
                <i className="ri-pencil-fill"></i>
              </button>
              <button
                className="btn-table"
                onClick={() => {
                  // transactionTemp = localTransaction;
                  setTransaction({
                    ...localTransaction,
                    logs: JSON.parse(localTransaction?.logs),
                  });
                  console.log(localTransaction);
                  setTransactionModalInfo(true);
                }}
              >
                <i className="ri-information-line"></i>
              </button>
            </div>
          ),
        });
      }
      setTotalRows(response._pagination.items);
      console.log(temp);
      setTransactions(temp);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handle_on_change = (event, name = null) => {
    if (!event.target) {
      setTransaction({ ...transaction, [name]: event });
    } else {
      setTransaction({
        ...transaction,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handle_add_transaction = async () => {
    setButtonLoading(true);
    setShowSweetAlert(false);
    let postData = {
      ...transaction,
      user_id: user_id,
      transaction_type: transaction.transaction_type.value ?? "",
      wallet_type: transaction.wallet_type.value ?? "",
    };
    const config = {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
    console.log(postData);
    console.log(config);
    try {
      await post(POST_TRANSACTION, postData, config);
      setButtonLoading(false);
      toastr.success("Transaction added");
      fetchTransactions(false);
      setTransaction({
        transaction_type: 0,
        amount: "",
        wallet_type: 0,
        notes: "",
      });
      fetchUserWallet();
    } catch (error) {
      setButtonLoading(false);
      toastr.error("System Error");
      console.log(error);
    }
  };

  const handle_delete_transaction = async () => {
    setShowSweetAlertDel(false);
    try {
      await del(`${TRANSACTIONS}${transaction.id}`);
      fetchTransactions(false);
      toastr.success("Transaction deleted");
    } catch (error) {
      toastr.error("System Error");
      console.log(error);
    }
  };
  const handle_update_transaction = async () => {
    setTransactionModal(false);
    try {
      await put(`${TRANSACTIONS}${transaction.id}`, transaction);
      fetchTransactions(false);
      toastr.success("Transaction Updated");
    } catch (error) {
      toastr.error("System Error");
      console.log(error);
    }
  };
  const handle_update_wallet = async () => {
    console.log(wallets);
    setWalletModal(false);
    try {
      await patch(`${TRANSACTIONS}update-wallets/${user_id}`, wallets);
      fetchUserWallet();
      toastr.success("Wallets Updated");
    } catch (error) {
      toastr.error("System Error");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTransactions(true);
  }, [transactionType]);

  return (
    <React.Fragment>
      {showSweetAlertDel ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handle_delete_transaction()}
          onCancel={() => setShowSweetAlertDel(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : (
        ""
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Modal
            isOpen={transactionModal}
            toggle={() => setTransactionModal(!transactionModal)}
            centered={true}
          >
            <ModalHeader toggle={() => setTransactionModal(false)}>
              Edit Transaction
            </ModalHeader>
            <ModalBody>
              <Row className="mb-3">
                <Label
                  htmlFor="example-search-input"
                  className="col-md-2 col-form-label"
                >
                  Message
                </Label>
                <Col md={10}>
                  <Input
                    type="text"
                    defaultValue={transaction.message}
                    onChange={(e) => {
                      setTransaction({
                        ...transaction,
                        message: e.target.value,
                      });
                    }}
                    id="example-search-input"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="example-search-input"
                  className="col-md-2 col-form-label"
                >
                  Sub Message
                </Label>
                <Col md={10}>
                  <Input
                    type="text"
                    onChange={(e) => {
                      setTransaction({
                        ...transaction,
                        sub_message: e.target.value,
                      });
                    }}
                    defaultValue={transaction.sub_message}
                    id="example-search-input"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="example-search-input"
                  className="col-md-2 col-form-label"
                >
                  Amount
                </Label>
                <Col md={10}>
                  <Input
                    type="number"
                    onChange={(e) => {
                      setTransaction({
                        ...transaction,
                        amount: e.target.value,
                      });
                    }}
                    defaultValue={transaction.amount}
                    id="example-search-input"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="example-search-input"
                  className="col-md-2 col-form-label"
                >
                  Comments
                </Label>
                <Col md={10}>
                  <textarea
                    type="text"
                    onChange={(e) => {
                      setTransaction({
                        ...transaction,
                        comments: e.target.value,
                      });
                    }}
                    id="example-search-input"
                  />
                </Col>
              </Row>
              {/* <Row className="mb-3">
                <Label
                  htmlFor="example-search-input"
                  className="col-md-2 col-form-label"
                >
                  Type
                </Label>
                <Col md={10}>
                  <select
                    onChange={(e) => {
                      setTransaction({ ...transaction, type: e.target.value });
                    }}
                  >
                    <option value={1} selected={transaction.type === 1}>
                      Plus
                    </option>
                    <option value={-1} selected={transaction.type === -1}>
                      Minus
                    </option>
                  </select>
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="example-search-input"
                  className="col-md-2 col-form-label"
                >
                  Payment ID
                </Label>
                <Col md={10}>
                  <Input
                    type="number"
                    onChange={(e) => {
                      setTransaction({
                        ...transaction,
                        payment_id: e.target.value,
                      });
                    }}
                    defaultValue={transaction.payment_id}
                    id="example-search-input"
                  />
                </Col>
              </Row> */}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={() => setTransactionModal(false)}
                color="light"
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={() => handle_update_transaction()}
                className="waves-effect waves-light"
              >
                Save changes
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={transactionModalInfo}
            toggle={() => setTransactionModalInfo(!transactionModalInfo)}
            centered={true}
          >
            <ModalHeader toggle={() => setTransactionModalInfo(false)}>
              Transaction Info
            </ModalHeader>
            <ModalBody>
              Update history:
              <table class="table table-striped">
                <tr>
                  <th>Version</th>
                  <th>Transaction</th>
                  <th>Sub message</th>
                  <th>Amount</th>
                  <th>Admin</th>
                  <th>Comment</th>
                </tr>

                {transaction?.logs?.__v?.map((e) => {
                  return (
                    <tr>
                      <td>{e?.id}</td>
                      <td width={"34%"}>{e?.transaction?.message}</td>
                      <td>{e?.transaction?.sub_message}</td>
                      <td>{e?.transaction?.amount}</td>
                      <td>{e?.admin_id}</td>
                      <td>{e?.transaction.comments}</td>
                    </tr>
                  );
                })}
              </table>
              <div className="blockquote font-size-16 mb-0">
                <ul>
                  <ol>
                    <strong>Admin:</strong>
                    {transaction.logs?.admin_id}
                  </ol>
                  <ol>
                    <strong>From: </strong>
                    {transaction.logs?.userAgent
                      ? getBrowserAndOsInfo(transaction.logs?.userAgent)
                      : ""}
                  </ol>
                  <ol>
                    <strong>Comments:</strong>{" "}
                    {transaction.logs?.admin_comments}
                  </ol>
                </ul>
                {/* <footer className="blockquote-footer m-0">
                  Younes -<cite title="Source Title">20/11/2022 18:30</cite>
                </footer> */}
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={walletModal}
            toggle={() => setWalletModal(!walletModal)}
            centered={true}
          >
            <ModalHeader toggle={() => setWalletModal(false)}>
              Wallets update
            </ModalHeader>
            <ModalBody>
              <Row className="mb-3">
                <Label
                  htmlFor="example-search-input"
                  className="col-md-2 col-form-label"
                >
                  Real wallet
                </Label>
                <Col md={10}>
                  <Input
                    type="text"
                    defaultValue={wallets.wallet_amount}
                    onChange={(e) => {
                      setWallets({
                        ...wallets,
                        wallet_amount: e.target.value,
                      });
                    }}
                    id="example-search-input"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="example-search-input"
                  className="col-md-2 col-form-label"
                >
                  Gift wallet
                </Label>
                <Col md={10}>
                  <Input
                    type="text"
                    defaultValue={wallets.gift_wallet}
                    onChange={(e) => {
                      setWallets({
                        ...wallets,
                        gift_wallet: e.target.value,
                      });
                    }}
                    id="example-search-input"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="example-search-input"
                  className="col-md-2 col-form-label"
                >
                  Comments
                </Label>
                <Col md={10}>
                  <textarea
                    type="text"
                    onChange={(e) => {
                      setWallets({
                        ...wallets,
                        comments: e.target.value,
                      });
                    }}
                    id="example-search-input"
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={() => setWalletModal(false)}
                color="light"
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={() => handle_update_wallet()}
                className="waves-effect waves-light"
              >
                Save changes
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={walletModalHistory}
            toggle={() => setWalletModalHistory(!walletModalHistory)}
            centered={true}
          >
            <ModalHeader toggle={() => setWalletModalHistory(false)}>
              Wallet Logs
            </ModalHeader>
            <ModalBody>
              <table class="table table-striped">
                <tr>
                  <th>Version</th>
                  <th>Real wallet</th>
                  <th>Gift wallet</th>
                  <th>Admin</th>
                  <th>Comments</th>
                  <th>Date</th>
                </tr>
                <tbody>
                  {member?.wallets_logs
                    ? member?.wallets_logs?.__v?.map((e) => {
                        return (
                          <tr>
                            <td>{e?.id}</td>
                            <td>{e?.user?.wallet_amount}</td>
                            <td>{e?.user?.gift_wallet}</td>
                            <td>{e?.admin_id}</td>
                            <td>{e?.user?.comments}</td>
                            <td>{e?.user?.date}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
              {/* {
                JSON.parse(member?.wallets_logs)
               } */}
            </ModalBody>
          </Modal>
          <Row>
            {user_id ? (
              <div>
                <Col className="col-4">
                  <div style={{ marginLeft: 15 }}>
                    <CardBody>
                      <Row
                        className="custom-card"
                        style={{ backgroundColor: "white" }}
                      >
                        <Col xl={5}>
                          <p className="text-truncate font-size-13 mb-2">
                            Wallet
                          </p>
                          <h4 className="mb-2 card-title">
                            {member.wallet_amount
                              ? member.wallet_amount.toFixed(2)
                              : 0}{" "}
                            Dh
                          </h4>
                        </Col>

                        <Col xl={5} className="b-left">
                          <p className="text-truncate font-size-13 mb-2">
                            Gift
                          </p>
                          <h4 className="mb-2 card-title">
                            {member.gift_wallet
                              ? member.gift_wallet.toFixed(2)
                              : 0}{" "}
                            Dh
                          </h4>
                        </Col>
                        <Col xl={2}>
                          <button
                            className="btn-table"
                            onClick={() => {
                              // transactionTemp = localTransaction;
                              setWallets({
                                gift_wallet: member.gift_wallet
                                  ? member.gift_wallet.toFixed(2)
                                  : 0,
                                wallet_amount: member.wallet_amount
                                  ? member.wallet_amount.toFixed(2)
                                  : 0,
                              });
                              setWalletModal(true);
                            }}
                          >
                            <i className="ri-pencil-fill"></i>
                          </button>
                          <button
                            className="btn-table"
                            onClick={() => {
                              // transactionTemp = localTransaction;

                              setWalletModalHistory(true);
                            }}
                          >
                            <i className="ri-information-line"></i>
                          </button>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardBody>
                      <Row
                        className="custom-card"
                        style={{ backgroundColor: "white" }}
                      >
                        <Col xl={5}>
                          <p className="text-truncate font-size-13 mb-2">
                            Total Profit
                          </p>
                          <h4 className="mb-2 card-title">
                            +{parseFloat(member.total_profit).toFixed(2)} Dh
                          </h4>
                        </Col>
                      </Row>
                    </CardBody>
                  </div>
                </Col>
                <Col className="col-12">
                  <Card className="custom-card">
                    <CardBody>
                      <Row>
                        <Col xl={3} className="mb-3">
                          <Label
                            htmlFor="nationality"
                            className="col-md6 col-form-label"
                          >
                            Transaction
                          </Label>
                          <Col md={10}>
                            <Select
                              value={transaction.transactions_type}
                              onChange={(event) => {
                                handle_on_change(event, "transaction_type");
                              }}
                              name="transaction_type"
                              options={transactions_types}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                        </Col>
                        <Col xl={2} className="mb-3">
                          <Label
                            htmlFor="document_number"
                            className="col-md-6 col-form-label"
                          >
                            Amount
                          </Label>
                          <Col md={10}>
                            <input
                              type="number"
                              className="form-control"
                              name="amount"
                              onChange={handle_on_change}
                              value={transaction.amount}
                            />
                          </Col>
                        </Col>
                        <Col xl={2} className="mb-3">
                          <Label
                            htmlFor="nationality"
                            className="col-md6 col-form-label"
                          >
                            Type
                          </Label>
                          <Col md={10}>
                            <Select
                              value={transaction.wallet_type}
                              onChange={(event) => {
                                handle_on_change(event, "wallet_type");
                              }}
                              name="wallet_type"
                              options={wallet_types}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                        </Col>
                        <Col xl={3} className="mb-3">
                          <Label
                            htmlFor="document_number"
                            className="col-md-6 col-form-label"
                          >
                            Notes
                          </Label>
                          <Col md={10}>
                            <input
                              type="text"
                              className="form-control"
                              name="notes"
                              onChange={handle_on_change}
                              value={transaction.notes}
                            />
                          </Col>
                        </Col>
                        <Col xl={2} className="mb-3">
                          <Label
                            htmlFor="document_number"
                            className="col-md-6 col-form-label v-none text-white"
                          >
                            .
                          </Label>
                          <Col md={10}>
                            <Button
                              type="button"
                              color="success"
                              disabled={false}
                              className="waves-effect waves-light"
                              onClick={() => {
                                try {
                                  setShowSweetAlert(true);
                                } catch (e) {
                                  toastr.error("Check your inputs");
                                }
                              }}
                            >
                              {buttonLoading ? (
                                <Spinner color="light" size="sm">
                                  Load
                                </Spinner>
                              ) : (
                                "Add Transaction"
                              )}
                            </Button>
                            {showSweetAlert ? (
                              <SweetAlert
                                title="Are you sure?"
                                warning
                                showCancel
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                onConfirm={() => handle_add_transaction()}
                                onCancel={() => setShowSweetAlert(false)}
                              >
                                You won't be able to revert this!
                              </SweetAlert>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            ) : (
              ""
            )}
            <Col className="col-4">
              <CardBody>
                <Row>
                  <Col xl={12} className="mb-3">
                    <Label
                      htmlFor="nationality"
                      className="col-md6 col-form-label"
                    >
                      Filter
                    </Label>
                    <Col md={12}>
                      <Select
                        onChange={(event) => {
                          setTransactionType(event.value);
                        }}
                        name="transaction_type"
                        options={transactions_types}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4" style={{ textAlign: "center" }}>
                    <DataTable
                      pagination
                      paginationServer
                      columns={columns}
                      paginationRowsPerPageOptions={[10, 20, 50, 100]}
                      progressPending={isLoading}
                      progressComponent={<Loader />}
                      paginationTotalRows={totalRows}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                      conditionalRowStyles={conditionalRowStyles}
                      data={transactions}
                    />
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-12">
              <TransactionsV4 user={user_id} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Transaction;
