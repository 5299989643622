import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { GET_FRAUDE_NOTIFICATIONS, GET_OPPOSITES_RIDES_NOTIFICATIONS, GET_RESERVATIONS_NOTIFICATIONS, GET_RIDES_NOTIFICATIONS, GET_SIGNALEMENTS_NOTIFICATIONS, GET_UNCOMPLETED_PAYMENTS_NOTIFICATIONS } from '../constants/api.constants';
import { get } from '../helpers/api_helper';
import { updateFraudNotification, updateOppositesRidesNotification, updateReservationNotification, updateRidesNotification, updateSignalementsNotification, updateUncompletedPaymentNotification } from '../store/actions'; // Adjust the path if necessary

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {

    // const { sendMessage, lastMessage, readyState } = useWebSocket('ws://localhost:3035');
    const { sendMessage, lastMessage, readyState } = useWebSocket('wss://bo-api.pippipyala.com');
    const dispatch = useDispatch();
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (lastMessage !== null) {
            setMessage(JSON.parse(lastMessage.data));
        }
    }, [lastMessage]);

    useEffect(() => {
        if (message !== null) {
            switch (message.type) {
                case 'reservations_update':
                    dispatch(updateReservationNotification('newReservations', message.data));
                    break;
                case 'rides_update':
                    dispatch(updateRidesNotification('newRides', message.data));
                    break;
                case 'uncompletedPayment_update':
                    dispatch(updateUncompletedPaymentNotification('uncompletedPayment', message.data));
                    break;
                case 'opposites_update':
                    dispatch(updateOppositesRidesNotification('newOpposites', message.data));
                    break;
                case 'fraud_update':
                    dispatch(updateFraudNotification('newFraud', message.data));
                    break;
                case 'signalements_update':
                    dispatch(updateSignalementsNotification('newSignalements', message.data));
                    break;
                default:
                    break;
            }
        }
    }, [message, dispatch]);

    useEffect(() => {
        fetchNewUncompletedPayments();
        fetchNewOppositesRides();
        fetchNewSignalements();
        fetchNewReservations();
        fetchNewRides();
        fetchNewDetectedFraud();
    }, []);

    const fetchNewReservations = async () => {
        try {
            const response = await get(GET_RESERVATIONS_NOTIFICATIONS);
            if (response && response.reservations_not) {
                dispatch(updateReservationNotification('newReservations', response.reservations_not));
            }
        } catch (error) {
            console.log('Error fetching reservations notifications:', error);
        }
    };

    const fetchNewRides = async () => {
        try {
            const response = await get(GET_RIDES_NOTIFICATIONS);
            if (response && response.rides_not) {
                dispatch(updateRidesNotification('newRides', response.rides_not));
            }
        } catch (error) {
            console.log('Error fetching rides notifications:', error);
        }
    };

    const fetchNewOppositesRides = async () => {
        try {
            const response = await get(GET_OPPOSITES_RIDES_NOTIFICATIONS);
            if (response && response.opposites_rides_not) {
                dispatch(updateOppositesRidesNotification('newOpposites', response.opposites_rides_not));
            }
        } catch (error) {
            console.log('Error fetching opposites rides notifications:', error);
        }
    };

    const fetchNewDetectedFraud = async () => {
        try {
            const response = await get(GET_FRAUDE_NOTIFICATIONS);
            console.log('fraud not fetching ...');
            if (response && response.fraud_not) {
                console.log('fraud not : ', response.fraud_not)
                dispatch(updateFraudNotification('newFraud', response.fraud_not))
            }
            else {
                console.log('response fr : ', response)
            }
        } catch (error) {
            console.log('Error fetching fraud notifications:', error);
        }
    }

    const fetchNewSignalements = async () => {
        try {
            const response = await get(GET_SIGNALEMENTS_NOTIFICATIONS);
            console.log('signalament notif')
            if (response && response.signalements_not) {
                console.log('signalements not : ', response.signalements_not)
                dispatch(updateSignalementsNotification('newSignalements', response.signalements_not))
            }
            else {
                console.log('response fr : ', response)
            }
        } catch (error) {
            console.log('Error fetching signalements notifications:', error);
        }
    }

    const fetchNewUncompletedPayments = async () => {
        try {
            const response = await get(GET_UNCOMPLETED_PAYMENTS_NOTIFICATIONS);

            if (response && response.uncompleted_payments_not) {
                console.log('up not : ', response.uncompleted_payments_not)
                dispatch(updateSignalementsNotification('uncompletedPayment', response.uncompleted_payments_not))
            }
            else {
                console.log('response fr : ', response)
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <WebSocketContext.Provider value={{ sendMessage, message, readyState }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocketContext = () => useContext(WebSocketContext);
