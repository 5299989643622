import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const DashedLine = ({ stats }) => {
  const [series, setSeries] = useState([
    {
      name: "Commissions",
      data: stats.turnover.map((turnover) => turnover.profit),
    },
    {
      name: "Pénalités",
      data: stats.penalties.map((penalty) => penalty.amount),
    },
  ]);
  const [options, setOptions] = useState({
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#556ee6", "#34c38f"],
    xaxis: {
      type: "datetime",
      categories: stats.turnover.map((turnover) => turnover.date),
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  });

  useEffect(() => {
    console.log(stats.turnover.map((turnover) => turnover.profit));
    console.log(stats.turnover.map((turnover) => turnover.date));
  }, []);

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="350"
      />
    </React.Fragment>
  );
};

export default DashedLine;
