import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Form,
    FormFeedback,
    FormGroup,
    Label,
    Modal, ModalBody,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap'
import { z } from 'zod'
import { BASE_URL } from '../../constants/api.constants'
import { get, post, put } from '../../helpers/api_helper'


const schema = z.object({
    title: z.string().min(1, "Title is required"),
    image: z.string().optional(),
    content: z.string().min(1, "Content is required"),
    slogan: z.string().min(1, "Slogan is required"),
    meta_title: z.string().min(1, "Meta title is required"),
    meta_desc: z.string().min(1, "Meta description is required"),
    published: z.boolean().default(true),
})

const BlogForm = () => {
    const { id } = useParams();
    const history = useHistory();

    const { data, isLoading } = useQuery({
        queryKey: ['blog', id],
        queryFn: () => get(`${BASE_URL}blogs/${id}`),
        enabled: !!id
    })

    if (isLoading) {
        return <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
            <Spinner />
        </div>
    }

    return (
        <Container className="mt-5">
            <Card>
                <CardBody>
                    <CardTitle tag="h2" className="mb-4">
                        {id ? 'Edit Blog' : 'Create New Blog'}
                    </CardTitle>
                    <BlogFormInputs blogData={data} id={id} history={history} />
                </CardBody>
            </Card>
        </Container>
    );
};

export default BlogForm;


const BlogFormInputs = ({ blogData, id, history }) => {
    console.log(blogData)
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: zodResolver(schema),
        defaultValues: blogData || {}
    });

    const onSubmit = async (data) => {
        if (id) {
            await put(`${BASE_URL}blogs/${id}`, data);
        } else {
            await post(`${BASE_URL}blogs`, data);
        }
        history.push('/blogs');
    };

    const [previewModal, setPreviewModal] = useState(null)

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} className='tw-py-10'>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="title">Title</Label>
                            <input
                                type="text"
                                id="title"
                                {...register('title')}
                                invalid={!!errors.title}
                                className="form-control"

                            />
                            {errors.title && <FormFeedback>{errors.title.message}</FormFeedback>}
                        </FormGroup>
                    </Col>
                    <Col md={6} >
                        <FormGroup>
                            <Label for="image">Image URL</Label>
                            <input className="form-control" type="text" id="image" {...register('image')} />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <div className='d-flex justify-content-between pb-2'>
                        <Label for="content">Content</Label>
                        <Button color="primary" onClick={() => setPreviewModal(watch('content'))}>Preview</Button>
                    </div>
                    <textarea
                        id="content"
                        rows={17}
                        {...register('content')}
                        invalid={!!errors.content}
                        className="form-control"
                    />
                    {errors.content && <FormFeedback>{errors.content.message}</FormFeedback>}
                </FormGroup>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="slogan">Slogan</Label>
                            <input
                                type="text"
                                id="slogan"
                                {...register('slogan')}
                                invalid={!!errors.slogan}
                                className="form-control"
                            />
                            {errors.slogan && <FormFeedback>{errors.slogan.message}</FormFeedback>}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="meta_title">Meta Title</Label>
                            <input
                                type="text"
                                id="meta_title"
                                {...register('meta_title')}
                                invalid={!!errors.meta_title}
                                className="form-control"
                            />
                            {errors.meta_title && <FormFeedback>{errors.meta_title.message}</FormFeedback>}
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label for="meta_desc">Meta Description</Label>
                    <input
                        type="textarea"
                        id="meta_desc"
                        {...register('meta_desc')}
                        invalid={!!errors.meta_desc}
                        className="form-control"
                    />
                    {errors.meta_desc && <FormFeedback>{errors.meta_desc.message}</FormFeedback>}
                </FormGroup>
                <FormGroup check className="mb-3">
                    <Label check>
                        <input type="checkbox" {...register('published')} />{' '}
                        Published
                    </Label>
                </FormGroup>
                <Button color="primary" type="submit">
                    {id ? 'Update' : 'Create'} Blog
                </Button>
            </Form>
            <Modal isOpen={!!previewModal} toggle={() => setPreviewModal(null)} size="lg">
                <ModalHeader toggle={() => setPreviewModal(null)}>Preview</ModalHeader>
                <ModalBody>
                    {/* Render the HTML content safely */}
                    <div dangerouslySetInnerHTML={{ __html: previewModal || '' }} />
                </ModalBody>
            </Modal>
        </>
    );
};