import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { get, post } from "../../helpers/api_helper";
import { Link } from "react-router-dom";
import {
  transactionS, TRANSACTIONS,
} from "../../constants/api.constants";
import DataTable from "react-data-table-component"; 
import Loader from "../../components/Loader/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import Cookies from "js-cookie";
import { forbiddenWords } from "../../data/forbidden.words";
// import TransactionsV4 from "./transactionsv4";

const UncompletedPayments = () => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [showSweetAlertRAS, setShowSweetAlertRAS] = useState(false);
  const [transaction, settransaction] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [newTransactions, setNewTransactions] = useState(0);
  toastr.options = {
    positionClass: "toast-bottom-left",
    closeButton: true,
    progressBar: true,
  };

  // const handle_save_fraud = async () => {
  //   setShowSweetAlert(false);
  //   const config = {
  //     headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  //   };
  //   const data = {
  //     user_id: transaction.user_id,
  //     detected_from: "transaction",
  //     ride_id: transaction.ride_id,
  //     transaction_id: transaction.transaction_id,
  //   };
  //   const response = await post(`${transactionS}save-fraud`, data, config);
  //   if (response.message) {
  //     let newtransactions = [...transactions];
  //     const indexToUpdate = newtransactions.findIndex(
  //       (obj) => obj.transaction_id === transaction.transaction_id
  //     );
  //     console.log(indexToUpdate);
  //     if (indexToUpdate !== -1) {
  //       newtransactions[indexToUpdate] = get_html({ ...transaction, is_fraud: 1 });
  //     }
  //     settransactions(newtransactions);
  //   }
  // };


  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  const handleChangeStatus = async (status, id) => {
    try {
      const response = await post(
        `${TRANSACTIONS}payments-attemps`, {pr_id: id, val: status}
      );
      fetchTransactions();
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [page, perPage]);

  const fetchTransactions = async (show_loader = true) => {
    setIsLoading(show_loader);
    try {

      const response = await get(
        `${TRANSACTIONS}payments-attempts?per_page=${perPage}&page=${page}`,
      );
      let temp = [];
      for (let index = 0; index < response.transactions.length; index++) {
        const transaction = response.transactions[index];
        temp.push(get_html(transaction));
      }
      setTotalRows(response._pagination.items);
      setTransactions(temp);
      setIsLoading(false);
      // const newCount = temp.filter(transaction => !transaction.uph_status).length;
      // const calledC = temp.filter(transaction => transaction.uph_status === 2).length;
      
    } catch (error) {
      console.log(error);
    }
  };

  const get_html = (transaction) => {
    return {
      ...transaction,
      fullname: (
        <Link to={"/member-detail/" + transaction.user_id}>
          {" "}
          {transaction.first_name + " " + transaction.last_name}{" "}
        </Link>
      ),
      amount: (
        <div className="custom-div-container">
            {transaction.amount} MAD
        </div>
      ),
      gateway: (
        <div className="custom-div-container">
            {transaction.gateway === 0 ? 'CMI' : (transaction.gateway === 1 ? 'Cashplus' : 'Tashilat')}
        </div>
      ),
      wallets: (
        <div className="custom-div-container" style={{textAlign: 'left'}}>

            <li> Gift: {transaction.gift_wallet} MAD</li>
            <li> Real: {transaction.real_wallet} MAD</li>
            <li> Cmi: {transaction.cmi} MAD</li>
          
        </div>
      ),
      date: <p>{transaction.date}</p>, 
      ride: (
        <div className="custom-div-container">
          {transaction.ride_id ?  <Link to={"/ride-detail/" + transaction.ride_id}>
            View Ride
          </Link> : ''}
        </div>
      ),
      actions: (transaction?.uph_status !== 2 ? (
        <select
          className={`${transaction?.uph_status  === 1 ? 'custom-select-not-responding' : 'custom-select' }`}
          onChange={(e) => {
            handleChangeStatus(e.target.value, transaction.id);
          }}
        >
          <option disabled selected={!transaction.uph_status}>
            New
          </option>
          <option
            value="1"
            selected={transaction?.uph_status === 1}
          >
            Not responding
          </option>
          <option
            value="2"
            selected={transaction?.uph_status === 2}
          >
            Called
          </option>
        </select>
      ) : (
        <span class="badge bg-primary">Called ✓</span>
      ))
    };
  };

  const columns = [
    {
      name: "Fullname",
      selector: (row) => row.fullname,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,

    },
    {
      name: "Gateway",
      selector: (row) => row.gateway,

    },
    {
      name: "Wallets",
      selector: (row) => row.wallets,
      columns: 3
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Ride",
      selector: (row) => row.ride,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                {/* {showSweetAlert ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => handle_save_fraud()}
                    onCancel={() => setShowSweetAlert(false)}
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : (
                  ""
                )}
                {showSweetAlertRAS ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => handle_ignore_fraud()}
                    onCancel={() => setShowSweetAlertRAS(false)}
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : (
                  ""
                )} */}
                <CardBody>
                  <CardTitle className="h4" style={{ textAlign: "center" }}>
                    <DataTable
                      pagination
                      paginationServer
                      columns={columns}
                      paginationRowsPerPageOptions={[10, 20, 50, 100]}
                      progressPending={isLoading}
                      progressComponent={<Loader />}
                      paginationTotalRows={totalRows}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                      data={transactions}
                    />
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UncompletedPayments;

const HighlightForbiddenWords = ({ text }) => {
  let words = text.split(" ");

  const isPhoneNumber = (word) => /^\d{10}$/.test(word);

  words = words.map((word) => {
    if (forbiddenWords.includes(word) || isPhoneNumber(word)) {
      return <mark key={word}>{word}</mark>;
    } else {
      return word;
    }
  });

  return (
    <p>
      {words.map((word, index) => (
        <React.Fragment key={index}>{word} </React.Fragment>
      ))}
    </p>
  );
};
