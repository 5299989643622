import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const DataListComponent = ({ data }) => {
    const [showModal, setShowModal] = useState(false);
    const [visibleItems, setVisibleItems] = useState(3);

    const handleViewAll = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowMore = () => {
        setVisibleItems(data.length);
    };

    const renderDataItems = () => {
        const itemsToShow = data.slice(0, visibleItems);
        return itemsToShow.map((e, index) => <div key={index} className="user-comment-history">
            <p>{e.comment}</p>
            <i>
                {" "}
                - {e.firstname + " " + e.lastname} at{" "}
                {e.created_date}
            </i>
        </div>);
    };

    return (
        <div>
            {renderDataItems()}
            {data.length > visibleItems && (
                <Button onClick={handleViewAll}>View All</Button>
            )}

            {/* {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={handleCloseModal}>
                            &times;
                        </span>
                        <h2>All Items:</h2>

                        <button onClick={handleShowMore}>Show More</button>
                    </div>
                </div>
            )} */}

            <Modal
                isOpen={showModal}
                toggle={handleCloseModal}
            >
                <ModalHeader
                    toggle={handleCloseModal}
                >
                    User Comments history
                </ModalHeader>
                <ModalBody>
                    {data.map((e, index) => (
                        <div key={index} className="user-comment-history">
                            <p>{e.comment}</p>
                            <i>
                                {" "}
                                - {e.firstname + " " + e.lastname} at{" "}
                                {e.created_date}
                            </i>
                        </div>
                    ))}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default DataListComponent;
