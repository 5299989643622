const { ASSETS } = require("../constants/api.constants");
const { banksList } = require("../data/banks.data");
exports.getImageLink = (name) => {
  if (name) {
    if (name.includes("undefined")) {
      return ASSETS + "images/users/user_default_pic.svg";
    } else {
      return ASSETS + name;
    }
  } else {
    return ASSETS + "images/users/user_default_pic.svg";
  }
};

exports.detectBankName = (rib) => {
  const bankList = banksList;

  let bankId = rib.substring(0, 3);
  let bank = bankList.find((bank) => bank.bank_code === bankId);

  if (!bank) {
    bankId = rib.substring(0, 2);
    bank = bankList.find((bank) => bank.bank_code === bankId);
  }

  if (!bank) {
    bankId = rib.substring(0, 1);
    bank = bankList.find((bank) => bank.bank_code === bankId);
  }

  if (!bank) {
    return "⚠️";
  }

  return bank.bank_name;
};

exports.convertDate = (dateStr) => {
  let date = new Date(dateStr);
  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

exports.timeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return `${interval} year${interval > 1 ? "s" : ""} ago`;
  }

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return `${interval} month${interval > 1 ? "s" : ""} ago`;
  }

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return `${interval} day${interval > 1 ? "s" : ""} ago`;
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `${interval} hour${interval > 1 ? "s" : ""} ago`;
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return `${interval} minute${interval > 1 ? "s" : ""} ago`;
  }

  return "Just now";
};

exports.censorWords = (text, forbiddenWords) => {
  let newTxt = text;
  console.log(forbiddenWords);
  forbiddenWords.forEach(function (item) {
    if (newTxt.includes(item.word)) {
      newTxt = newTxt.replace(item.word, "*");
    }
  });
  return newTxt;
};
