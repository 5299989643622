import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Card
import CardShop from "./CardShop";
import { getShops } from "../../../store/e-commerce/actions";

class RidesDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Ecommerce", link: "#" },
        { title: "Shop", link: "#" },
      ],


      series: [{
        name: '2020',
        type: 'line',
        data: [1000, 1001, 3005, 2007, 4003, 2012, 1047, 3041, 2122, 2232, 12, 16]
       }, {
        name: '2021',
        type: 'line',
        data: [2000, 2045, 2997, 3001, 2032, 2002, 2657, 2828, 2232, 301, 2121, 1551]
       }
        , {
        name: '2022',
        type: 'line',
        data: [1000, 3022, 1222, 3349, 2447, 3552, 4777, 3458, 232, 3550, 2001, 1800]
      }],
      options: {
        chart: {
          toolbar: {
            show: false,
          }
        },
        stroke: {
          width: [0, 3],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            vertical: false,
            columnWidth: '20%',
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        colors: ['#5664d2', '#1cbb8c', "#f46a6a"],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      }
    };
  }


  componentDidMount() {
    const { onGetShops } = this.props;
    onGetShops();
  }

  render() {
    const { shops } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Ecommerce"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              {map(shops, (shop, key) => (
                <CardShop shop={shop} key={"_shop_" + key} />
              ))}
            </Row>
            <Row>
              <Col xl={12}>
                <div className="text-center my-3">
                  <Link to="#" className="text-primary">
                    <i className="mdi mdi-loading mdi-spin font-size-20 align-middle me-2"></i>{" "}
                    Load more{" "}
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

RidesDashboard.propTypes = {
  shops: PropTypes.array,
  onGetShops: PropTypes.func,
};

const mapStateToProps = ({ Ecommerce }) => ({
  shops: Ecommerce.shops,
});

const mapDispatchToProps = (dispatch) => ({
  onGetShops: () => dispatch(getShops()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RidesDashboard);
