import React from "react"
import { Link } from "react-router-dom"
import { Row } from "reactstrap"

const RideColumns = () => [
  {
    dataField: "id",
    text: "iD",
    hidden:true,
    sort: true,
    
  },
  {
    dataField: "image",
    text: "",
    
  },
  {
    dataField: "driverName",
    text: "Driver Name",
    sort: true,
    
  },
  {
    dataField: "ride",
    text: "Ride",
    // sort: true,
  },
  {
    dataField: "price",
    text: "Price",
    // sort: true,
  },
  {
    dataField: "departureDate",
    text: "Departure Date",
    // sort: true,
  },
  {
    dataField: "seats",
    text: "Available Seats",
    // sort: true,
  },
  {
    dataField: "pending",
    text: "Pending",
    // sort: true,
  },
  {
    dataField: "preapproved",
    text: "Pre-approved",
    // sort: true,
  },
  
  {
    dataField: "status",
    text: "Status",
    // sort: true,
  },
  
  
  // {
  //   dataField: "menu",
  //   isDummyField: true,
  //   text: "Action",
  //   formatter: () => (
  //     <>
  //       {/* <Link to="#" className="me-3 "><i className="ri-settings-2-line font-size-18"></i></Link> */}
  //       <Link to="/ride-detail" className='text-secondary' ><i className=" ri-arrow-down-s-fill font-size-18"></i></Link>
  //       <Link to="/ride-detail" className='text-primary' ><i className="fas fa-eye font-size-18"></i></Link>
  //     </>
  //   ),
  // },
]

export default RideColumns
