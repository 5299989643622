// UNCOMPELTED PAYMENTS 
export const UPDATE_UNCOMPLETED_PAYMENT = 'UPDATE_UNCOMPLETED_PAYMENT';
export const HIDE_UNCOMPLETED_PAYMENT = 'HIDE_UNCOMPLETED_PAYMENT';

// RESERVATIONS
export const UPDATE_RESERVATIONS = 'UPDATE_RESERVATIONS';
export const HIDE_RESERVATIONS = 'HIDE_RESERVATIONS';

// RIDES
export const UPDATE_RIDES = 'UPDATE_RIDES';
export const HIDE_RIDES = 'HIDE_RIDES';

// OPPOSITES RIDES
export const UPDATE_OPPOSITES_RIDES = 'UPDATE_OPPOSITES_RIDES';
export const HIDE_OPPOSITES_RIDES = 'HIDE_OPPOSITES_RIDES';

// FRAUD
export const UPDATE_FRAUD = 'UPDATE_FRAUD';
export const HIDE_FRAUD = 'HIDE_FRAUD';

// SIGNALEMENTS
export const UPDATE_SIGNALEMENTS = 'UPDATE_SIGNALEMENTS'
export const HIDE_SIGNALEMENTS = 'HIDE_SIGNALEMENTS'