import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdEdit } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { Button, Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import { queryClient } from "../..";
import Loader from "../../components/Loader/Loader";
import { BASE_URL } from "../../constants/api.constants";
import { del, get } from "../../helpers/api_helper";
import useDate from "../../helpers/date";

const Blogs = () => {
    //const partner = useParams().partner;

    const { dayjs } = useDate();

    const [page, setPage] = useState(0);
    const [showHistory, setShowHistory] = useState(undefined);
    const [perPage, setPerPage] = useState(10);
    const [dataPage, setData] = useState([]);
    const { data, isLoading } = useQuery({
        queryKey: ["blogs"],

        queryFn: () => get(`${BASE_URL}blogs`),
    });

    const { mutate: deleteBlog } = useMutation({
        mutationFn: (id) => del(`${BASE_URL}blogs/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["blogs"] });
        },
    });

    const [deleteModal, setDeleteModal] = useState({ isOpen: false, blogId: null });

    const toggleDeleteModal = (blogId = null) => {
        setDeleteModal({ isOpen: !deleteModal.isOpen, blogId });
    };

    const confirmDelete = () => {
        if (deleteModal.blogId) {
            deleteBlog(deleteModal.blogId);
            toggleDeleteModal();
        }
    };

    useEffect(() => {
        if (data) {
            const start = perPage * page;
            const end = start + perPage;
            setData(data.slice(start, end));
        }
    }, [data, page, perPage]);

    const columns = [
        // {
        //     name: 'ID',
        //     selector: row => row.id,
        // },
        {
            name: "Image",
            selector: (row) => (
                <div style={{ width: "80px", height: "60px", borderRadius: "10px" }}>
                    <img
                        src={row.image}

                        style={{ width: "100%", height: "100%", borderRadius: "50%", objectFit: "cover" }}
                    />
                </div>

            ),
            width: "110px",
        },
        {
            name: "Title",
            selector: (row) => (
                <span className="font-bold">{row.title}</span>
            ),

            width: "250px",
        },

        {
            name: "Slogan",
            selector: (row) =>
                <span className="text-primary tw-text-sm">{row.slogan}</span>,
            width: "250px",
        },

        {
            name: "Last update",
            selector: (row) =>
                row.updated_at
                    ? `${dayjs(row.updated_at).fromNow()}`
                    : "--",
            width: "130px",
        },
        {
            name: "Created at",
            selector: (row) =>
                row.created_at
                    ? `${dayjs(row.created_at).format(
                        "lll"
                    )}`
                    : "--",
            width: "200px",
        },
        {
            name: "",
            selector: (row) => (
                <div className="flex gap-2 items-center">

                    <NavLink to={`/blogs/${row.id}`}>
                        <button

                            className="btn btn-primary btn-sm flex items-center gap-2"
                        >
                            <MdEdit className="text-xl" />
                            Edit
                        </button>
                    </NavLink>
                    <button
                        // disabled={isUpdating}
                        // onClick={() => mutate({ id: row.id, data: { isValid: 0 } })}
                        className="btn btn-danger btn-sm"
                        onClick={() => toggleDeleteModal(row.id)}
                    >
                        Delete
                    </button>

                </div>
            ),
            width: "200px",
        },

    ];
    const handlePageChange = (page) => {
        setPage(page - 1);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };
    return (
        <div
            style={{
                marginTop: "90px",
                marginLeft: "20px",
                marginRight: "20px",
            }}
        >
            <Modal
                size="lg"
                isOpen={!!showHistory}
                toggle={() => setShowHistory(undefined)}
            >
                <ModalHeader toggle={() => setShowHistory(undefined)}>
                    Modal title
                </ModalHeader>
                <ModalBody>
                    <ModalUserPromo promoCode={showHistory} />
                </ModalBody>
            </Modal>
            <Modal isOpen={deleteModal.isOpen} toggle={() => toggleDeleteModal()}>
                <ModalHeader toggle={() => toggleDeleteModal()}>Confirm Delete</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this blog?
                    <div className="d-flex justify-content-end mt-3">
                        <Button color="secondary" onClick={() => toggleDeleteModal()}>Cancel</Button>
                        <Button color="danger" onClick={confirmDelete} className="ml-2">Delete</Button>
                    </div>
                </ModalBody>
            </Modal>
            <Card className="custom-card">
                <div className="flex justify-end w-full">
                    <NavLink to="/blogs/new" className="btn btn-primary">Add Blog</NavLink>
                </div>
                <CardBody>
                    <DataTable
                        pagination
                        paginationServer
                        columns={columns}
                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                        progressPending={isLoading}
                        progressComponent={<Loader />}
                        paginationTotalRows={data?.length ?? 0}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        data={dataPage ?? []}
                    />
                </CardBody>
            </Card>
        </div>
    );
};

export default Blogs;

const ModalUserPromo = ({ promoCode }) => {
    const { dayjs } = useDate();
    const { data, isLoading } = useQuery({
        queryKey: ["codePromo", "users", promoCode],
        queryFn: () => get(`${BASE_URL}/code-promo/users/${promoCode}`),
        enabled: !!promoCode,
    });

    return (
        <DataTable
            noHeader
            columns={[
                {
                    name: "User",
                    selector: (row) => (
                        <a href={`/member-detail/${row.id}`}>
                            {row.first_name + " " + row.last_name}
                        </a>
                    ),
                },

                {
                    name: "Amount saved",
                    selector: (row) => row.total_amount_deducted + " MAD",
                    grow: 0.6,
                },
                {
                    name: "Last usage",
                    selector: (row) =>
                        `${dayjs(row.last_used).fromNow()} (${dayjs(row.last_used).format(
                            "LLL"
                        )})`,
                },
            ]}
            progressPending={isLoading}
            data={data ?? []}
        />
    );
};
