import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const useDate = () => {
  return {
    format: (date) => dayjs(date).format,
    dayjs,
  };
};

export default useDate;
