import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";
import { Link } from 'react-router-dom';

class AlertCities extends Component {

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            {/* <ButtonGroup className="mb-2 font-size-16">
                                <Button size="sm" color="light" type="button">Today</Button>
                                <Button size="sm" color="light" active type="button">Weekly</Button>
                                <Button size="sm" color="light" type="button">Monthly</Button>
                            </ButtonGroup> */}
                        </div>
                        <h4 className="card-title mb-4">Top 5 cities with alert</h4>
                        <ul className="list-unstyled megamenu-list">
                            <li>
                                <Row className="flex-1 align-items-center justify-content-space-between">
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> City Name</p>
                                    </Col>
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> Count</p>
                                    </Col>
                                </Row>
                            </li>
                            <li>
                                <Row className="flex-1 align-items-center justify-content-space-between">
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> Agadir</p>
                                    </Col>
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> 325</p>
                                    </Col>
                                </Row>
                            </li>
                            <li>
                                <Row className="flex-1 align-items-center justify-content-space-between">
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> Casablanca</p>
                                    </Col>
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> 200</p>
                                    </Col>
                                </Row>
                            </li>
                            <li>
                                <Row className="flex-1 align-items-center justify-content-space-between">
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16  text-truncate"> Casablanca</p>
                                    </Col>
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> 150</p>
                                    </Col>
                                </Row>
                            </li>
                            <li>
                                <Row className="flex-1 align-items-center justify-content-space-between">
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> Fes</p>
                                    </Col>
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> 100</p>
                                    </Col>
                                </Row>
                            </li>
                            <li>
                                <Row className="flex-1 align-items-center justify-content-space-between">
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> Casablanca</p>
                                    </Col>
                                    <Col xl={6}>
                                        <p className="mb-2 font-size-16 text-truncate"> 500</p>
                                    </Col>
                                </Row>
                            </li>

                        </ul>
                        {/* <div>
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                                 <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="280" />
                            </div>
                        </div> */}
                    </CardBody>

                    {/* <CardBody className="border-top text-center">
                        <Row>
                         

                        
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 font-size-16 text-truncate"><i className="mdi mdi-circle text-warning font-size-10 me-1"></i> Total members</p>
                                    
                                </div>
                     
                        </Row>
                    </CardBody> */}
                </Card>
            </React.Fragment>
        );
    }
}

export default AlertCities;