exports.banksList = [
  { bank_id: "1", bank_code: "011", bank_name: "BMCE" },
  { bank_id: "1", bank_code: "11", bank_name: "BMCE" },
  { bank_id: "2", bank_code: "13", bank_name: "BMCI" },
  { bank_id: "3", bank_code: "21", bank_name: "Crédit du Maroc" },
  { bank_id: "4", bank_code: "230", bank_name: "CIH" },
  { bank_id: "5", bank_code: "22", bank_name: "SGMB" },
  { bank_id: "6", bank_code: "7", bank_name: "AWB" },
  { bank_id: "7", bank_code: "190", bank_name: "BP" },
  { bank_id: "8", bank_code: "350", bank_name: "Barid Bank" },
  { bank_id: "9", bank_code: "225", bank_name: "Crédit agricole" },
  { bank_id: "10", bank_code: "181", bank_name: "BP" },
  { bank_id: "11", bank_code: "127", bank_name: "BP" },
  { bank_id: "12", bank_code: "28", bank_name: "CITY BANK" },
  { bank_id: "12", bank_code: "007", bank_name: "Attijari Wafa" },
  { bank_id: "13", bank_code: "50", bank_name: "CFG" },
  { bank_id: "14", bank_code: "360", bank_name: "Umnia bank" },
  { bank_id: "15", bank_code: "361", bank_name: "Bank najmah" },
  { bank_id: "16", bank_code: "362", bank_name: "Bank assafa" },
  { bank_id: "17", bank_code: "363", bank_name: "Bank al amane" },
  { bank_id: "18", bank_code: "364", bank_name: "Bank arreda" },
  { bank_id: "19", bank_code: "365", bank_name: "Al akhdar bank" },
  { bank_id: "20", bank_code: "366", bank_name: "Bank al youssr" },
  { bank_id: "21", bank_code: "367", bank_name: "Bti bank" },
  { bank_id: "22", bank_code: "145", bank_name: "BP" },
  { bank_id: "23", bank_code: "101", bank_name: "BP" },
  { bank_id: "24", bank_code: "164", bank_name: "BP" },
  { bank_id: "24", bank_code: "050", bank_name: "CFG" },
  { bank_id: "24", bank_code: "013", bank_name: "BMCI" },
  { bank_id: "24", bank_code: "021", bank_name: "CDM" },
  { bank_id: "24", bank_code: "022", bank_name: "SGMB" },
];
