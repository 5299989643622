import Cookies from "js-cookie";
import React,{useState,useEffect} from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import { CHATS } from "../../../constants/api.constants";
import { post } from "../../../helpers/api_helper";
import { getImageLink } from "../../../helpers/utils";

export default function ChatDialog({toggle_modal,reservation,dialog_open}) {
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getChats = async () => {
    setIsLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${  Cookies.get("token")}` },
    };
    try {
      let chatsData = await post(CHATS, { reservation_id: reservation.reservation_id,}, config );
      console.log(chatsData.chats);
      setChats(chatsData.chats);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    getChats();
  }, [])
  
  return (
    <Modal isOpen={dialog_open} centered={true} className="modal-chat">
      <ModalHeader toggle={toggle_modal}>
        Chat
      </ModalHeader>
      
      <ModalBody>
       { 
       isLoading ? (<Loader />)
       : 
       <div className="chat-container">
       {chats.map((chat) => {
        return (
          <div className={'chat-dialog ' + (chat.is_driver === 1 ? 'chat-owner' : '')}>
          {chat.is_driver === 0 ? (
            <div className="avatar">
            <img
              src={getImageLink((chat.is_driver === 0 ? chat.from_picture : chat.to_picture))}
              alt="img"
            />
          </div>
          ) : ''} 
          <div className="chat-detail">
            <h5>{chat.from_fname + ' ' + chat.from_lname}</h5>
            <p>
              {chat.msg}
            </p>
            <span className="date">{chat.send_date}</span>
          </div>
          {chat.is_driver === 1 ? (
            <div className="avatar">
            <img
              src={getImageLink((chat.is_driver === 1 ? chat.from_picture : chat.to_picture))}
              alt="img"
            />
          </div>
          ) : ''} 
        </div>
        )
       })}
        
        {/* <div className="chat-dialog chat-owner">
          <div className="chat-detail">
            <h5>Yuniss obr</h5>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam
              nihil soluta nam facilis consequuntur asperiores doloribus
              blanditiis, odio ex eaque?
            </p>
            <span className="date">07/12/2022 19:19:05</span>
          </div>
          <div className="avatar">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
              alt="img"
            />
          </div>
        </div> */}
       </div> 
       }
       
      </ModalBody>
    </Modal>
  );
}
