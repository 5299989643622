import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import {
  GET_WITHDRAWS,
  POST_WITHDRAWS,
} from "../../../constants/api.constants";
import { get, post } from "../../../helpers/api_helper";
import { detectBankName } from '../../../helpers/utils';


const Virements = () => {
  const [withdrawsData, setWithdrawData] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modal_standard, setModalStandard] = useState(false);
  const [type, setType] = useState(null);
  const [states, setStates] = useState([]);
  const [keyword, setKeyword] = useState('');

  const [withdraw, setWithdraw] = useState([]);

  var [withdraws, setWithdraws] = useState([]);
  const [is_modal_loading, setIsModalLoading] = useState(false);

  const handlePageChange = (page) => {
    setPage((page - 1));
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };







  const tog_standard = (withdraw_id = null, is_reject = false,) => {

    if (withdraw_id !== null) {
      let cur = withdraws.find((wr) => wr.id === withdraw_id);
      console.log(cur);
      console.log(withdraw_id);
      setWithdraw({ ...cur, is_reject: is_reject });
      setModalStandard(true);
    } else {
      setModalStandard(false);
    }
  };

  const handle_withdraw = async (withdraw_id, is_reject) => {

    setIsModalLoading(true);
    if (!type) {
      alert("Please select the type of this withdraw! " + withdraw.amount);
    }
    try {
      await post(POST_WITHDRAWS, {
        withdraw_id,
        action: is_reject ? -1 : 1,
        amount: withdraw.amount,
        type,
      });
      setIsModalLoading(false);
      tog_standard(null);
      fetchWithdraws();
    } catch (error) {
      setIsModalLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWithdraws();

  }, [page, perPage])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchWithdraws();
    }, 800)
    return () => clearTimeout(delayDebounceFn)
  }, [keyword])
  const fetchWithdraws = async () => {
    setIsLoading(true);
    try {
      const response = await get(GET_WITHDRAWS + '?page=' + page + '&per_page=' + perPage + '&keyword=' + keyword);
      withdraws = response.withdraws;

      console.log('virement response : ', response)
      setStates(response.stats);

      var temp = [];
      for (let i = 0; i < response.withdraws.length; i++) {
        const withdraw = response.withdraws[i];
        temp.push({
          id: i + 1,

          withdraw_id: withdraw.id,
          user: (
            <Link to={"/member-detail/" + withdraw.user_id}>
              {withdraw.first_name + " " + withdraw.last_name}
            </Link>
          ),
          created_at: withdraw.created_at,
          sent_date: withdraw.status === 1 ? withdraw.updated_at : '-',
          amount: withdraw.amount,

          bank: `${detectBankName(withdraw.rib_number)}`,
          status:
            withdraw.status === 0 ? (
              <span class="badge bg-warning">Waiting</span>
            ) : withdraw.status === 1 ? (
              <span class="badge bg-success">Sent</span>
            ) : (
              <span class="badge bg-danger">Disapproved</span>
            ),
          actions:
            // <Button
            //   color="success mt-0"
            //   onClick={() => this.props.history.push("/member-detail")}
            //   style={{ width: "60px", cursor: "pointer" }}
            // >
            //   <i class="far fa-eye"></i>
            // </Button>
            localStorage.getItem("role") == "0" ? (withdraw.status === 0 ? (
              <div className="d-flex">
                <Button
                  type="button"
                  onClick={() => {
                    tog_standard(withdraw.id, true);
                  }}
                  color="bg-danger"
                  className="waves-effect waves-light text-danger"
                >
                  <i class="ri-close-circle-line fs-20"></i>
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    tog_standard(withdraw.id);
                  }}
                  color="bg-success"
                  className="waves-effect waves-light md text-success"
                >
                  <i class="ri-checkbox-circle-fill fs-20"></i>
                </Button>
              </div>
            ) : (
              ""
            )) : '',
        })
      }
      setTotalRows(response._pagination.total_items);
      setWithdrawData(temp);
      setIsLoading(false)

    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      selector: (row) => row.user,
      name: "Full name",
    },
    {
      selector: (row) => row.created_at,
      name: "Request Date",
    },
    {
      selector: (row) => row.amount,
      name: "Amount",
    },
    {
      selector: (row) => row.withdraw_id,
      name: "Withdraw Id",
    },
    {
      selector: (row) => row.bank,
      name: "Bank",
    },
    {
      selector: (row) => row.status,
      name: "Status",
    },
    {
      selector: (row) => row.sent_date,
      name: "Sent Date",
    },
    {
      selector: (row) => row.actions,
      name: "Actions",
    },
  ];


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="3">
              <Card className="custom-card">
                <CardBody>
                  {states?.total_pending ? (
                    <>
                      <p>Pending </p>
                      <h4>{states?.total_pending} MAD</h4>
                    </>
                  ) : (
                    <i class="mdi mdi-spin mdi-loading" target="_blank"></i>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs="3">
              <Card className="custom-card">
                <CardBody>
                  {states?.total_pending_virments ? (
                    <>
                      <p>Total operations </p>
                      <h4>{states?.total_pending_virments}</h4>
                    </>
                  ) : (
                    <i class="mdi mdi-spin mdi-loading" target="_blank"></i>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs="3">
              <Card className="custom-card">
                <CardBody>
                  <p>Search for user </p>
                  <Input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                </CardBody>
              </Card>
            </Col>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle
                    className="h4"
                    style={{ textAlign: "center" }}
                  ></CardTitle>
                  <Modal isOpen={modal_standard} centered={true}>
                    <ModalHeader toggle={() => setModalStandard(false)}>
                      Confirmation
                    </ModalHeader>
                    <ModalBody>
                      {withdraw.is_reject ? (
                        <>
                          Are you sure you want to reject request of{" "}
                          <Link to={"/member-detail/" + withdraw.user_id}>
                            {withdraw.first_name + " " + withdraw.last_name}
                          </Link>
                        </>
                      ) : (
                        <div>
                          Lorsque tu a cliquer sur le bouton 'Envoyer', tu a
                          accepté que le montant de ({withdraw.amount} Dh) a été
                          envoyé à:
                          <Card className="mt-2 pt-3">
                            <div className="wr-items">
                              <div className="wr-item">
                                <span className="strong"> User:</span>{" "}
                                {withdraw.first_name} {withdraw.last_name}
                              </div>
                              <div className="wr-item" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <span className="strong">Amount:</span>
                                {/* <Input style={{width: "100px"}} type="number" onChange={(e) => setWithdraw({...withdraw, amount: e.target.value})} value={withdraw.amount} /> */}
                                <span>
                                  {withdraw.amount} Dh
                                </span>
                              </div>
                              <div className="wr-item">
                                <span className="strong">RIB:</span>{" "}
                                {withdraw.rib_number} <br />
                                <div className="copy-action">
                                  <i className=" ri-file-copy-line"></i>
                                </div>
                              </div>
                              <div className="wr-item">
                                <span className="strong">Virement ID:</span>{" "}
                                {withdraw.id} <br />
                                <div className="copy-action">
                                  <i className=" ri-file-copy-line"></i>
                                </div>
                              </div>
                              <div className="wr-item">
                                <span className="strong">Type:</span>{" "}
                                <select onChange={(e) => { setType(e.target.value) }}>
                                  <option selected disabled>Select type</option>
                                  <option value={0}>Virement</option>
                                  <option value={1}>Remboursement</option>
                                </select>

                              </div>
                            </div>
                          </Card>
                        </div>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        onClick={() => {
                          tog_standard(null);
                        }}
                        color="light"
                        className="waves-effect"
                      >
                        Close
                      </Button>
                      <Button
                        type="button"
                        color={withdraw.is_reject ? "danger" : "success"}
                        disabled={is_modal_loading}
                        className="waves-effect waves-light"
                        onClick={() =>
                          handle_withdraw(withdraw.id, withdraw.is_reject)
                        }
                      >
                        {is_modal_loading ? (
                          <Spinner color="light" size="sm">
                            Load
                          </Spinner>
                        ) : withdraw.is_reject ? (
                          "Reject this virement"
                        ) : (
                          "Accept and send"
                        )}
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <DataTable
                    pagination
                    paginationServer
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    columns={columns}
                    progressPending={isLoading}
                    progressComponent={<Loader />}
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    data={withdrawsData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Virements;
