import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import { CHATS, REVIEWS } from "../../../constants/api.constants";
import { get, post, put } from "../../../helpers/api_helper";
import { getImageLink } from "../../../helpers/utils";

export default function ReviewDialog({
  toggle_modal,
  reservation,
  dialog_open,
}) {
  const [review, setReview] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getReviews = async () => {
    setIsLoading(true);
    try {
      let data = await get(
        `${REVIEWS}?reservation_id=${reservation.reservation_id}`
      );
      if (data.reviews.length) {

        setReview(data.reviews[0]);
      } else {
        alert("No review yet");
        toggle_modal();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const putReviews = async () => {
    setIsLoading(true);
    try {
      let data = await put(
        `${REVIEWS}?review_id=${review.id}`,
        review
      );
      if (data.message === "Ok") {
        toggle_modal();
      } else {
        alert("No review yet");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <Modal isOpen={dialog_open} centered={true} className="modal-chat">
      <ModalHeader toggle={toggle_modal}>Reviews</ModalHeader>

      <ModalBody>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="chat-container" style={{height: '384px'}}>

            {/* Driver Bloc */}
            <div className={"chat-dialog chat-owner"}>
              {/* <div className="avatar">
                <img src={getImageLink(review.driver_picture)} alt="img" />
              </div> */}

              <div className="chat-detail">
                <i className={review.driver_thumb === 0 ? '' :(review.driver_thumb === 1 ? 'ri-thumb-up-fill text-success' : 'ri-thumb-down-fill text-danger')} style={{fontSize: '23px'}}></i>
                {/* <h5>{chat.from_fname + ' ' + chat.from_lname}</h5> */}
                <p>{review.driver_comment === null ? (<i style={{color: '#979797'}}>[No review yet]</i>) :review.driver_comment}</p>
                {/* <span className="date">{chat.send_date}</span> */}
              </div>

              <div className="avatar">
                <img src={getImageLink(review.passenger_pic)} alt="img" />
              </div>

           
            </div>

            {/* Passenger Bloc  */}
            <div className={"chat-dialog"}>
              <div className="avatar">
                <img src={getImageLink(review.driver_picture)} alt="img" />
              </div>

              <div className="chat-detail">
              <i className={review.passenger_thumb === 0 ? '' :(review.passenger_thumb === 1 ? 'ri-thumb-up-fill text-success' : 'ri-thumb-down-fill text-danger')} style={{fontSize: '23px'}}></i>
                {/* <h5>{chat.from_fname + ' ' + chat.from_lname}</h5> */}
                {/* <p>{review.passenger_comment}</p> */}
                <p>{review.passenger_comment === null ? (<i style={{color: '#979797'}}>[No review yet]</i>) :review.passenger_comment}</p>
                {/* <span className="date">{chat.send_date}</span> */}
              </div>

            
            
            </div>

              <div className="mt-1"></div>
              <div className="mt-1"></div>
            <div className="edit-section mt-1">
            
            <i onClick={()=> setReview({...review, passenger_thumb: 1})} className={review.passenger_thumb === 1 ? 'ri-thumb-up-fill text-success' : 'ri-thumb-up-fill text-secondary'} style={{fontSize: '23px'}}></i>
            <i onClick={()=> setReview({...review, passenger_thumb: -1})}className={review.passenger_thumb === -1 ? 'ri-thumb-down-fill text-danger' :'ri-thumb-down-fill text-secondary'} style={{fontSize: '23px'}}></i>
              <textarea type="text" rows={3} defaultValue={review.passenger_comment} onChange={(e) => setReview({...review, passenger_comment: e.target.value})}/>
              <textarea type="text" rows={3} defaultValue={review.driver_comment} onChange={(e) => setReview({...review, driver_comment: e.target.value})}/>
            <i onClick={()=> setReview({...review, driver_thumb: 1})} className={review.driver_thumb === 1 ? 'ri-thumb-up-fill text-success' : 'ri-thumb-up-fill text-secondary'} style={{fontSize: '23px'}}></i>
            <i onClick={()=> setReview({...review, driver_thumb: -1})} className={review.driver_thumb === -1 ? 'ri-thumb-down-fill text-danger' :'ri-thumb-down-fill text-secondary'} style={{fontSize: '23px'}}></i>
            </div>
            <div className="centered-div-w-margin">
             <Button className="btn-success mt-1" onClick={() => putReviews()}>UPDATE</Button>
            </div>

            {/* <div className="chat-dialog chat-owner">
          <div className="chat-detail">
            <h5>Yuniss obr</h5>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam
              nihil soluta nam facilis consequuntur asperiores doloribus
              blanditiis, odio ex eaque?
            </p>
            <span className="date">07/12/2022 19:19:05</span>
          </div>
          <div className="avatar">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
              alt="img"
            />
          </div>
        </div> */}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}
