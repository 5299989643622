import React, { Component, useEffect, useState } from "react";
import {
    Container,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Table,
    CardText,
    Row,
    Col,
    Button,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
//Import Breadcrumb
import Select from "react-select";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { get } from "../../helpers/api_helper";
import { GET_MEMBERS, GET_RESERVATIONS } from "../../constants/api.constants";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { memberDashboardSelection } from "../../data/selects.data";
import Cookies from "js-cookie";

const columns_rides = [
    {
        name: "Departure City",
        selector: (row) => row.departure_city,
    },
    {
        name: "Arrival City",
        selector: (row) => row.arrival_city,
    },
    {
        name: "Search Count",
        selector: (row) => row.search_count,
    },
];

const ReservationsDashboard = () => {
    const [stats, setStats] = useState([]);
    const [statsKPIS, setStatsKPIS] = useState([]);
    const [nbrReservation,setNbrReservations]=useState(0)
    const [ratioTravelReservation, setRatioTravelReservation] =useState(0)
    const [dateRange, setDateRange] = useState([]);
    const [fillRate, setFillRate] = useState();
    const [isLoading, setIsLoading] = useState(true);


    const fetch_fill_rate = async (from_range = false, to_range = false) => {
        const config = {
            headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        };
        var response;
        if (from_range) {
            response = await get(GET_RESERVATIONS + "fill-rate?from_range=" + from_range + "&to_range=" + to_range, config);
        } else {
            response = await get(GET_RESERVATIONS + "fill-rate", config);
        }
        console.log('response', response)
        if (response.rate) {
            setFillRate(response.rate);
        }
        if(response.nbr_reservations){
            setNbrReservations(response.nbr_reservations)
        }
        if(response.ratio_reservations_travelled){
            setRatioTravelReservation(response.ratio_reservations_travelled)
        }
        if (response.total_seats) {
            setStatsKPIS(response);
        }
    };
    const fetchStats = async () => {
        setIsLoading(true);
        var _query = '';
        if (dateRange.length === 2) {
            let from_range = dateRange[0];
            let to_range = dateRange[1];
            
            // Constructing date strings in the format 'YYYY-MM-DD'
            let d1 = new Date(from_range);
            let d2 = new Date(to_range);
            let from_range_formatted = `${d1.getFullYear()}-${(d1.getMonth() + 1).toString().padStart(2, '0')}-${d1.getDate().toString().padStart(2, '0')}`;
            let to_range_formatted = `${d2.getFullYear()}-${(d2.getMonth() + 1).toString().padStart(2, '0')}-${d2.getDate().toString().padStart(2, '0')}`;
            
            _query += `?from_range=${from_range_formatted}&to_range=${to_range_formatted}`;
            fetch_fill_rate(from_range_formatted, to_range_formatted);
        }        
        
        try {
            const response = await get(GET_RESERVATIONS + "stats/" + _query);
            let trending_search = [];
            for (let i = 0; i < response.stats.length; i++) {
                const row = response.stats[i];
                trending_search.push({
                    departure_city: row.departure_city,
                    arrival_city: row.arrival_city,
                    search_count: <div>{row.search_count}</div>,
                });
            }
            setStats({
                ...stats,
                trending_searchs: trending_search,
            });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        fetchStats();
        fetch_fill_rate();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                {!isLoading ? (
                    <Container fluid>
                        <Row style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <Col >
                                <Card className="custom-card">
                                    <CardBody>
                                        {fillRate ? (
                                            <>
                                                <p>Fill Rate</p>
                                                <h4>{fillRate}</h4>
                                            </>
                                        ) : (
                                            <i class="mdi mdi-spin mdi-loading" target="_blank"></i>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col >
                                <Card className="custom-card">
                                    <CardBody>
                                        {statsKPIS?.total_traveled_psg ? (
                                            <>
                                                <p>Traveled Passengers</p>
                                                <h4>{statsKPIS?.total_traveled_psg}</h4>
                                            </>
                                        ) : (
                                            <i class="mdi mdi-spin mdi-loading" target="_blank"></i>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col >
                                <Card className="custom-card">
                                    <CardBody>
                                        {statsKPIS?.total_seats ? (
                                            <>
                                                <p>Total Seats</p>
                                                <h4>{statsKPIS?.total_seats}</h4>
                                            </>
                                        ) : (
                                            <i class="mdi mdi-spin mdi-loading" target="_blank"></i>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col >
                                <Card className="custom-card">
                                    <CardBody>
                                        {nbrReservation ? (
                                            <>
                                                <p>Number of Reservation</p>
                                                <h4>{nbrReservation}</h4>
                                            </>
                                        ) : (
                                            <i class="mdi mdi-spin mdi-loading" target="_blank"></i>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col >
                                <Card className="custom-card">
                                    <CardBody>
                                        {ratioTravelReservation? (
                                            <>
                                                <p>Traveled/Reservations</p>
                                                <h4>{ratioTravelReservation}</h4>
                                            </>
                                        ) : (
                                            <i class="mdi mdi-spin mdi-loading" target="_blank"></i>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={3}>

                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                    <Flatpickr
                                        className="form-control d-block"
                                        placeholder="dd M,yyyy"
                                        value={dateRange}
                                        onChange={(e) => {
                                            console.log("changed");
                                            setDateRange(e);
                                        }}
                                        options={{
                                            mode: "range",
                                            dateFormat: "Y-m-d",
                                        }}
                                    />
                                    <Button size="sm" style={{ marginLeft: '16px' }} onClick={() => { fetchStats() }}>Filter</Button>
                                </div>

                                {/* <Select
                                        value={currentSelection}
                                        onChange={(event) => {
                                            setCurrentSelection(event);
                                        }}
                                        name="transaction_type"
                                        options={memberDashboardSelection}
                                        classNamePrefix="select2-selection"
                                    /> */}


                            </Col>
                        </Row>


                        <Row>
                            <Col lg={12}>
                                <Card className="custom-card">
                                    <CardBody>
                                        <h4 className="card-title" style={{ marginBottom: 20 }}>
                                            Top 10 Searches
                                        </h4>

                                        <div className="table-responsive">
                                            <DataTable
                                                pagination
                                                columns={columns_rides}
                                                paginationRowsPerPageOptions={[5, 10]}
                                                progressPending={false}
                                                paginationPerPage={5}
                                                progressComponent={<Loader />}
                                                paginationTotalRows={10}
                                                data={stats?.trending_searchs}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col lg={6}>
                                <Card className="custom-card">
                                    <CardBody>
                                        <h4 className="card-title" style={{ marginBottom: 20 }}>
                                            Top 10 Drivers (by amount recieved)
                                        </h4>

                                        <div className="table-responsive">
                                            <DataTable
                                                pagination
                                                columns={columns_rides}
                                                paginationRowsPerPageOptions={[5, 10]}
                                                progressPending={false}
                                                paginationPerPage={5}
                                                progressComponent={<Loader />}
                                                paginationTotalRows={10}
                                                data={stats?.new10profitableDrivers}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>

                    </Container>
                ) : (
                    <Loader />
                )}
            </div>
        </React.Fragment>
    );
};
export default ReservationsDashboard;
