import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  CardText,
  Spinner,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import { isEmpty } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Star Ratings
import { rides } from "../../common/data";

//Import actions
// import { getProductDetail } from "../../../store/actions";
import PassengersList from "./components/PassengerInfo";
// import Reviews from "./Reviews";
// import { useParams } from "react-router-dom";
import { get, post } from "../../helpers/api_helper";
import { upperCase } from "lodash";
import { ride_statuses } from "../../data/selects.data";
import {
  GET_MEMBERS,
  GET_RIDES,
  POST_FULL_POWER,
  RIDES,
} from "../../constants/api.constants";
import SweetAlert from "react-bootstrap-sweetalert";
import Cookies from "js-cookie";

function RideDetail(props) {
  const [singleRide, setSingleRide] = useState([]);
  const [rideState, setRideState] = useState([]);
  const [isRideLoading, setIsRideLoading] = useState(true);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [driverStats, setDriverStats] = useState({ total_rides: 0, completed_rides: 0 });
  const [rideModalHistory, setRideModalHistory] = useState(false);

  // const params = useParams();
  let ride_id = props.match.params.ride_id;
  toastr.options = {
    positionClass: "toast-bottom-left",
    closeButton: true,
    progressBar: true,
  };
  useEffect(() => {
    console.log("params");
    console.log(ride_id);
    fetch_ride(ride_id);
  }, []);

  const fetch_ride = async (ride_id) => {
    try {
      const response = await get(GET_RIDES + "ride/" + ride_id);
      fetch_driver_stats(response.ride.driver_id)
      setSingleRide(response.ride);
      console.log('ride : ', response)
      setRideState(response.state);
      setIsRideLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetch_driver_stats = async (user_id) => {
    try {
      const response = await get(GET_MEMBERS + "/state/" + user_id);

      setDriverStats({ ...response.state.rides })
    } catch (error) {
      console.log(error);
    }
  };

  const parse_ride_status = (ride_status) => {
    switch (ride_status) {
      case 1:
        return { status: "Ongoing", class: "bg-pending" };
      case 2:
        return { status: "Cancelled", class: "bg-negative" };
      case 3:
        return { status: "Completed", class: "bg-completed" };
      default:
        break;
    }
  };
  const parse_stations = (stations) => {
    let resp = [];
    switch (stations.length) {
      case 1:
        return resp;
      case 3:
        resp.push({
          city: stations[2].departure_city,
          district: stations[2].departure_district,
          departure_date: stations[2].departure_date,
          price: stations[2].price,
        });
        return resp;
      case 6:
        resp.push({
          city: stations[3].departure_city,
          district: stations[3].departure_district,
          departure_date: stations[3].departure_date,
          price: stations[3].price,
        });
        resp.push({
          city: stations[5].departure_city,
          district: stations[5].departure_district,
          departure_date: stations[5].departure_date,
          price: stations[5].price,
        });
        return resp;
      default:
        return [];
    }
  };

  const parse_luggage = (luggage) => {
    if(luggage == 1) {
      return 'Small'
    } else if (luggage == 2) {
      return 'Medium'
    } else if (luggage == 3) {
      return 'Large'
    }
  }

  const imageShow = (img, id) => {
    const expandImg = document.getElementById("expandedImg" + id);
    expandImg.src = img;
  };
  const ride = rides[0];

  const handle_change_status = async () => {
    setShowSweetAlert(false);
    if (parseInt(selectedStatus) === 5) {
      execute_full_power(5)
    } else
      try {
        await post(`${RIDES}update-status`, {
          ride_id,
          new_status: selectedStatus,
        });
        fetch_ride(ride_id);
        toastr.success("Ride updated");
      } catch (error) {
        toastr.error("System Error");
        console.log(error);
      }
  };
  const update_seats = async () => {
    setIsRideLoading(true);
    if(!singleRide.new_seats_comment){
      setIsRideLoading(false)
    }
    try {
      await post(`${RIDES}update-seats`, {
        ride_id,
        new_seats: singleRide.new_seats,
        new_seats_comment: singleRide.new_seats_comment,
      });
      fetch_ride(ride_id);
      toastr.success("Ride updated");
    } catch (error) {
      toastr.error("System Error");
      setIsRideLoading(false)
      console.log(error);
    }
  };

  const execute_full_power = async (action) => {
    setIsRideLoading(true);
    try {
      const config = {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      };
      await post(
        POST_FULL_POWER,
        {
          ride_id: ride_id,
          reservation_id: 1,
          action,
        },
        config
      );
      setIsRideLoading(false);
      fetch_ride(ride_id);
    } catch (error) {
      setIsRideLoading(false);
      alert(error);
      console.log(error);
    }
  };
  // const { product } = this.props;

  return (
    <React.Fragment>
      <div className="page-content">
        {showSweetAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handle_change_status()}
            onCancel={() => setShowSweetAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : (
          ""
        )}
        <Container fluid>
          {!isEmpty(ride) && (
            <React.Fragment>
              <Modal
                isOpen={rideModalHistory}
                toggle={() => setRideModalHistory(!rideModalHistory)}
                centered={true}
                size="md"
              >
                <ModalHeader toggle={() => setRideModalHistory(false)}>
                  Ride History Infos
                </ModalHeader>
                <ModalBody>
                  <label className="text-success" >Updated : 2024-12-10 at 17:20:10</label>
                  <Row className="mt-3">
                    <Col xs={4}>
                      <span
                        style={{
                          "fontSize": "16px",
                          "fontWeight": "bold",
                          "color": "#222831",
                        }}
                      >
                        Departure City :
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span style={{"marginRight": "-14px"}}>Casablanca, maarif --&gt; <span className="text-success">Casablanca, bourgone</span></span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={4}>
                      <span 
                        style={{
                          "fontSize": "16px",
                          "fontWeight": "bold",
                          "color": "#222831",
                        }}
                      >
                        Departure Date :
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span>13/05/2024 at 17:30 --&gt; <span className="text-success">13/05/2024 at 20:30</span></span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={4}>
                    <span
                        style={{
                          "fontSize": "16px",
                          "fontWeight": "bold",
                          "color": "#222831",
                        }}
                      >
                        Arrival City :
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span>Marrackech, agdal </span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={4}>
                    <span
                        style={{
                          "fontSize": "16px",
                          "fontWeight": "bold",
                          "color": "#222831",
                        }}
                      >
                        Arrival Date :
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span>13/05/2024 at 21:30</span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={4}>
                    <span
                        style={{
                          "fontSize": "16px",
                          "fontWeight": "bold",
                          "color": "#222831",
                        }}
                      >
                        Price :
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span>115 dh --&gt; <span className="text-success">120 dh</span> </span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={4}>
                    <span
                        style={{
                          "fontSize": "16px",
                          "fontWeight": "bold",
                          "color": "#222831",
                        }}
                      >
                        Seats :
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span>4 --&gt; <span className="text-success">3</span> </span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={4}>
                    <span
                        style={{
                          "fontSize": "16px",
                          "fontWeight": "bold",
                          "color": "#222831",
                        }}
                      >
                        Valise :
                      </span>
                    </Col>
                    <Col xs={8}>
                      <span>High --&gt; <span className="text-success">Moyen</span> </span>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Row>
                <Col lg={3}>
                  <Card className="custom-br20 custom-bg-gradient-card">
                    <CardBody>
                      <div className="flex-1 overflow-hidden">
                        <h4 className="mb-2 card-title">
                          {rideState ? rideState.pending ?? "0" : "loading.."}
                        </h4>
                        <p className="text-truncate font-size-16 mb-2">
                          Pending
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="custom-br20 custom-bg-gradient-card">
                    <CardBody>
                      <div className="flex-1 overflow-hidden">
                        <h4 className="mb-2 card-title">
                          {rideState
                            ? rideState.pre_approved ?? "0"
                            : "loading.."}
                        </h4>
                        <p className="text-truncate font-size-16 mb-2">
                          Pre-approved
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="custom-br20 custom-bg-gradient-card">
                    <CardBody>
                      <div className="flex-1 overflow-hidden">
                        <h4 className="mb-2 card-title">
                          {rideState ? rideState.paid ?? "0" : "loading.."}
                        </h4>
                        <p className="text-truncate font-size-16 mb-2">Paid</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="custom-br20 custom-bg-gradient-card">
                    <CardBody>
                      <div className="flex-1 overflow-hidden">
                        <h4 className="mb-2 card-title">
                          {singleRide.seats -  rideState.paid}
                        </h4>
                        <p className="text-truncate font-size-16 mb-2">
                          Available seats
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  {!isRideLoading ? (
                    <Row>
                      <Col xl="6" lg="6" md="6">
                        <Card
                          style={{
                            "background-color": "#ffffff7a",
                            "border-radius": "15px",
                          }}
                        >
                          <CardBody
                            style={{
                              "border-left": "4px solid #4443",
                              "padding-left": "13px",
                              "border-left-style": "dashed",
                              "margin-left": "28px",
                            }}
                          >
                            <div className="mt-4 mt-xl-3">
                              <select
                                onChange={(e) => {
                                  setShowSweetAlert(true);
                                  setSelectedStatus(e.target.value);
                                }}
                                className={
                                  "ride-status " +
                                  (singleRide
                                    ? singleRide.ride_status
                                      ? parse_ride_status(
                                        singleRide.ride_status
                                      ).class
                                      : ""
                                    : "")
                                }
                              >
                                <option
                                  value={1}
                                  selected={singleRide.ride_status === 1}
                                  disabled={singleRide.ride_status === 1}
                                >
                                  Ongoing
                                </option>
                                <option
                                  value={2}
                                  selected={singleRide.ride_status === 2}
                                  disabled={singleRide.ride_status === 2}
                                >
                                  Cancelled
                                </option>
                                <option
                                  value={3}
                                  selected={singleRide.ride_status === 3}
                                  disabled={singleRide.ride_status === 3}
                                >
                                  Completed
                                </option>
                                <option
                                  value={5}
                                >
                                  Driver Absent
                                </option>
                              </select>
                              {/* <div
                              className={
                                "ride-status " +
                                (singleRide
                                  ? singleRide.ride_status
                                    ? parse_ride_status(singleRide.ride_status)
                                        .class
                                    : ""
                                  : "")
                              }
                            >
                              {singleRide
                                ? singleRide.ride_status
                                  ? parse_ride_status(singleRide.ride_status)
                                      .status
                                  : "loading.."
                                : "loading.."}
                            </div> */}
                              <h5
                                to="#"
                                className="text-secondary"
                                style={{
                                  "font-size": "11px",
                                  color: "#1c19198c !important",
                                }}
                              >
                                Published:{" "}
                                {singleRide
                                  ? singleRide.created ?? ""
                                  : "loading.."}
                              </h5>

                              <Row>
                                <Col xl="10">
                                  <Card style={{ "border-radius": "26px" }}>
                                    <CardBody style={{ padding: "0 27px" }}>
                                      <div
                                        className="mt-4 mt-xl-3 d-flex"
                                        style={{
                                          "justify-content": "space-between",
                                          "align-items": "center",
                                        }}
                                      >
                                        <div>
                                          <h3 to="#" className="text-secondary">
                                            {singleRide
                                              ? singleRide.departure_city ?? ""
                                              : "loading.."}
                                          </h3>
                                          <p
                                            to="#"
                                            className="text-secondary"
                                            style={{
                                              "margin-top": "-3px",
                                              "font-size": "16px",
                                            }}
                                          >
                                            {singleRide
                                              ? singleRide.departure_district ??
                                              ""
                                              : "loading.."}
                                          </p>
                                          <p
                                            to="#"
                                            className="text-secondary"
                                            style={{
                                              "margin-top": "-11px",
                                              "font-size": "13px",
                                            }}
                                          >
                                            {singleRide
                                              ? singleRide.departure_date ?? ""
                                              : "loading.."}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            "font-size": "20px",
                                            "font-weight": "700",
                                            color: "#0dae91",
                                            "margin-top": "-6px",
                                          }}
                                        >
                                          {singleRide
                                            ? singleRide.price ?? ""
                                            : "loading.."}
                                          dh
                                          <br />
                                          <span
                                            style={{
                                              color: "#444444c9",
                                              "font-size": "17px",
                                            }}
                                          >
                                            Seats:{" "}
                                            {singleRide
                                              ? singleRide.seats ?? ""
                                              : "loading.."}
                                          </span>
                                          <br />
                                          <span
                                            style={{
                                              color: "#444444c9",
                                              "font-size": "15px",
                                            }}
                                          >
                                            Luggage: {parse_luggage(singleRide.luggage)}
                                          </span>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                  <Row>
                                    <Col
                                      xl="12"
                                      style={{ "margin-left": "30px" }}
                                    >
                                      {parse_stations(
                                        singleRide.stations ?? []
                                      ).map((station) => {
                                        return (
                                          <Card
                                            style={{ "border-radius": "26px" }}
                                          >
                                            <CardBody
                                              style={{ padding: "0 27px" }}
                                            >
                                              <div className="mt-4 mt-xl-3">
                                                <h3
                                                  to="#"
                                                  className="text-secondary"
                                                  style={{
                                                    "font-size": "20px",
                                                  }}
                                                >
                                                  {station.city}
                                                </h3>
                                                <p
                                                  to="#"
                                                  className="text-secondary"
                                                  style={{
                                                    "margin-top": "-3px",
                                                    "font-size": "13px",
                                                  }}
                                                >
                                                  {station.district ?? "Other"}
                                                </p>
                                                <p
                                                  to="#"
                                                  className="text-secondary"
                                                  style={{
                                                    "margin-top": "-11px",
                                                    "font-size": "10px",
                                                  }}
                                                >
                                                  {station.departure_date}
                                                </p>
                                              </div>
                                            </CardBody>
                                          </Card>
                                        );
                                      })}
                                    </Col>
                                  </Row>
                                  <Card style={{ "border-radius": "26px" }}>
                                    <CardBody style={{ padding: "0 27px" }}>
                                      <div className="mt-4 mt-xl-3">
                                        <h3 to="#" className="text-secondary">
                                          {singleRide
                                            ? singleRide.arrival_city ?? ""
                                            : "loading.."}
                                        </h3>
                                        <p
                                          to="#"
                                          className="text-secondary"
                                          style={{
                                            "margin-top": "-3px",
                                            "font-size": "16px",
                                          }}
                                        >
                                          {singleRide
                                            ? singleRide.arrival_district ?? ""
                                            : "loading.."}
                                        </p>
                                        <p
                                          to="#"
                                          className="text-secondary"
                                          style={{
                                            "margin-top": "-11px",
                                            "font-size": "13px",
                                          }}
                                        >
                                          {singleRide
                                            ? singleRide.arrival_date ?? ""
                                            : "loading.."}
                                        </p>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                              <button
                                className="btn-table"
                                style={{
                                  "position": "absolute", 
                                  "right": "15px", 
                                  "bottom": "15px" 
                                }}
                                onClick={() => {
                                  setRideModalHistory(true);
                                }}
                              >
                                <i className="ri-information-line "></i>
                              </button>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl="6" lg="6" md="6">
                        <Row>
                          <Col>
                            <Card className="custom-br20">
                              <Row className="no-gutters">
                                <Col md="4">
                                  <img
                                    className="driver-img"
                                    width="100%"
                                    // src={
                                    //   singleRide
                                    //     ? "https://pippipyalahcom.s3.eu-west-3.amazonaws.com/" +
                                    //         singleRide.driver_picture ?? ""
                                    //     : "loading.."
                                    // }
                                    src={
                                      `https://pippipyalahcom.s3.eu-west-3.amazonaws.com/` +
                                      (singleRide.driver_picture
                                        ? singleRide.driver_picture?.includes(
                                          "undefined"
                                        )
                                          ? "images/users/user_default_pic.svg"
                                          : singleRide.driver_picture
                                        : "images/users/user_default_pic.svg")
                                    }
                                    alt="Card cadp"
                                  />
                                </Col>
                                <Col md="8">
                                  <CardBody>
                                    <CardTitle>
                                      <Link
                                        to={
                                          singleRide
                                            ? "/member-detail/" +
                                            singleRide.driver_id ?? "#"
                                            : "loading.."
                                        }
                                      >
                                        {singleRide
                                          ? singleRide.driver_name ?? ""
                                          : "loading.."}
                                      </Link>
                                    </CardTitle>
                                    <CardText></CardText>
                                    <Row className="custom-card">
                                      <Col xl={6}>
                                        <p className="text-truncate font-size-13 mb-2">
                                          Total rides
                                        </p>
                                        <h4 className="mb-2 card-title">{driverStats.total_rides}</h4>
                                      </Col>
                                      <Col xl={6} className="b-left">
                                        <p className="text-truncate font-size-13 mb-2">
                                          Completed rides
                                        </p>
                                        <h4 className="mb-2 card-title">{driverStats.completed_rides}</h4>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card className="custom-br20">
                              <Row className="no-gutters">
                                <Col md="4">
                                  <img
                                    className="driver-img car-img"
                                    width="100%"
                                    src="https://cdn-icons-png.flaticon.com/512/575/575703.png"
                                    alt="Card cadp"
                                  />
                                </Col>
                                <Col md="8">
                                  <CardBody>
                                    <CardTitle>
                                      {singleRide
                                        ? singleRide.color ?? ""
                                        : "loading.."}{" "}
                                      &nbsp;
                                      {singleRide
                                        ? singleRide.brand_name ?? ""
                                        : "loading.."}{" "}
                                      &nbsp;
                                      {singleRide
                                        ? singleRide.model_name ?? ""
                                        : "loading.."}
                                    </CardTitle>
                                    <CardText></CardText>
                                    <Row className="custom-card pt-3">
                                      <Col xl={4} className="text-center">
                                        {/* <p className="text-truncate font-size-13 mb-2">
                                        Plate id
                                      </p> */}
                                        <h4 className="mb-2 card-title">
                                          {" "}
                                          {singleRide
                                            ? singleRide.plate_id ?? ""
                                            : "loading.."}
                                        </h4>
                                      </Col>
                                      <Col
                                        xl={4}
                                        className="b-left text-center"
                                      >
                                        {/* <p className="text-truncate font-size-13 mb-2">
                                        Completed rides
                                      </p> */}
                                        <h4 className="mb-2 card-title">
                                          {" "}
                                          {singleRide
                                            ? upperCase(
                                              singleRide.plate_letter
                                            ) ?? ""
                                            : "loading.."}
                                        </h4>
                                      </Col>
                                      <Col
                                        xl={4}
                                        className="b-left text-center"
                                      >
                                        {/* <p className="text-truncate font-size-13 mb-2">
                                        
                                      </p> */}
                                        <h4 className="mb-2 card-title">
                                          {singleRide
                                            ? singleRide.plate_region_number ??
                                            ""
                                            : "loading.."}
                                        </h4>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <div className="loading-container">
                      <Spinner color="dark" size="md">
                        Load
                      </Spinner>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="col-6">
                  <Card className="custom-card">
                    <CardBody>
                      <Row>
                        <Col xl={6} className="mb-3">
                          <Label
                            htmlFor="nationality"
                            className="col-md6 col-form-label"
                          >
                            Change number of seats
                          </Label>
                          <Col md={10}>
                            <Input
                              type="number"
                              // value={transaction.transactions_type}
                              onChange={(event) => {
                                setSingleRide({ ...singleRide, new_seats: event.target.value })
                                // handle_on_change(event, "transaction_type");
                              }}
                              name="ride_seats"
                              classNamePrefix="select2-selection"
                            />
                            <br></br>
                            <Input
                              type="textarea"
                              className="textarea"
                              placeholder="Add comments"
                              onChange={(event) => {
                                setSingleRide({ ...singleRide, new_seats_comment: event.target.value })
                                // handle_on_change(event, "transaction_type");
                              }}
                              name="ride_seats"
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Label
                            htmlFor="document_number"
                            className="col-md-6 col-form-label v-none text-white"
                          >
                            .
                          </Label>
                          <Col md={9}>
                            <Button
                              type="button"
                              color="success"
                              disabled={false}
                              className="waves-effect waves-light"
                              onClick={update_seats}
                            >
                              {false ? (
                                <Spinner color="light" size="sm">
                                  Load
                                </Spinner>
                              ) : (
                                "Apply"
                              )}
                            </Button>
                          </Col>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg={12}>
                  <PassengersList editRideSeats={singleRide.ride_status === 1} ride_id={ride_id} />
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(RideDetail);
