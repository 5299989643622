import "flatpickr/dist/themes/material_blue.css";
import React, { Component } from 'react';
import Flatpickr from "react-flatpickr";
import { Button, Card, CardBody, Col, Input, InputGroup, Row } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import { GET_CHART_MEMBERS, GET_CHART_NEW_MEMBERS } from '../../constants/api.constants';
import { get } from '../../helpers/api_helper';
import "./dashboard.scss";

class MembersAnalytics extends Component {
    state = {
        filter: 'week',

        newMembers: 0,
        totalMembers: 0,
        regularMembers: 0,
        currentMembers: 0,
        androidMembers: 0,
        iosMembers: 0,
        facebookMembers: 0,
        googleMembers: 0,
        appleMembers: 0,

        showTotalMembers: true,
        showRegularMembers: true,
        showCurrentMembers: false,
        showAndroidMembers: true,
        showIOSMembers: true,
        showFacebookMembers: true,
        showGoogleMembers: true,
        showAppleMembers: true,

        dateRange: [],

        tempSeries: [],
        tempColors: [],

        series: [],
        options: {
            chart: {
                toolbar: {
                    show: false,
                }
            },
            stroke: {
                width: [2, 2, 2, 2, 2, 2, 2],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    vertical: false,
                    columnWidth: '20%',
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            // colors: ['#5664d2', '#1cbb8c',"#f46a6a", "#f9f07a"],
            colors: [],
            labels: [],
        }
    }

    async componentDidMount() {
        const endDate = new Date(); // Current date
        const startDate = new Date(endDate); // Start date is the current date
        startDate.setFullYear(startDate.getFullYear() - 1, startDate.getMonth() + 1);
        const tempDate = [startDate, endDate]
        this.setState({
            dateRange: tempDate
        });

        // Wait for a few milliseconds before initiating the data fetch
        setTimeout(async () => {
            await this.handleOnSearch();
        }, 100); // Adjust the delay time as needed


        this.fetchNewMembersCount();
        // Fetch new members count every 5 minutes
        this.interval = setInterval(this.fetchNewMembersCount, 5 * 60 * 1000); // 5 minutes interval
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.showTotalMembers !== this.state.showTotalMembers ||
            prevState.showRegularMembers !== this.state.showRegularMembers ||
            prevState.showAndroidMembers !== this.state.showAndroidMembers ||
            prevState.showIOSMembers !== this.state.showIOSMembers ||
            prevState.showFacebookMembers !== this.state.showFacebookMembers ||
            prevState.showGoogleMembers !== this.state.showGoogleMembers ||
            prevState.showAppleMembers !== this.state.showAppleMembers
        ) {
            this.updateSeriesState();
        }
    }


    updateSeriesState = () => {
        const { tempSeries, tempColors, showTotalMembers, showRegularMembers, showAndroidMembers, showIOSMembers, showFacebookMembers, showGoogleMembers, showAppleMembers } = this.state;
        const updatedOptions = { ...this.state.options };

        const updatedSeries = [];
        const updatedColors = [];

        if (showTotalMembers) {
            updatedSeries.push(tempSeries[0]);
            updatedColors.push(tempColors[0]);
        }
        if (showAndroidMembers) {
            updatedSeries.push(tempSeries[1]);
            updatedColors.push(tempColors[1]);
        }
        if (showIOSMembers) {
            updatedSeries.push(tempSeries[2]);
            updatedColors.push(tempColors[2]);
        }
        if (showFacebookMembers) {
            updatedSeries.push(tempSeries[3]);
            updatedColors.push(tempColors[3]);
        }
        if (showGoogleMembers) {
            updatedSeries.push(tempSeries[4]);
            updatedColors.push(tempColors[4]);
        }
        if (showAppleMembers) {
            updatedSeries.push(tempSeries[5]);
            updatedColors.push(tempColors[5]);
        }
        if (showRegularMembers) {
            updatedSeries.push(tempSeries[6]);
            updatedColors.push(tempColors[6]);
        }

        updatedOptions.colors = updatedColors;

        this.setState({
            series: updatedSeries,
            options: updatedOptions
        })
    }

    fetchNewMembersCount = async () => {
        try {
            const response = await get(GET_CHART_NEW_MEMBERS); // Assuming GET_MEMBERS endpoint fetches new members count
            if (response) {
                this.setState({ newMembers: response.newMembers });
                // console.log('response : ', response.newMembers);
            } else {
                console.error('Error: Response data is missing or invalid');
            }
        } catch (error) {
            console.error('Error while fetching new members count: ', error);
        }
    }

    fetchAllUsers = async () => {
        try {
            const { filter, dateRange } = this.state;
            let url = `${GET_CHART_MEMBERS}?filter=${filter}`;


            if (dateRange !== null && dateRange.length === 2) {
                let from_range = dateRange[0];
                let to_range = dateRange[1];

                url += `&startDate=${from_range}&endDate=${to_range}`
            }

            const response = await get(url);

            if (response) {
                const updatedOptions = { ...this.state.options };
                const updatedSeries = [];
                const updatedColors = [];

                if (true) {
                    updatedSeries.push({
                        name: 'Total Users',
                        type: 'line',
                        data: response?.series?.map(data => data?.totalUsers)
                    });
                    updatedColors.push("#5664d2")
                }
                if (true) {
                    updatedSeries.push({
                        name: 'Android Users',
                        type: 'line',
                        data: response?.series?.map(data => data?.androidUsers)
                    });
                    updatedColors.push("#1cbb8c")
                }
                if (true) {
                    updatedSeries.push({
                        name: 'IOS Users',
                        type: 'line',
                        data: response?.series?.map(data => data?.iosUsers)
                    });
                    updatedColors.push('#f46a6a')
                }
                if (true) {
                    updatedSeries.push({
                        name: 'Facebook Users',
                        type: 'line',
                        data: response?.series?.map(data => data?.facebookUsers)
                    });
                    updatedColors.push('#59d5E0')
                }
                if (true) {
                    updatedSeries.push({
                        name: 'Google Users',
                        type: 'line',
                        data: response?.series?.map(data => data?.googleUsers)
                    });
                    updatedColors.push('#fcdc2a')
                }
                if (true) {
                    updatedSeries.push({
                        name: 'Apple Users',
                        type: 'line',
                        data: response?.series?.map(data => data?.appleUsers)
                    });
                    updatedColors.push('#b4b4b8')
                }
                if (true) {
                    updatedSeries.push({
                        name: 'Regular Users',
                        type: 'line',
                        data: response?.series?.map(data => data?.regularUsers)
                    });
                    updatedColors.push('#fe7a36')
                }

                updatedOptions.labels = response?.series?.map(data => data?.label);

                this.setState({
                    options: updatedOptions,
                    series: updatedSeries,
                    tempSeries: updatedSeries,
                    tempColors: updatedColors,
                    totalMembers: response?.data[0]?.totalMembers,
                    regularMembers: response?.data[0]?.regularUsers,
                    androidMembers: response?.data[0]?.androidUsers,
                    iosMembers: response?.data[0]?.iosUsers,
                    facebookMembers: response?.data[0]?.facebookUsers,
                    googleMembers: response?.data[0]?.googleUsers,
                    appleMembers: response?.data[0]?.appleUsers
                }, () => {
                    console.log(response);
                });
            } else {
                console.error('Error: Response data is missing or invalid');
            }
        } catch (error) {
            console.error('Error while fetching all users: ', error);
        }
    }

    handleOnSearch = async () => {
        await this.fetchAllUsers();
        this.updateSeriesState();
    }

    render() {
        const { filter, newMembers, totalMembers, regularMembers, currentMembers, androidMembers, iosMembers, facebookMembers, googleMembers, appleMembers } = this.state
        const { showTotalMembers, showRegularMembers, showCurrentMembers, showAndroidMembers, showIOSMembers, showFacebookMembers, showGoogleMembers, showAppleMembers } = this.state
        let { dateRange } = this.state

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <Row className="float-end d-none d-md-flex align-items-center">
                            <Col>
                                <InputGroup style={{ marginRight: "10px" }}>
                                    <Flatpickr
                                        className="form-control d-block"
                                        style={{ width: 210 }}
                                        placeholder="dd M,yyyy"
                                        value={dateRange}
                                        onChange={(e) => {
                                            this.setState({
                                                dateRange: e
                                            })
                                        }}
                                        options={{
                                            mode: "range",
                                            dateFormat: "Y-m-d"
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Button color="success" type="button" onClick={this.handleOnSearch} className="waves-effect waves-light me-1">
                                    <i className="ri-search-line align-middle me-2"></i> Search
                                </Button>
                            </Col>
                        </Row>

                        <h4 className="card-title mb-4">New members: {newMembers}</h4>
                        <div>
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="280" />
                            </div>
                        </div>
                    </CardBody>

                    <CardBody className="border-top text-center">
                        <Row className='mb-4 d-flex justify-content-around align-items-center'>
                            <Col sm={3}>
                                <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showTotalMembers} onChange={(val) => this.setState({ showTotalMembers: val.target.checked })} />
                                    <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Total members:</p>
                                </div>
                                {/* <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Total members:</p> */}
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{totalMembers}</h5>
                                </div>

                            </Col>
                            {/* <Col sm={3}>
                            <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                <Input type="checkbox" className="me-2" checked={showCurrentMembers} onChange={(val) => this.setState({ showCurrentMembers: !showCurrentMembers })} />
                                <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle text-warning font-size-10 me-1"></i> Current Active members:</p>
                            </div>
                            <div className="d-inline-flex">
                                <h5 className="mb-0 me-2">{currentMembers}</h5>
                            </div>
                            
                        </Col> */}

                            <Col sm={3}>
                                <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showAndroidMembers} onChange={(val) => this.setState({ showAndroidMembers: !showAndroidMembers })} />
                                    <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> Android Users</p>
                                </div>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{androidMembers}</h5>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="mt-4 mt-sm-0 d-flex justify-content-center align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showIOSMembers} onChange={(val) => this.setState({ showIOSMembers: !showIOSMembers })} />
                                    <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle text-danger font-size-10 me-1"></i> IOS Users</p>
                                </div>
                                <div className="d-inline-flex justify-content-center">
                                    <h5 className="mb-0">{iosMembers}</h5>
                                </div>
                            </Col>

                        </Row>
                        <Row className='d-flex justify-content-around align-items-center'>

                            <Col sm={3}>
                                <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showRegularMembers} onChange={(val) => this.setState({ showRegularMembers: val.target.checked })} />
                                    <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle font-size-10 me-1" style={{ color: '#fe7a36' }}></i> Regular Users</p>
                                </div>
                                {/* <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Total members:</p> */}
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{regularMembers}</h5>
                                </div>

                            </Col>

                            <Col sm={3}>
                                <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showFacebookMembers} onChange={(val) => this.setState({ showFacebookMembers: val.target.checked })} />
                                    <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle text-info font-size-10 me-1"></i> Facebook Users:</p>
                                </div>
                                {/* <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Total members:</p> */}
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{facebookMembers}</h5>
                                </div>

                            </Col>

                            <Col sm={3}>
                                <div className="mt-4 mt-sm-0 d-flex align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showGoogleMembers} onChange={(val) => this.setState({ showGoogleMembers: val.target.checked })} />
                                    <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle text-warning font-size-10 me-1"></i> Google Users:</p>
                                </div>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{googleMembers}</h5>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="mt-4 mt-sm-0 d-flex justify-content-center align-items-center">
                                    <Input type="checkbox" className="me-2" checked={showAppleMembers} onChange={(val) => this.setState({ showAppleMembers: val.target.checked })} />
                                    <p className="mb-0 text-muted text-truncate"><i className="mdi mdi-circle text-secondary font-size-10 me-1"></i> Apple Users:</p>
                                </div>
                                <div className="d-inline-flex justify-content-center">
                                    <h5 className="mb-0">{appleMembers}</h5>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default MembersAnalytics;